import React, { FunctionComponent, useEffect, useState, useRef, Suspense } from 'react';
import { Row, Col } from 'react-bootstrap';
import BaseLayout from '../functionalComponents/baseLayout/BaseLayout';
import ReactMarkdown from 'react-markdown';
import '../../assets/css/prism.css';
import '../../assets/css/contentdoc.css';
import Accesos_EN from '../../newViews/APIs/1_tools/0_Accesos_EN/Accesos_EN';
import { useDispatch, useSelector } from 'react-redux';
import { updateName, updateShowContentInDocs } from '../../redux/slices/KnowDivInScreen';
import Introduction from '../../newViews/Docs/0_Introduction';
import Billing_System from '../../newViews/Docs/1_Billing_System';
import AVAP_CLI from "../../assets/img/developerPortalIMG/AVAP_CLI.png";
import AVAP_DS from "../../assets/img/developerPortalIMG/AVAP_DS.png";
import AVAP_Lenguaje from "../../assets/img/developerPortalIMG/AVAP_Lenguaje.png";
import AVAP_VS from "../../assets/img/developerPortalIMG/AVAP_VS.png";
import AVAP_Gateway from "../../assets/img/developerPortalIMG/AVAP_Gateway.png";
import AVAP_CollaborativeN from "../../assets/img/developerPortalIMG/AVAP_CollaborativeN.png";
import AVAP_Cloud from "../../assets/img/developerPortalIMG/AVAP_Cloud.png";
import icono_Cloud from "../../assets/img/developerPortalIMG/icono_Cloud.png";
import icono_education from "../../assets/img/developerPortalIMG/icono_education.png";
import icono_DS from "../../assets/img/developerPortalIMG/icono_DS.png";
import icono_AI from "../../assets/img/developerPortalIMG/icono_AI.png";
import icono_CollaborativeN from "../../assets/img/developerPortalIMG/icono_CollaborativeN.png";
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type ContentProps = {
    doc: string,
    sider: any,
    cat: string,
    section?: string
};

const Content: FunctionComponent<ContentProps> = props => {
    const { doc, cat, sider, section } = props;
    // console.log('!!!!!!!   ', section)
    // const md = require(`../../assets/docs/${doc}.md`);
    const objVistas = [{ id: "Accesos_EN", componente: <Accesos_EN /> }];

    // console.log(doc);//download_101cli
    // console.log(sider.menus);

    const componenteSeleccionado = objVistas.find(vista => vista.id === doc);

    const [alldivs, setAllDivs] = useState([]);

    const divRefs = useRef<Array<HTMLDivElement | null>>([]);
    let sendToNameSection;
    if (section) {
        sendToNameSection = useSelector((state: any) => section)

    } else {
        sendToNameSection = useSelector((state: any) => state.nameDiv.sendToNameSection)
    }
    // console.log('sendToNameSection', sendToNameSection);
    const sendToNameSubSection = useSelector((state: any) => state.nameDiv.sendToNameSubSection)
    // console.log('sendToNameSubSection', sendToNameSubSection);
    const getToSectionAlgolia = useSelector((state: any) => state.nameDiv.getToSectionAlgolia)
    const dispatch = useDispatch();
    const currentName = useSelector((state: any) => state.nameDiv.name)
    const showContentInDocs = useSelector((state: any) => state.nameDiv.showContentInDocs) //when is view default



    useEffect(() => {
        // Importamos prism cada vez que cambia el markdown file y ejecutamos el highlightAll
        const prism = require('../../assets/js/prism.js');
        prism.highlightAll();

        let divs = [];
        let menus = [...sider.menus];
        let showFirst = sider.showFirst
        if (showFirst) {
            const name_section = showFirst.id;
            const sections = showFirst.links;

            sections.forEach(items => {
                let path = items.path;
                divs.push(
                    <div className='py-5 border-bottom' key={name_section + "-" + path} id={name_section + "-" + path} ref={ref => divRefs.current.push(ref)}>
                        {items.view}
                    </div>
                )
            });
        }

        for (let index = 0; index < menus.length; index++) {
            const name_section = menus[index].id;
            const sections = menus[index].links;
            // debugger
            // console.log(sections);

            sections.forEach(items => {
                let path = items.path;
                // const docUbication = require(`../../assets/docs/${path}.md`);
                divs.push(
                    <div className='py-5 border-bottom' key={name_section + "-" + path} id={name_section + "-" + path} ref={ref => divRefs.current.push(ref)}>
                        {items.view}
                    </div>
                )
            });
        }

        setAllDivs(divs);

    }, [doc, sider]);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;

            for (let i = 0; i < divRefs.current.length; i++) {

                const div = divRefs.current[i];
                if (!div) continue;

                const rect = div.getBoundingClientRect();
                if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
                    const newName = div.id;
                    // console.log(newName, currentName);

                    if (newName !== currentName) {
                        dispatch(updateName(newName));
                    }
                    break;
                } else if (currentName !== "") {
                    dispatch(updateName(""));
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        //Use when clicked in new navbar
        // Ensure this code runs after the component has been rendered
        setTimeout(() => {
            for (let index = 0; index < divRefs.current.length; index++) {
                const divId = divRefs.current[index].id;
                const div = divRefs.current[index];

                if (divId.includes(sendToNameSection)) {
                    div.scrollIntoView({ behavior: 'auto' });
                    break;
                }
            }
        }, 0);
    }, [sendToNameSection]);


    useEffect(() => {

        for (let index = 0; index < divRefs.current.length; index++) {
            const divId = divRefs.current[index].id;
            const div = divRefs.current[index];

            if (divId === sendToNameSubSection) {
                div.scrollIntoView({ behavior: 'auto' });
                break;
            }
        }
    }, [sendToNameSubSection]);



    useEffect(() => {
        //To send sections when use ALGOLIA
        for (let index = 0; index < divRefs.current.length; index++) {
            const divId = divRefs.current[index].id;
            const div = divRefs.current[index];

            if (divId.includes(getToSectionAlgolia)) {
                div.scrollIntoView({ behavior: 'auto' });
                break;
            }
        }
    }, [getToSectionAlgolia]);



    const changeValue = () => {
        dispatch(updateShowContentInDocs(!showContentInDocs)); //when is view default
    }

    return (
        <>
            <BrowserView>
                <BaseLayout sider={sider} active={cat}>
                    <Row className="justify-content-md-center contentMarginX ">
                        <Col md={{ span: 12 }}>
                            {/* when is view default */}
                            {cat == "default" &&

                                (
                                    <div>
                                        <div className={` ${showContentInDocs == false ? "" : "hiddenContentDocs"}`}>
                                            <div className='text-bold dev_portal_text_dashboard dev_portal_gray1' style={{ textAlign: 'start' }}></div>
                                            <div className='d-flex'>
                                                <div className='row m-0 p-0 container-fluid'>
                                                    <div className='col-6 p-0 d-flex flex-column ' style={{ textAlign: 'start' }}>
                                                        <div className='d-flex flex-column text-bold ' style={{ marginBottom: "46px" }}>
                                                            <div className='dev_portal_font1 dev_portal_gray1 textBold' style={{ marginBottom: '20px' }} >
                                                                Start
                                                            </div>
                                                            <div className='dev_portal_green1 dev_portal_font2'>
                                                                Create your AVAP ID
                                                            </div>
                                                            <div className='dev_portal_green1 dev_portal_font2'>
                                                                Download basic software
                                                            </div>
                                                            <div className='dev_portal_green1 dev_portal_font2'>
                                                                Create your developer Token
                                                            </div>
                                                            <div className='dev_portal_green1 dev_portal_font2'>
                                                                Create your first API Project
                                                            </div>
                                                        </div>

                                                        <div className='d-flex flex-column'>
                                                            <div className='text-bold dev_portal_font1 dev_portal_gray1'>
                                                                Get Started
                                                            </div>

                                                            <div className='dev_portal_logos_left'>
                                                                <div>
                                                                    <img src={AVAP_CLI} alt="" height={"100%"} />
                                                                </div>
                                                            </div>

                                                            <div className='dev_portal_logos_left'>
                                                                <div>
                                                                    <img src={AVAP_DS} alt="" height={"100%"} />
                                                                </div>
                                                            </div>

                                                            <div className='dev_portal_logos_left'>
                                                                <div>
                                                                    <img src={AVAP_Lenguaje} alt="" height={"100%"} />
                                                                </div>
                                                            </div>

                                                            <div className='dev_portal_logos_left'>
                                                                <div>
                                                                    <img src={AVAP_VS} alt="" height={"100%"} />
                                                                </div>
                                                            </div>

                                                            <div className='dev_portal_logos_left'>
                                                                <div>
                                                                    <img src={AVAP_Gateway} alt="" height={"100%"} />
                                                                </div>
                                                            </div>

                                                            <div className='dev_portal_logos_left'>
                                                                <div>
                                                                    <img src={AVAP_CollaborativeN} alt="" height={"100%"} />
                                                                </div>
                                                            </div>

                                                            <div className='dev_portal_logos_left'>
                                                                <div>
                                                                    <img src={AVAP_Cloud} alt="" height={"100%"} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='col-6 p-0 d-flex flex-column' style={{ textAlign: 'start' }}>
                                                        <div className='dev_portal_font1 dev_portal_gray1 textBold' style={{ marginBottom: '20px' }} >
                                                            Walkthroughs
                                                        </div>
                                                        <div className='d-flex flex-column dev_portal_cards_right'>
                                                            <div className='d-flex flex-row dev_portal_cards_header dev_portal_cards_padding'>
                                                                <div>
                                                                    <div className='dev_portal_cards_size_log'>
                                                                        <img src={icono_Cloud} alt="" height={"100%"} width={"100%"} />
                                                                    </div>
                                                                </div>
                                                                <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Get started with AVAP Cloud</div>
                                                                <div></div>
                                                            </div>
                                                            <div className='dev_portal_cards_padding'>Access to a complete solution for API development, publishing, administration, and consumption based in the cloud.</div>

                                                        </div>

                                                        <div className='d-flex flex-column dev_portal_cards_right'>
                                                            <div className='d-flex flex-row  dev_portal_cards_padding'>
                                                                <div>
                                                                    <div className='dev_portal_cards_size_log'>
                                                                        <img src={icono_education} alt="" height={"100%"} width={"100%"} />
                                                                    </div>
                                                                </div>
                                                                <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Learn the fundamentals</div>
                                                                <div></div>
                                                            </div>

                                                        </div>

                                                        <div className='d-flex flex-column dev_portal_cards_right'>
                                                            <div className='d-flex flex-row dev_portal_cards_header dev_portal_cards_padding'>
                                                                <div>
                                                                    <div className='dev_portal_cards_size_log'>
                                                                        <img src={icono_DS} alt="" height={"100%"} width={"100%"} />
                                                                    </div>
                                                                </div>
                                                                <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Begin your journey with API Products</div>
                                                                <div></div>
                                                            </div>
                                                            <div className='dev_portal_cards_padding'>Try our own IDE with AVAP Dev Studio for project management, API creation, code maintenance, and API publication.</div>

                                                        </div>

                                                        <div className='d-flex flex-column dev_portal_cards_right'>
                                                            <div className='d-flex flex-row dev_portal_cards_header dev_portal_cards_padding'>
                                                                <div>
                                                                    <div className='dev_portal_cards_size_log'>
                                                                        <img src={icono_AI} alt="" height={"100%"} width={"100%"} />
                                                                    </div>
                                                                </div>
                                                                <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Embrace AI</div>
                                                                <div></div>
                                                            </div>
                                                            <div className='dev_portal_cards_padding'>Artificial Intelligence integrated at the core of all our AVAP products that optimizes and enhances efficiency in programming and API development.</div>

                                                        </div>

                                                        <div className='d-flex flex-column dev_portal_cards_right'>
                                                            <div className='d-flex flex-row dev_portal_cards_header dev_portal_cards_padding'>
                                                                <div>
                                                                    <div className='dev_portal_cards_size_log'>
                                                                        <img src={icono_CollaborativeN} alt="" height={"100%"} width={"100%"} />
                                                                    </div>
                                                                </div>
                                                                <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Unleash your potential</div>
                                                                <div></div>
                                                            </div>
                                                            <div className='dev_portal_cards_padding'>AVAP Collaborative Network is the 1st global and free peer-to-peer network for API development and deployment.</div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className={` ${showContentInDocs == false ? "hiddenContentDocs" : ""}`}>

                                            <Suspense fallback={<div>Cargando...</div>}>
                                                {alldivs}
                                            </Suspense>
                                        </div>
                                    </div>
                                )
                            }

                            {/* vier normal */}
                            {cat != "default" &&

                                (
                                    <Suspense fallback={<div>Cargando...</div>}>
                                        {alldivs}
                                    </Suspense>

                                )
                            }






                        </Col>
                    </Row>
                </BaseLayout>
            </BrowserView>
            <MobileView>
                <Disclaimer />
            </MobileView>
        </>

    )
}

export default Content;
