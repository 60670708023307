import { Container, Row, Col } from 'react-bootstrap';
import React, { FunctionComponent, useEffect, useState } from 'react';
import '../../../assets/css/BaseLayout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import HeaderMenu from '../MobileLayout/HeaderMenu';
// import DashboardSideBar from './DashboardSidebar';
// import Footer from './FooterMenu';

type MobileProps = {
  // sider: any,
  // active?: string,
  // sublinks?: Array<any>,
  // projects?: Array<any>,
  children?: any
};

const MobileLayout:FunctionComponent<MobileProps> = (props: MobileProps) => {
  // const { sider, children, active, sublinks } = props;
  const {  children } = props;


  useEffect(() => {
  }, []);

  return (
    // view old dashboard to blue AVAP CLOUD
  
     
        <Container fluid className='px-0' style={{paddingLeft:'0px !important', paddingRight:'0px !important'}} id='mobile-container'>
        {/* <HeaderMenu isDashboardBlue={isDashboardBlue}/> */}
        <Row id="mobile-content-page" style={{width: "100%",height: "100vh"}}>
          {/* <DashboardSideBar sider={sider} active={active} sublinks={sublinks}/> */}
          <Col id="mobile-content" className={`d-flex flex-column justify-content-center min-vh-100`}>
            <Row id='mobile-content-row'>
              <Col id='mobile-col-children'>
                {children}
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Footer/> */}
      </Container>
      
    


  )
}

export default MobileLayout;