import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Card, Button, Form, Table, Modal, Spinner, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import AddTeamModal from '../Modals/addTeamModal';
import { ReactSortable } from 'react-sortablejs';
import SessionService from '../../services/SessionsService';
import AddOrganizationSlots from '../Modals/addOrganizationSlots';
import AddTeamsSlots from '../Modals/addTeamsSlots';

import editicon from '../../assets/img/icon_edit.png';
import Logo from '../../assets/img/ilustracion_noInfo2.png'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type IncomeProps = {
};


const OrganizationTeams: FunctionComponent<IncomeProps> = ({ }) => {
  const history = useHistory();

  const [LeftDevTeams, setLeftDevTeams] = useState(0);
  const [projects, setProjects] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [newdevelopers, setNewDevelopers] = useState([]);
  const [editing, setEdition] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadDevs, setloadDevs] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [filtro, setFilter] = useState('');
  const [addingOrg, setaddingOrg] = useState(false)



  const handleOrg = () => {
    setaddingOrg(!addingOrg);
    getProjects('');
  }


  const [activeTeam, setActiveTeam] = useState('');

  const changeFilter = (e) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
  }



  const getDevelopers = async (team_id, team_name) => {
    try {
      setloadDevs(true);
      setDevelopers([]);

      const developersresult = (
        await ObexRequestHandler.get(`/team_devs?organization=${SessionService.ORGANIZATION}&team=${team_id}`, {}, true)).data || [];
      console.log("#####");
      console.log(developersresult);
      console.log("#####");
      setDevelopers(developersresult);
      setNewDevelopers(developersresult);
      setActiveTeam(team_name);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setloadDevs(false);
    }
    setloadDevs(false);
  }

  const handleModal = () => {
    console.log("ACTUALIZAONDO")
    getProjects('');
    setShowModal(!showModal);
  };

  const isTheCategory = (category, id) => {
    return category.id === id;
  }


  const handleSwitch = async (e, category, id) => {
    const index = newdevelopers.findIndex((category) => isTheCategory(category, id));
    const oo = [];
    newdevelopers.forEach((x) => { oo.push(x) });
    oo[index].checked = !oo[index].checked;
    setNewDevelopers(oo);

    if (oo[index].checked == false) {
      const resuu = (await ObexRequestHandler.get(`/remove_developer_team?organization=${oo[index].org_id}&dev_team=${activeTeam}&email=${oo[index].email}&dev_id=${oo[index].dev_id}`, {}, true)).data || [];
      console.log(resuu);
    } else {
      const resuu = (await ObexRequestHandler.get(`/add_developer_team?organization=${oo[index].org_id}&dev_team=${activeTeam}&email=${oo[index].email}`, {}, true)).data || [];
      console.log(resuu);
    }
  }

  const safeAndHideModal = async () => {
    const newCats = [];
    for (let id = 0; id < newdevelopers.length; id++) {
      const { email, checked } = newdevelopers[id];
      const cat = {
        id,
        email,
        checked
      }
      newCats.push(cat);
    }

    const allDisabled = (newCats.filter(c => c.checked)).length < 1;
    if (!allDisabled) {
      //ObexRequestHandler.post('/custom_categories', payload);
      //onEditOrder(); // TODO mejorar esto para que use los movementStats ya almacenados que no tenga que pedirlos de nuevo



    }
    getProjects('');

    setEdition(false);
  }

  const categoriesList = (
    <Form>
      <ReactSortable tag="div" list={newdevelopers} setList={setNewDevelopers}>
        {newdevelopers.map(c => (
          c.acepted ?
            <Row key={c.id} className="my-1">
              <Col>
                <Card className="product-detail-card obex-card api-product-card">
                  <Card.Body className="py-3 px-4">
                    <Row>
                      <Col className="pl-2">
                        <FontAwesomeIcon icon={faBars} className="button-sort mr-2" size="1x" />
                        <span className="title-product">{c.email}</span>
                      </Col>


                      <Col md="2" className="text-right">
                        <Form.Check
                          checked={c.checked}
                          type="switch"
                          size={5}
                          id={`${c.id}`}
                          label=''
                          onChange={(e) => handleSwitch(e, c, c.id)}
                          className="obex-switch"
                        />

                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row> : <></>
        ))}
      </ReactSortable>
    </Form>
  )

  const modal = (
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={editing}
      onHide={safeAndHideModal}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col className="addons-title">
              <span>Developers</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
        <Row className="px-3">
          <Col>
            {loadDevs &&
              <Row style={{ textAlign: 'center' }}>
                <Col>
                  <Spinner animation="border" style={{ marginLeft: '8px' }} />
                </Col>
              </Row>
            }
            {!loadDevs &&
              categoriesList
            }
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )



  const getProjects = async (filtro) => {
    try {
      setProjects([]);
      setLoading(true);
      const projects = (await ObexRequestHandler.get(`/developer_teams?organization=${SessionService.ORGANIZATION}`, {}, false)).data || [];

      setLeftDevTeams(projects['max'] - projects['used']);

      if (filtro == '') setProjects(projects['result']);
      else {

        const filter_projects = [];
        projects['result'].forEach(element => {

          if (element.name.toLowerCase().includes(filtro.toLowerCase())) filter_projects.push(element);

        });

        setProjects(filter_projects);
      }

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setLoading(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjects('');
  }, []);


  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'teams'} sublinks={[]}>
          <Row>
            <Col className="" >
              <Row style={{ marginTop: '0px' }}>
                <Col md={9} style={{ marginLeft: '-20px', marginBottom: '2rem' }}><h2 className='textBold' >Manage <b>organization {SessionService.ORGANIZATION_NAME}
                </b> Teams</h2>
                </Col>
                <Col md={3} style={{ paddingLeft: '-0px' }}>

                  {(LeftDevTeams < 1) ?
                    <Button disabled={null} className="custom-button-blue w-100 textBold px-4"
                      onClick={() => { handleOrg() }}>Create Team
                    </Button>
                    :
                    <Button disabled={null} className="custom-button-blue w-100 textBold px-4"
                      onClick={() => { setShowModal(true) }}>Create Team
                    </Button>
                  }
                </Col>

              </Row>
              <Form.Row >
                <Col md="9" style={{ marginLeft: '-20px' }}>
                  <Form.Control required type="text" value={filtro} placeholder="Search by Team name"
                    onChange={changeFilter} />
                </Col>

                <Col md={3}>
                  <Button disabled={null} className=" custom-button-blue w-100 textBold px-4"
                    onClick={() => { getProjects(filtro) }}><FontAwesomeIcon
                      icon={faSearch} size="1x" className="mr-1" />
                  </Button>
                </Col>
              </Form.Row>



              <Row style={{ marginTop: '20px' }}>
                <Col style={{ marginLeft: '-20px' }}>


                  {(addingOrg) && <AddTeamsSlots shown={addingOrg} onCloseAction={handleOrg} onSuccess={handleOrg} MaxDBSize={1} ActualDBSize={0} />}

                  {loading &&

                    <Row style={{ textAlign: 'center' }}>
                      <Col>
                        <Spinner animation="border" style={{ marginLeft: '8px' }} />
                      </Col>
                    </Row>
                  }

                  {!loading &&

                    <div style={{ borderStyle: 'solid', borderColor: '#ededee', borderWidth: '1px', borderRadius: '7px' }}>
                      <Table responsive>

                        <thead style={{ fontFamily: 'Regular Intro Bold', textAlign: 'start', backgroundColor: 'transparent', color: '#595959', borderBottomColor: '#ededed', borderBottomStyle: 'solid', borderBottomWidth: '2px' }}>
                          <div className='nowarp' style={{ paddingTop: '10px', fontSize: '1.2vw', height: '50px', paddingLeft: '15px' }}>Developers Teams</div>
                        </thead>

                        <tbody>
                          <tr style={{ color: '#595959' }}>
                            <th className='textBold'>Name</th>
                            <th style={{ textAlign: 'center' }} className='textBold'>Description</th>
                            <th style={{ textAlign: 'center' }} className='textBold'># of member</th>
                            <th style={{ textAlign: 'end' }} className='textBold'>action</th>

                          </tr>



                          {projects.map(project => (
                            <tr key={project.id}>
                              <td>{project.name}</td>
                              <td style={{ textAlign: 'center' }}>{project.descripcion}</td>
                              <td style={{ textAlign: 'center' }}>{project.developers}</td>
                              <td style={{ textAlign: 'end' }} className="text-right">
                                Edit
                                <a className="edit-link" onClick={async () => {
                                  getDevelopers(project.id, project.name);
                                  setEdition(true);
                                }
                                }><Image src={editicon} style={{ marginBottom: '', marginTop: '-5px', paddingLeft: '10px' }} width="30px" alt="Logo" /></a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  }
                </Col>
              </Row>

              {(projects.length == 0 && loading == false) &&
                <div>
                  <><Row className="justify-content-center my-4">
                    <Col xs="6" style={{ display: 'flex', justifyContent: 'center' }}>
                      <Image style={{ width: '50%', marginTop: '60px', marginRight: '0' }} src={Logo} fluid />
                    </Col>
                  </Row>
                    <Col style={{ textAlign: 'center', marginBottom: '60px', marginRight: '0' }}>
                      <Row style={{ marginRight: '0' }}><Col><h5>You don’t have data to show</h5></Col></Row>
                      <Row style={{ marginRight: '0' }}><Col>When you have data to show, you’ll find here.</Col></Row>
                      {/*} <Row style={{marginRight:'293px'}}><Col>want to track, you'll find here. </Col></Row>*/}
                    </Col>
                  </>
                </div>
              }

              {showModal &&
                <AddTeamModal
                  show={showModal}
                  organization={SessionService.ORGANIZATION}
                  handleClose={handleModal} />
              }
              {modal}
            </Col>
          </Row>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default OrganizationTeams;


