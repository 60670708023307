import React, { FunctionComponent, Component, useEffect, useState, useRef } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Card, Form, Button, Spinner, InputGroup, FormControl, Container } from 'react-bootstrap';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useParams, useHistory } from 'react-router-dom';
import ObexAlert from '../basicComponents/ObexAlerts';
import ConfirmDeleteProjectModal from '../Modals/ConfirmDeleteProjectModal';
import SessionService from '../../services/SessionsService';
import GoToProductionModal from '../Modals/GoToProductionModal';
import Cookies from 'universal-cookie';
import DeployTemplateModal from '../Modals/DeployTemplateModal';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';


type ProjectInfoProps = {
  projects: Array<unknown>
};

const projectStored = {
  name: '',
  description: '',
  apikey: '',
  benefits: 0,
  creation_date: '',
  cipherKey: '',
  tx: {
    limit: 0,
    current: 0,
    rest: 0
  },
  type: ''
}

const ProjectInfo: FunctionComponent<ProjectInfoProps> = (props: ProjectInfoProps) => {

  const history = useHistory();

  const { project } = useParams();
  const { projects } = props;
  let puntose = '';
  let contadore = 0;
  const [projectInfo, setProjectInfo] = useState(projectStored);
  const [tokenString, setTokenString] = useState('');
  const [projectInfoId, setProjectInfoID] = useState(0);
  const [TemplateInfo, settemplateInfo] = useState({ id: 0, description: '', code: '', active: true });
  const [IsInProduction, setIsInProduction] = useState(false);
  const [IsInProductionAsked, setIsInProductionAsked] = useState(false);
  const [oldProjectName, setoldProjectName] = useState('')
  const [projectName, setProjectName] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [projectLimit, setProjectLimit] = useState('');
  const [originalLimit, setOriginalLimit] = useState('');
  const [projectBenefits, setProjectBenefits] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [productioning, setProductioning] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showAlert, setAlert] = useState(true);
  const IDProject = useRef(null);
  const projectAPIKey = useRef(null);
  const projectURL = useRef(null);
  const cipherKey = useRef(null);
  const [usuariosProj, setUsuarios] = useState([]);
  const [ListTemplates, setListTemplates] = useState([]);
  const [templateID, setTemplateID] = useState(-1);

  const [sublinks, setSublinks] = useState([{
    path: '#',
    title: 'Loading Data ',
  }]);
  const [stillLoaded, SetStillLoaded] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => { setShowModal(!showModal) }

  const [showTempleteModal, SetshowTempleteModal] = useState(false);
  const handleShowTempleteModal = (templateId) => {
    if (templateId != -1) {
      let template = { id: 0, description: '', code: '', active: false };
      Templates.forEach((tt) => {
        if (tt.id === templateId) template = tt;
      })
      // console.log(template);
      settemplateInfo(template);
      SetshowTempleteModal(!showTempleteModal);
    }
  }


  const [showProdModal, setShowProdModal] = useState(false);
  const handleShowProdModal = () => {
    setSubmitDisabled(true);
    setCreateOSelect(true);
    set_cliente_email('');
    SetclientAssigned('');
    getClient();
    setFormulario(false);
    setFormulario2(false);
    setShowProdModal(!showProdModal)
  }

  const [creatOselect, setCreateOSelect] = useState(true)
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [Copied, setCopied] = useState('');
  const [titulo, setTitulo] = useState('');

  const [loadingCli, setLoadingCli] = useState(false);
  const [cliente_email, set_cliente_email] = useState('');
  const [cliente, setCliente] = useState('');
  const [clientAssigned, SetclientAssigned] = useState('');

  const [Formulario, setFormulario] = useState(false);
  const [Formulario2, setFormulario2] = useState(false);

  const [Templates, setTemplates] = useState([]);


  const getTemplates = async () => {
    setLoadingCli(true);
    const cliente = (await ObexRequestHandler.get('/products', {}, true));

    if (cliente.success) {
      // console.log('CLI')
      // console.log(cliente.data);
      setTemplates(cliente.data.products);

      const XcountriesList = cliente.data.products.map(c => <option key={'getTEmplatesSigh' + c.country_code + Math.random()} value={c.id} >{c.description}</option>);

      setListTemplates(XcountriesList);

    } else {
      // console.log('NO CLI')
      setTemplates([]);
    }



    //console.log(cliente.data)
    setLoadingCli(false);
  }



  const getClient = async () => {

    const cookies = new Cookies();
    const userInfo = cookies.get('101Obex');

    setLoadingCli(false);
    set_cliente_email(userInfo.email);
    const cliente = (await ObexRequestHandler.get(`/client/${userInfo.email}/username`, {}, true));

    if (cliente.success) {
      // console.log('CLI')
      // console.log(cliente.data);
      setCliente(cliente.data.username);
      // set_cliente_email(cliente.data.username)
      SetclientAssigned(cliente.data.username);

    } else {
      // console.log('NO CLI')
      setCliente('');
    }



    //console.log(cliente.data)
    setLoadingCli(false);
  }



  const getProjectInfo = async () => {
    SetStillLoaded(true);
    const interval = setInterval(() => {
      if (contadore < 5) {
        puntose = puntose + '.'
        contadore++;

      } else {
        contadore = 0;
        puntose = '.';
      }
      setSublinks([{ path: '#', title: 'Loading Data ' + puntose }]);
    }, 100);

    try {
      const usuarios = (await ObexRequestHandler.get(`/projects/${project}/users`, {}, true)).data || {};
      setUsuarios(usuarios);
      const projectInformation = (await ObexRequestHandler.get(`/projects/${project}`, {}, true)).data || {};
      console.log(projectInformation);
      const productionProject = (await ObexRequestHandler.get(`/is_in_production?project_id=${projectInformation.id}`, {}, true)).data || {};

      const productionProjectAsked = (await ObexRequestHandler.get(`/is_in_production_asked?project_id=${projectInformation.id}`, {}, true)).data || {};

      setIsInProduction(productionProject.production);
      setIsInProductionAsked(productionProjectAsked.production);

      // console.log("setIsInProductionAsked")
      // console.log(productionProjectAsked.production);

      // const projectsStaging = (await ObexRequestHandler.get('/staging_projects', {}, true)).data || [];
      const pro = projects ? projects : (await ObexRequestHandler.get('/projects', {}, true)).data || []; // Si no recibe los projects por los props los rescatamos por request
      const sublinksProjects = []
      let nn;
      pro.filter(crea => crea.isCreating).map((project, i) => {
        if (projectCode == project.name && projectName != '') { nn = projectName; setProjectName(''); } else nn = project.description;
        const linkProject = {
          path: project.name,
          title: nn,
        }
        sublinksProjects.push(linkProject)
      });
      clearInterval(interval);
      setSublinks(sublinksProjects);

      // console.log(projectInformation);

      setProjectInfo(projectInformation);
      setProjectInfoID(projectInformation.id)
      setTitulo(projectInformation.description);
      setoldProjectName(projectInformation.description);
      setProjectCode(projectInformation.name);
      setProjectLimit(new Intl.NumberFormat('en-US').format((projectInformation.tx.limit)));
      setOriginalLimit(new Intl.NumberFormat('en-US').format(projectInformation.tx.limit));
      setProjectBenefits(projectInformation.benefits);
      SetStillLoaded(false);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    }
  }

  const alertaCopy = (identificador) => {
    setCopied(identificador);
    setTimeout(() => {
      setCopied('');
    }, 2000);

  }

  const updateProjectInfo = async () => {
    try {
      setLoading(true);
      setSuccess('');
      setError('');
      if (projectName) {
        const { success, data, message } = await ObexRequestHandler.put(`/projects/${project}/name`, { name: projectName });
        if (!success) throw { message };
      }
      if (projectLimit !== originalLimit) {
        let old_projectLimit = projectLimit.replace('.', '');
        old_projectLimit = old_projectLimit.replace(',', '');
        const newProjectLimit = old_projectLimit;
        const { success, data, message } = await ObexRequestHandler.put(`/projects/${project}/rate-limit`, { rate: newProjectLimit });
        if (!success) throw { message };
      }
      setSuccess('Project updated');
      setTimeout(() => {
        setSuccess('')
        setSubmitDisabled(true);
      }, 2000);


      setTitulo(projectName);
      getProjectInfo();
      //getProjectInfo();
    } catch (error) {
      setError(error.message || error);
      setLoading(false);
    }
    setLoading(false);
  }

  const handleProjectName = (e) => {
    setProjectName(e.target.value);
    setSubmitDisabled(false);
  }

  const handleProjectLimit = (e) => {
    let t = e.target.value.replace(',', '');
    t = t.replace('.', '');
    setProjectLimit(new Intl.NumberFormat('en-DE').format(t));
    e.target.value = new Intl.NumberFormat('en-DE').format(t);
    setSubmitDisabled(false);
  }

  const handleProjectBenefits = (e) => {
    setProjectBenefits(e.target.value);
    setSubmitDisabled(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await updateProjectInfo();
  }

  const routeCommission = (project) => {
    history.push(`/projects/${project}/commissions`);
  }

  const handleRouteCurrencies = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { name } = projectInfo;
    history.push(`/projects/${name}/exchange_activated/`);
  }

  const deleteProject = async (project) => {
    try {
      setSuccess('');
      setDeleting(true);
      const result = await ObexRequestHandler.delete(`/projects/${project}`);
      const { success, message, data } = result;
      if (!success) {
        setError(message)
      } else {
        setSuccess('true');
        setTimeout(() => {
          history.push('/projects');
        }, 2000);

      }
    } catch (error) {
      setError('Project cannot be deleted.');
    }
    setDeleting(false);
    setSuccess('success');
    SessionService.renewSession();
  }


  const deployProject = async (projectId, TokenString, templateCode) => {
    try {
      setSuccess('');
      setDeleting(true);

      const payload = {
        product: templateCode,
        auth_token: TokenString,
        project_id: projectId,
        template_id: 1
      }

      console.log(payload)
      const result = await ObexRequestHandler.post('/invitation_fork/', payload);
      const { success, message, data } = result;
      if (!success) {
        setError(message)
        setTimeout(() => {
          // history.push(`/projects/${projectInfo.name}`);
          SetshowTempleteModal(false);
        }, 3000);
      } else {
        setSuccess('true');
        const payload2 = {
          product: templateCode,
          auth_token: TokenString,
          project_id: projectId
        }
        const result2 = await ObexRequestHandler.post('/update_product_config/', payload2);
        setTimeout(() => {
          // history.push(`/projects/${projectInfo.name}`);
          SetshowTempleteModal(false);
        }, 2000);

      }
    } catch (error) {
      setError('Project cannot be deleted.');
    }
    setDeleting(false);
    setSuccess('success');
    SessionService.renewSession();
  }

  const GotoProductionProject = async (project) => {
    // try {
    setProductioning(true);


    const resulta = await ObexRequestHandler.get(`/client/${cliente_email}/username`, {}, true);

    try {
      if (resulta.success) {
        // console.log(resulta.data.id);
        try {

          const cliente_email_id = resulta.data.id;
          const cookies = new Cookies();
          const userInfo = cookies.get('101Obex');
          // console.log(userInfo.email)
          const payload = { selfproject: userInfo.email == cliente_email };
          // console.log(cliente_email)
          const result = await ObexRequestHandler.post(`/client/${cliente_email_id}/project/${projectInfo['id']}`, payload);
          const { success, message, data } = result;
          if (!success) {
            setError(message)
          } else {


            setTimeout(() => {
              history.push('/projects');
            }, 2000);



          }
        } catch (error) {
          setError('Project cannot go to production.');
        }
      }
    } catch {
      setError('Project cannot go to production.');
    }
    setSuccess('Project is now in Production')
    setProductioning(false);
    SessionService.renewSession();
  }

  const copyTextID = (e) => {
    IDProject.current.disabled = false;
    IDProject.current.select();
    document.execCommand('copy');
    IDProject.current.disabled = true;
  }

  const copyAPIKey = () => {
    projectAPIKey.current.disabled = false;
    projectAPIKey.current.select();
    document.execCommand('copy');
    projectAPIKey.current.disabled = true;
  }

  const copyURL = () => {
    projectURL.current.disabled = false;
    projectURL.current.select();
    document.execCommand('copy');
    projectURL.current.disabled = true;
  }

  const copyCypherKey = () => {
    cipherKey.current.disabled = false;
    cipherKey.current.select();
    document.execCommand('copy');
    cipherKey.current.disabled = true;
  }

  const handleDeleteProject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteProject(projectInfo.name);
  }


  const handleDeployTemplate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deployProject(projectInfoId, tokenString, TemplateInfo.code);
  }



  const handleProductionProject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    GotoProductionProject(projectInfo.name);
  }


  const handleRouteCommission = (e) => {
    e.preventDefault();
    e.stopPropagation();
    routeCommission(projectInfo.name);
  }

  useEffect(() => {
    getProjectInfo();
    getTemplates();
  }, [project]);

  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'projects'} sublinks={sublinks}>
          {stillLoaded ?

            <Container className="vh-100 d-flex flex-column vw-100">
              <Row className="h-100" >


                <Col md="1" className="mx-auto d-flex align-items-center">
                  <Spinner animation="border" />
                </Col>

              </Row>
            </Container>
            :
            <Row>
              <Col style={{ marginLeft: '-15px' }}>
                <Row>
                  <Col>
                    <h1 className="mb-3">{titulo}</h1>
                  </Col>
                </Row>
                <Row>

                  <Col>
                    <Card className="product-detail-card mb-3">
                      <Card.Body className="p-3">
                        <Row>
                          <Col className="mx-auto">
                            <Form className="obex-form" onSubmit={handleSubmit}>
                              <Form.Row className="mb-3">
                                <Form.Label column md="3" className="obex-form-label">
                                  Project Name
                                </Form.Label>
                                <Col>
                                  <Form.Control type="text" defaultValue={projectInfo.description} onChange={handleProjectName} />
                                </Col>
                              </Form.Row>
                              <Form.Row className="mb-3">
                                <Form.Label column md="2" className="obex-form-label">
                                  Type
                                </Form.Label>
                                <Col>
                                  <Form.Control plaintext readOnly defaultValue={projectInfo.type} />
                                </Col>
                                <Form.Label column md="2" className="obex-form-label">
                                  Created
                                </Form.Label>
                                <Col>
                                  <Form.Control plaintext readOnly defaultValue={projectInfo.creation_date} />
                                </Col>
                              </Form.Row>
                              <Form.Row className="mb-3">
                                <Form.Label column md="5" className="obex-form-label">
                                  Monthly Request limit
                                </Form.Label>
                                <Col>
                                  <Form.Control type="number" placeholder="limit Request monthly" value={projectLimit} onChange={handleProjectLimit} />
                                </Col>
                              </Form.Row>
                              <Form.Row className="mb-3">
                                <Form.Label column md="5" className="obex-form-label">
                                  Monthly spending limit
                                </Form.Label>
                                <Col>
                                  <Form.Control disabled={true} type="number" placeholder="limit Balance monthly" value={projectBenefits} onChange={handleProjectBenefits} />
                                </Col>
                              </Form.Row>
                              <Row>
                                <Col>
                                  <ObexAlert type='error' msg={error} />
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md="4" className="ml-auto">
                                  <Button disabled={false} type="submit" className="custom-button-blue w-80 textBold">
                                    Update {success && <FontAwesomeIcon icon={faCheck} />}
                                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} onClick={handleSubmit} />}
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                </Row>
                <Row>
                  <Col className="mx-auto">
                    <Row>
                      <Col>
                        <h1 className="mb-3">API Project information</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card className="product-detail-card obex-card project-access-card mb-3">
                          <Card.Body className="p-3">
                            <Form className="obex-form">
                              <Form.Row className="mb-3">
                                <Form.Label column md="2" className="obex-form-label">
                                  ID
                                </Form.Label>
                                <Col>
                                  <InputGroup className="obex-input-group mb-3">
                                    <FormControl
                                      ref={IDProject}
                                      placeholder={projectInfo.name}
                                      aria-describedby={projectInfo.name}
                                      value={projectInfo.name}
                                      disabled
                                    />
                                    <InputGroup.Append>
                                      <Button className="text-black" value="id_project" style={{ backgroundColor: '#e9ecef' }} onClick={() => {
                                        navigator.clipboard.writeText(projectInfo.name)
                                        alertaCopy(projectInfo.name);
                                      }}>
                                        {Copied == projectInfo.name ?
                                          <FontAwesomeIcon icon={faCheck} /> :
                                          <FontAwesomeIcon icon={faCopy} />
                                        }
                                      </Button>
                                    </InputGroup.Append>
                                  </InputGroup>
                                </Col>
                              </Form.Row>
                              <Form.Row className="mb-3">
                                <Form.Label column md="2" className="obex-form-label">
                                  API Key
                                </Form.Label>
                                <Col>
                                  <InputGroup className="obex-input-group mb-3">
                                    <FormControl
                                      ref={projectAPIKey}
                                      placeholder="API Key"
                                      aria-describedby={projectInfo.apikey}
                                      value={projectInfo.apikey}
                                      disabled
                                    />
                                    <InputGroup.Append>
                                      <Button className="text-black" style={{ backgroundColor: '#e9ecef' }} onClick={() => {
                                        navigator.clipboard.writeText(projectInfo.apikey)
                                        alertaCopy(projectInfo.apikey);
                                      }}>
                                        {Copied == projectInfo.apikey ?
                                          <FontAwesomeIcon icon={faCheck} /> :
                                          <FontAwesomeIcon icon={faCopy} />
                                        }
                                      </Button>
                                    </InputGroup.Append>
                                  </InputGroup>
                                </Col>
                              </Form.Row>

                              {projectInfo.cipherKey &&
                                <Form.Row className="mb-3">
                                  <Form.Label column md="2" className="obex-form-label">
                                    Cipher Key
                                  </Form.Label>
                                  <Col>
                                    <InputGroup className="obex-input-group mb-3">
                                      <FormControl
                                        ref={cipherKey}
                                        placeholder="Cypher Key"
                                        aria-describedby={projectInfo.cipherKey}
                                        value={projectInfo.cipherKey}
                                        disabled
                                      />
                                      <InputGroup.Append>
                                        <Button className="text-black" style={{ backgroundColor: '#e9ecef' }} onClick={() => {
                                          navigator.clipboard.writeText(projectInfo.cipherKey)
                                          alertaCopy(projectInfo.cipherKey);
                                        }}>
                                          {Copied == projectInfo.cipherKey ?
                                            <FontAwesomeIcon icon={faCheck} /> :
                                            <FontAwesomeIcon icon={faCopy} />
                                          }
                                        </Button>
                                      </InputGroup.Append>
                                    </InputGroup>
                                  </Col>
                                </Form.Row>
                              }
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/*}
        <Row>
          <Col>
            <h1 className="my-3">Edit Fees</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="8" className="mt-2">
                      <span>
                        Edit Fees
                      </span>
                    </Col>
                    <Col md="4" className="my-auto">
                      <Button disabled={deleting} onClick={handleRouteCommission} className="custom-button-blue w-80 textBold">
                        Edit Fees
                      </Button>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="my-3">Edit currencies</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="8" className="mt-2">
                      <span> 
                        Edit currencies
                      </span>
                    </Col>
                    <Col md="4" className="my-auto">
                      <Button disabled={deleting} onClick={handleRouteCurrencies} className="custom-button-blue w-80 textBold">
                        Edit Currencies
                      </Button>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
*/}
                {/* ////////////////////////////////////////////Project templates invalidated ///////////////////////////////////////////////// */}
                {/* 
        <Row>          
          <Col>
            <h1 className="my-3">Project Templates</h1>
          </Col>
        </Row> */}

                {/* <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">

                <Card.Text>

                  <Row>
                    <Col>
                <Form.Control required as="select" placeholder='Country' custom onChange={(e)=>{
                  // console.log(e.target.value);
                  // console.log(Templates);
                  setTemplateID(parseInt(e.target.value));
                  }}>
                          <option value="" selected disabled hidden>Choose Template</option>
                            {ListTemplates}
                        </Form.Control>
                        </Col>
                        <Col md='4'>
                        <Button disabled={deleting} onClick={()=>{handleShowTempleteModal(templateID)}} className="btn-block obex-btn btn-green">
                        Manage
                      </Button>
                        </Col>
                        </Row>

                </Card.Text> */}
                {/*}

                <Card.Text>

                {Templates.map(tempp =>  (
                  <Row style={{ marginBottom:'20px' }} key={tempp.id}>
                    <Col md="8" className="">
                      <span>{tempp.description}</span>
                    </Col>
                    <Col md="4" className="my-auto">
                      <Button disabled={deleting} onClick={()=>{handleShowTempleteModal(tempp)}} className="btn-block obex-btn btn-green">
                        Manage
                      </Button>
                    </Col>
                  </Row>
                ))}

                </Card.Text>
                */}
                {/* </Card.Body>
            </Card>
          </Col>
        </Row> */}


                {usuariosProj['default_affiliate']['user_id'] != '' &&
                  <><Row>
                    <Col>
                      <h1 className="my-3">Project Users</h1>
                    </Col>
                  </Row><Row>
                      <Col>
                        <Card className="product-detail-card mb-3">
                          <Card.Body className="p-3">
                            <Card.Text>
                              <Row style={{ fontFamily: 'Regular Intro Bold ' }}>
                                <Col className="m-2" style={{ maxWidth: '20%' }}>
                                  Description
                                </Col>
                                <Col style={{ maxWidth: '6%' }} className="m-2">
                                  Id
                                </Col>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  Nick
                                </Col>
                                <Col style={{ maxWidth: '33%' }} className="m-2">
                                  PIN
                                </Col>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  Private Key
                                </Col>

                              </Row>
                              <Row>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  Default Affiliate
                                </Col>
                                <Col style={{ maxWidth: '6%' }} className="m-2">
                                  {usuariosProj['default_affiliate']['user_id']}
                                </Col>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  {usuariosProj['default_affiliate']['nick']}
                                </Col>
                                <Col style={{ maxWidth: '30%' }} className="m-2">
                                  {usuariosProj['default_affiliate']['pin']}
                                </Col>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  {usuariosProj['default_affiliate']['private_key']}
                                </Col>

                              </Row>
                              <Row>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  Admin Affiliate
                                </Col>
                                <Col style={{ maxWidth: '6%' }} className="m-2">
                                  {usuariosProj['admin_affiliate']['user_id']}
                                </Col>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  {usuariosProj['admin_affiliate']['nick']}
                                </Col>
                                <Col style={{ maxWidth: '30%' }} className="m-2">
                                  {usuariosProj['admin_affiliate']['pin']}
                                </Col>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  {usuariosProj['admin_affiliate']['private_key']}
                                </Col>

                              </Row>

                              <Row>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  Fees Affiliate
                                </Col>
                                <Col style={{ maxWidth: '6%' }} className="m-2">
                                  {usuariosProj['fees_affiliate']['user_id']}
                                </Col>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  {usuariosProj['fees_affiliate']['nick']}
                                </Col>
                                <Col style={{ maxWidth: '30%' }} className="m-2">
                                  {usuariosProj['fees_affiliate']['pin']}
                                </Col>
                                <Col style={{ maxWidth: '20%' }} className="m-2">
                                  {usuariosProj['fees_affiliate']['private_key']}
                                </Col>

                              </Row>




                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row></>

                }

                <Row>
                  <Col>
                    <h1 className="my-3">Delete API Project</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="product-detail-card mb-3">
                      <Card.Body className="p-3">
                        <Card.Text>
                          <Row>
                            <Col md="8" className="">
                              <span>By closing your account, you will lose access to
                                your financial data and your key will expire.
                                This is irreversible.</span>
                            </Col>
                            <Col md="4" className="my-auto">
                              <button disabled={deleting} onClick={handleShowModal} className="custom-button-orange w-80 textBold">
                                Delete Project
                              </button>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {IsInProduction ?

                  <Row>
                    <Col>
                      <Card className="product-detail-card mb-3">
                        <Card.Body className="p-3">
                          <Card.Text>
                            <Row>
                              <Col md="8" className="">
                                <span>This Project is actually in Production</span>
                              </Col>
                              <Col md="4" className="my-auto">
                              </Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row> :

                  (IsInProductionAsked ?
                    <Row>
                      <Col>
                        <Card className="product-detail-card mb-3">
                          <Card.Body className="p-3">
                            <Card.Text>
                              <Row>
                                <Col md="8" className="">
                                  <span>This Project is asked to go to Production</span>
                                </Col>
                                <Col md="4" className="my-auto">
                                </Col>
                              </Row>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    :

                    <Row style={{ height: '3rem' }}>
                      {/*}
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="8" className="">
                      <span>By going to production, a client will be assigned to own this project, and your organization still be the maintainer developer.</span>
                    </Col>
                    <Col md="4" className="my-auto">
                      <Button disabled={productioning} onClick={handleShowProdModal} className="custom-button-blue w-80 textBold">
                        Go to Production
                      </Button>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          */}
                    </Row>)



                }

                <GoToProductionModal
                  handleSubmit={handleProductionProject}
                  showModal={showProdModal}
                  onCloseModal={handleShowProdModal}
                  set_cliente_email={set_cliente_email}
                  SetclientAssigned={SetclientAssigned}
                  setCliente={setCliente}
                  setLoadingCli={setLoadingCli}
                  setFormulario={setFormulario}
                  setFormulario2={setFormulario2}
                  setCreateOSelect={setCreateOSelect}
                  setSubmitDisabled={setSubmitDisabled}
                  submitDisabled={submitDisabled}
                  creatOselect={creatOselect}
                  Formulario={Formulario}
                  Formulario2={Formulario2}
                  cliente_email={cliente_email}
                  clientAssigned={clientAssigned}
                  cliente={cliente}
                  loadingCli={loadingCli}
                  loading={productioning}
                  error={error}
                  success={success}
                  name_to_check={projectInfo.name}
                  title_to_show={projectInfo.description}
                  username={SessionService.CLIENT_USERNAME}
                />

                <ConfirmDeleteProjectModal
                  handleSubmit={handleDeleteProject}
                  showModal={showModal}
                  onCloseModal={handleShowModal}
                  loading={deleting}
                  error={error}
                  success={success}
                  name_to_check={projectInfo.name}
                  title_to_show={projectInfo.description}
                />

                <DeployTemplateModal
                  handleSubmit={handleDeployTemplate}
                  showModal={showTempleteModal}
                  onCloseModal={() => { SetshowTempleteModal(!showTempleteModal) }}
                  loading={deleting}
                  error={error}
                  success={success}
                  name_to_check={TemplateInfo.code}
                  title_to_show={TemplateInfo.description}
                  project_id={projectInfoId}
                  tokenString={tokenString}
                  setTokenString={setTokenString}
                />


              </Col>
            </Row>
          }
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>

  )
}
// TODO possibleProjectsToBuy rescartar la info
export default ProjectInfo;