import React, { FunctionComponent, useEffect, useState } from 'react';
import BaseLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Spinner } from 'react-bootstrap';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type MarketPlaceProjectsProps = {
};


const MarketPlaceProjects: FunctionComponent<MarketPlaceProjectsProps> = () => {

  const history = useHistory();

  const [loadingTable, setLoadingTable] = useState(false);
  const [projects, setProjects] = useState([]);
  const [sublinks, setSublinks] = useState([]);

  const getInfo = async () => {
    try {
      setLoadingTable(true);
      const result = (await ObexRequestHandler.get('/projects?basic=true')).data || [];
      setProjects(result.filter(rr => rr.isCreating));
      const sublinksProjects = []
      projects.map((project, i) => {
        const linkProject = {
          path: project.name,
          title: project.description,
        }
        sublinksProjects.push(linkProject)
      });
      setSublinks(sublinksProjects);
    } catch (error) {
      console.error('ERROR GETTING INFO ', error)
    }
    setLoadingTable(false);
  }

  const projectRows = projects.map(p => (
    <tr key={'marketplaceprojets' + p.name}>
      <td>{p.description}</td>
      <td>{p.name}</td>
      <td className="text-left">
        <FontAwesomeIcon
          onClick={() => history.push(`/marketplace/${p.name}`)}
          cursor="pointer"
          icon={faPencilAlt}
          size="1x"
          className="mr-1" />
      </td>
    </tr>
  ))


  const loadingSpinner = <Row><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText = <Row>
    <Col className="mx-auto text-center">
      <span className="d-block">
        You have not yet registered any project in your account.
      </span>
    </Col>
  </Row>;


  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <BrowserView>
        <BaseLayout sider={DashRouters} active={'marketplace'} sublinks={sublinks}>
          <Row>
            <Col md={10}>
              <h1>Marketplace</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive>
                <thead style={{
                  fontFamily: 'Regular Intro Bold',
                  textAlign: 'start',
                  backgroundColor: 'transparent',
                  color: 'black',
                  borderWidth: '1px',
                  borderRadius: '15px',
                  borderStyle: 'hidden'
                }}>
                  <tr>
                    <th className='textBold'>Project Name</th>
                    <th className='textBold'>Project ID</th>
                    <th className='textBold'>Edit plugins</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingTable && projectRows}
                </tbody>
              </Table>
            </Col>
          </Row>
          {loadingTable && loadingSpinner}
          {(!loadingTable && projects.length <= 0) && noProjectsText}
        </BaseLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default MarketPlaceProjects;