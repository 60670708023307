import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Form, InputGroup, FormControl, Button, Spinner } from 'react-bootstrap';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { useParams, useHistory } from 'react-router-dom';
import ObexToast from '../basicComponents/ObexToasts';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommissionsSectionsModal from '../Modals/commissionsSectionsModal';
import FetchingSpinner from '../basicComponents/loaderModal';
import ProjectNeeded from '../Modals/ProjectNeeded';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';



const CommissionContent: FunctionComponent<{}> = () => {
  var contadore = 0;
  var puntose = '';
  const { project } = useParams();
  const [projects, setProjects] = useState([]);
  const [profiles, setProfiles] = useState(['All']);
  const [selectedProfile, setSelectedProfile] = useState('All');
  const [profile, setProfile] = useState('');
  const [property, setProperty] = useState('');
  const [actionId, setActonId] = useState(0);
  const [commissions, setCommissions] = useState([]);
  const [filteredCommissions, setFilteredComissions] = useState([]);
  const [editing, setEditing] = useState('');
  const [editingSections, setEditingSections] = useState(false);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [contador, setContador] = useState(0);
  const [puntos, setPuntos] = useState('');
  const [showProjectNeeded, setProjectNeeeded] = useState(false);
  const history = useHistory();
  const [sublinks, setSublinks] = useState([

    {
      "path": `#`,
      "title": "Loading Data ",
    }
  ]);

  const [maximum, setMaximum] = useState('');
  const [percentage, setPercent] = useState('');
  const [fix, setFix] = useState('');

  const onCloseProjectNeeded = () => {
    setProjectNeeeded(false);
    history.push('/projects')
  }


  const getProjects = async () => {
    const interval = setInterval(() => {
      if (contadore < 5) {
        puntose = puntose + "."
        contadore++;

      } else {
        contadore = 0;
        puntose = '.';
      }

      setSublinks([{ "path": `#`, "title": "Loading Data " + puntose }]);

    }, 100);

    const projects = (await ObexRequestHandler.get('/projects')).data;
    const sublinksProjects = []
    projects.filter(crea => crea.isCreating).map((project, i) => {
      var linkProject = {
        "path": project.name,
        "title": project.description,
      }
      sublinksProjects.push(linkProject)
    });
    setSublinks(sublinksProjects);
    const optionProjects = projects.filter(crea => crea.isCreating).map(value => <option value={value.name}>{value.description}</option>)
    setProjects(optionProjects);
    clearInterval(interval);
    setLoading(false);
  }

  const getProjectCommission = async (sel_proj, init = false) => {
    try {
      setLoading(true);
      var sel_proje;
      if (sel_proj == null) sel_proje = project; else sel_proje = sel_proj.target.value;
      const commissionSettings = (await ObexRequestHandler.get(`/projects/${sel_proje}/commissions`, {}, true)).data || {};
      let existingProfiles = commissionSettings.map(c => { return ({ "profile": c.profile, "name": c.profile_name }) });
      var distinctProfiles = []
      existingProfiles.map(v => {
        if (!distinctProfiles.some(value => value.profile === v.profile)) {
          distinctProfiles.push(v);
        }
      })
      setCommissions(commissionSettings);
      setFilteredComissions(commissionSettings);
      setProfiles(distinctProfiles.sort());
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    }
    if (!init) setLoading(false);

  }

  const filterComissions = (value) => {
    setEditing(''); // Deshabilitamos la edición
    const filteredCommissions = value !== 'All'
      ? commissions.filter(c => c.profile == value)
      : commissions;
    setFilteredComissions(filteredCommissions);
  }

  const handleProfile = (e) => {
    setLoading(true);
    const { value } = e.target;
    setSelectedProfile(value);
    filterComissions(value);
    setLoading(false);
  }

  const startSaving = () => {
    setError('');
    setSaving(true);
  }

  const saveCommissions = async (customSections = false): Promise<any> => {
    try {
      const values = { maximo: parseFloat(maximum), porcentaje: parseFloat(percentage), fijo: parseFloat(fix), tramos: sections };
      if (Array.isArray(customSections)) values.tramos = customSections;
      if (maximum != '0' && !maximum) delete values.maximo;
      if (percentage != '0' && !percentage) delete values.porcentaje;
      if (fix != '0' && !fix) delete values.fijo;
      const stringValues = JSON.stringify(values);
      const payload = {
        values: stringValues,
        actionId,
        profile,
        property
      }
      const result = await ObexRequestHandler.post(`/projects/${project}/commissions`, payload);
      return result;
    } catch (error) {
      throw { message: error.message || error };
    }

  }

  const saveSuccess = async () => {
    setEditingSections(false);
    setSelectedProfile('All');
    await getProjectCommission(null, false);
  }

  const save = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      startSaving();
      const result = await saveCommissions(false);
      const { success, data, message } = result;
      if (!success) throw { message };
      await saveSuccess();
    } catch (error) {
      console.error('Error saving commissions ', error);
      setError(error.message || error);
    }
    setSaving(false);
    setEditing('')
  }

  const profilesList = profiles.map(p => <option value={p["profile"]} selected={selectedProfile == p["profile"]}>{p["name"]}</option>);

  const getFilteredCommissions = () => {
    const commissions = [];
    for (let i = 0; i < filteredCommissions.length; i++) {
      const commission = filteredCommissions[i];
      const id = `row-${i}`;
      const { profile, name_action, property, value, id_action, profile_name } = commission;
      let { maximo, porcentaje, tramos, fijo } = value;
      tramos = tramos || [];
      maximo = (maximo != 0 && !maximo) ? '' : maximo;
      porcentaje = (porcentaje != 0 && !porcentaje) ? '' : porcentaje;
      fijo = (fijo != 0 && !fijo) ? '' : fijo;
      const editingThis = editing === id;
      commissions.push(
        <Row id={id} className="border-bottom">
          <Col className="mt-2">
            <span>{profile_name || 'Everyone'}</span>
          </Col>
          <Col className="mt-2">
            <span>{name_action}</span>
          </Col>
          <Col className="mt-2">
            <span>{property}</span>
          </Col>
          <Col className="mt-2">
            {editingThis
              ? <Form.Control
                type="number"
                value={maximum}
                placeholder="Maximum"
                onChange={(e) => {
                  const { value } = e.target;
                  setMaximum(value)
                }}
              />
              : <span>{maximo}</span>
            }
          </Col>
          <Col className="mt-2">
            {editingThis
              ? <Form.Control
                type="number"
                value={percentage}
                placeholder="Percentage"
                onChange={(e) => {
                  const { value } = e.target;
                  setPercent(value)
                }}
              />
              : <span>{`${porcentaje}%`}</span>
            }
          </Col>
          <Col className="mt-2">
            {editingThis
              ? <Form.Control
                type="number"
                value={fix}
                placeholder="Fix"
                onChange={(e) => {
                  const { value } = e.target;
                  setFix(value)
                }}
              />
              : <span>{`${fijo}`}</span>
            }
          </Col>
          <Col className="mt-2">
            <span>
              Show &nbsp;
              <FontAwesomeIcon
                cursor="pointer"
                icon={faEye}
                onClick={() => {
                  setMaximum(maximo);
                  setPercent(porcentaje);
                  setFix(fijo);
                  setActonId(id_action);
                  setProperty(property);
                  setProfile(profile);
                  setSections(tramos);
                  setEditing('');
                  setEditingSections(true);
                }}
              />
            </span>
          </Col>
          <Col className="mt-2">
            {editingThis
              ? <div style={{ display: 'inline-flex' }}>
                <Button
                  size="sm"
                  style={{ width: '65px' }}
                  onClick={save}
                  type="submit"
                  className="obex-btn btn-green"
                  disabled={saving}
                >
                  {saving && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  Save
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  className="obex-btn btn-orange"
                  style={{ width: '65px' }}
                  onClick={() => setEditing('')}>
                  Cancel
                </Button>
              </div>
              : <FontAwesomeIcon
                cursor="pointer"
                onClick={() => {
                  setMaximum(maximo);
                  setPercent(porcentaje);
                  setFix(fijo);
                  setSections(tramos);
                  setActonId(id_action);
                  setProperty(property);
                  setProfile(profile);
                  setEditing(id);
                }}
                icon={faPencilAlt} />
            }
          </Col>
        </Row>
      )
    }
    return commissions;
  }



  useEffect(() => {
    if (project == "___no__proj__") {
      setSublinks([]);
      setProjectNeeeded(true);
    } else {
      getProjects();
      getProjectCommission(null, true);
    }
  }, [])


  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'projects'} sublinks={sublinks}>
          <Row>
            <Col md="11" className="mx-auto">
              <Row>
                <Col>
                  <h1 className="mb-4">Project commissions</h1>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <Form.Label column sm="12" className="obex-form-label">
                    Profile
                  </Form.Label>
                  <Form.Control as="select" placeholder='Profile' custom onChange={handleProfile}>
                    <option value="" selected disabled hidden>All</option>
                    {profilesList}
                  </Form.Control>
                </Col>

                <Col md="5" >
                  <Form.Label column sm="12" className="obex-form-label" >
                    Project
                  </Form.Label>
                  <Form.Control as="select" onChange={getProjectCommission} custom defaultChecked={project} >
                    {projects}
                  </Form.Control>
                </Col>





              </Row>
              <Row className="pb-2 border-bottom mt-5" style={{ fontFamily: 'Regular Intro Bold' }}>
                <Col>
                  <span>Profile</span>
                </Col>
                <Col>
                  <span>Action</span>
                </Col>
                <Col>
                  <span>Commission</span>
                </Col>
                <Col>
                  <span>Maximum</span>
                </Col>
                <Col>
                  <span>Percentage</span>
                </Col>
                <Col>
                  <span>Fix</span>
                </Col>
                <Col>
                  <span>Sections</span>
                </Col>
                <Col>
                  <span>Edit</span>
                </Col>
              </Row>
              {loading
                ? <Row style={{ marginTop: '15px' }}>
                  <Col>
                    <FetchingSpinner />
                  </Col>
                </Row>
                : <Fragment>
                  <Form>
                    {getFilteredCommissions()}
                  </Form>
                </Fragment>
              }
            </Col>
          </Row>
          <ProjectNeeded showModal={showProjectNeeded} text={'You need a project to define his commissions'} onCloseModal={onCloseProjectNeeded} />
          <CommissionsSectionsModal
            shown={editingSections}
            receivedSections={sections}
            property={property}
            profile={profile}
            actionId={actionId}
            project={project}
            saveAction={saveCommissions}
            onCloseAction={() => setEditingSections(false)}
            onSuccess={saveSuccess}
          />
          <ObexToast type="error" msg={error} clean={() => setError('')} />
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default CommissionContent;