import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import BaseLayout from '../functionalComponents/DashboardLayout/DevDashboardLayout';
import { Row, Col, Table, Button, Spinner, Form } from 'react-bootstrap';
import TransactionsMediaChart from './GraphComponents/TransactionsMediaChart';
import DashRouters from '../../configs/routers/dev-dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import UpdateRateLimitModal from '../Modals/updateRateLimitModal';
import AddTxSelModal from '../Modals/addTxModalSelect';
import { firstDayInThisMonth, lastDayInThisMonth } from '../../services/TimeService';
import SessionService from '../../services/SessionsService';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type TransactionsProps = {
};


const DevTransactionsContent: FunctionComponent<TransactionsProps> = props => {
  const menuActive = 'transactions';
  let puntose = '';
  let contadore = 0;
  const [loadingTable, setLoadingTable] = useState(false);
  const [editedProject, setEditedProject] = useState({ name: '', id: '', currentLimit: 0, currentConsumed: 0 });
  const [projects, setProjects] = useState([]);
  const [projectsMovements, setProjectMovements] = useState({});
  const [projectTx, setProjectTx] = useState([]);
  const [globalTx, setGlobalTx] = useState({ limit: 0, current: 0, rest: 0 });
  const [editing, setEditing] = useState(false);
  const [addingTx, setAddingTx] = useState(false);
  const [maxTX, setMaxTX] = useState(3100);
  const [actualTX, setActualTX] = useState(0);
  const [sublinks, setSublinks] = useState([
    {
      path: '#',
      title: 'Loading Data ',
    }
  ]);
  const [allLoaded, setAllLoaded] = useState(true);

  const [currentProject, setProject] = useState('');
  const [from, setFrom] = useState(firstDayInThisMonth());
  const [to, setTo] = useState(lastDayInThisMonth());

  const handleAddingTx = () => setAddingTx(true);

  const getInfo = async () => {
    const interval = setInterval(() => {
      if (contadore < 5) {
        puntose = puntose + '.'
        contadore++;

      } else {
        contadore = 0;
        puntose = '.';
      }

      setSublinks([{ path: '#', title: 'Loading Data ' + puntose }]);

    }, 100);
    try {
      setLoadingTable(true);



      const projectsInfo = (await ObexRequestHandler.get('/developer_projects', { org_id: SessionService.ORGANIZATION }, true)).data || [];
      const movements = (await ObexRequestHandler.get('/movements')).data || {};
      const consup = (await ObexRequestHandler.get('/dev_consumption', { org_id: SessionService.ORGANIZATION }, true)).data || {};
      const tx = consup.tx;
      setMaxTX(consup.subscriptionInfo.max_month_rate_limit);
      setActualTX(tx.limit);
      setProjects(projectsInfo);
      // Seteamos por defecto el primer proyecto
      const firstProjectName = projectsInfo[0].name;
      setProject(firstProjectName);
      setAllLoaded(false);
      const movsPerProject = {};
      movements.movements.forEach(p => {
        const { project } = p;
        if (!Object.keys(movsPerProject).includes(project)) movsPerProject[project] = { txNumber: 0, movements: [] };
        movsPerProject[project].movements.push(p);
      })
      Object.keys(movsPerProject).forEach(k => movsPerProject[k].txNumber = movsPerProject[k].movements.length);
      setProjectMovements(movsPerProject);
      const { limit, current, rest } = tx;
      setGlobalTx({ limit, current, rest });
      const projectsTx = tx.projects;
      const sublinksRequests = [];
      const transactions = Object.keys(projectsTx).map(p => {
        const info = projectsTx[p];
        const { limit, current, rest, percent } = info;
        const { description } = projectsInfo.find(pro => pro.name === p);

        sublinksRequests.push({
          path: p,
          title: description
        })
        return {
          id: p,
          name: description,
          total: new Intl.NumberFormat('en-Us').format(limit),
          available: new Intl.NumberFormat('en-Us').format(rest),
          current: new Intl.NumberFormat('en-Us').format(current),
          average: `${percent || 0}%`,
        }
      })
      clearInterval(interval);
      setSublinks(sublinksRequests);
      setProjectTx(transactions);
    } catch (error) {

      setSublinks([


      ]);

      setAllLoaded(false);
      console.error('ERROR GETTING INFO ', error)

    }
    clearInterval(interval);
    setLoadingTable(false);
  }

  const handleEdit = () => {
    setEditing(!editing);
  }

  const editPencil = (project) => {

    const editedProject = projectTx.find(p => p.id === project);
    setEditedProject({
      name: editedProject.name,
      id: editedProject.id,
      currentLimit: editedProject.total,
      currentConsumed: editedProject.current
    });

    handleEdit();
  }

  const projectRows = projectTx.map(p => (
    <Row key={p.id} className="border-bottom py-2">
      <Col>{p.name}</Col>
      <Col>{p.id}</Col>
      <Col className="text-center">{p.total}</Col>
      <Col className="text-center">{p.available}</Col>
      <Col className="text-center">{p.average}</Col>

      <Col className="text-right"><FontAwesomeIcon onClick={() => editPencil(p.id)} cursor="pointer" icon={faPencilAlt} size="1x" className="mr-1" /></Col>
    </Row>
  ))


  const loadingSpinner = <Row className="my-3"><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText = <Row>
    <Col className="mx-auto text-center">
      <span className="d-block">
        You have not yet registered any project in your account.
      </span>
      <span className="d-block">
        Press add to register and configure your projects.
      </span>
    </Col>
  </Row>;

  const whenClosing = () => {
    setAddingTx(false);
  }

  useEffect(() => {
    getInfo();
  }, []);

  const optionProjects = projects.map(value => <option key={value.id} value={value.name}>{value.name}</option>);

  return (
    <>
      <BrowserView>
        <BaseLayout sider={DashRouters} active={menuActive} sublinks={sublinks}>
          <Row>
            <Col md={9}>
              <h1>Requests this month</h1>
            </Col>
            {/*    <Col md={3}>
          <Button disabled={allLoaded} className="btn-add obex-btn btn-add-green btn-block px-4" onClick={handleAddingTx}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add More</Button>
  </Col>*/}
          </Row>
          <Row>
            <Col className="obex-table table-request">
              <Row className="table-thead py-2" style={{
                fontFamily: 'Regular Intro Bold',
                textAlign: 'start',
                backgroundColor: 'transparent',
                color: 'black',
                borderWidth: '1px',
                borderRadius: '15px',
                borderStyle: 'hidden'
              }}>
                <Col>Name</Col>
                <Col>Project ID</Col>
                <Col>Total Req</Col>
                <Col>Available Req</Col>
                <Col>Average Req</Col>
                <Col></Col>
              </Row>
              {!loadingTable && projectRows}
            </Col>
          </Row>
          {loadingTable && loadingSpinner}
          {(!loadingTable && projects.length <= 0) && noProjectsText}
          <Row>
            {/*     <Col md={3} className="my-3">
          <Button disabled={allLoaded} className="btn-add obex-btn btn-add-green btn-block px-4" onClick={handleAddingTx}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add More</Button>
</Col>*/}
          </Row>

          <Row>
            {/* SELECTO DE FECHAS */}
            <Col>
              <Form.Control type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
            </Col>
            <Col>
              <Form.Control type="date" value={to} onChange={(e) => setTo(e.target.value)} />
            </Col>
            <Col>
              {/* SELECTOR DE PROYECTO */}
              <Form.Control as="select" onChange={(e) => setProject(e.target.value)} custom defaultValue={currentProject}>
                {optionProjects}
              </Form.Control>
            </Col>
          </Row>


          {projects.length <= 0 &&
            <Row className="stats-grafic">
              <Col>
                <TransactionsMediaChart from={from} to={to} project={currentProject} />
              </Col>
            </Row>}
          <UpdateRateLimitModal
            project_name={editedProject.name}
            project_id={editedProject.id}
            shown={editing}
            currentLimit={editedProject.currentLimit}
            maxAllowedTx={globalTx.rest}
            currentConsumed={editedProject.currentConsumed}
            onCloseAction={handleEdit}
            onSuccessCreation={async () => {
              setEditing(false);
              await getInfo();
            }}
          />
          {addingTx && <AddTxSelModal shown={addingTx} onSuccess={whenClosing} onCloseAction={whenClosing} maxMonthLimit={maxTX} actualMonthLimit={actualTX} />}
        </BaseLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default DevTransactionsContent;