import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { register } from '../../serviceWorker';
import { Table, Spinner } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type ApiTestProps = {};

const ApiTestContent: FunctionComponent<ApiTestProps> = () => {
  const [loading, setLoading] = useState(false);
  const [apiProducts, setApiProducts] = useState([]);
  const [apiServices, setApiServices] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedParams, setSelectedParams] = useState({});
  const [testResults, setTestResults] = useState(null);

  const getApiProducts = async () => {
    try {
      const result = (await ObexRequestHandler.get('/api_products')).data;
      setApiProducts(result);
    } catch (error) {
      console.error('Error al obtener la lista de API Products:', error);
    }
  };

  const getApiServices = async (apiProduct) => {
    try {
      if (apiProduct == 'DynamicApi') {
        const result = (await ObexRequestHandler.get(`/api_services/${apiProduct}`)).data;
        setApiServices([...result]);
      } else {
        const result = (await ObexRequestHandler.get(`/api_services/${apiProduct}`)).data;
        // Agrega una opción adicional "Todos"
        const allServicesOption = { id: 'all', api_service: 'All' };
        setApiServices([allServicesOption, ...result]);
      }

    } catch (error) {
      console.error('Error al obtener la lista de API Services:', error);
    }
  };

  const runTests = async (product, service, params = null) => {
    try {

      // Obtener información de /api_services_info
      if (product == 'DynamicApi') {
        service = service.id_service;
      } else {
        service = service.api_service;
      }
      let servicesInfo;
      if (service == 'All') {
        servicesInfo = (await ObexRequestHandler.get(`/api_services_info?api_product_name=${product}`));

      } else {
        servicesInfo = (await ObexRequestHandler.get(`/api_services_info?api_product_name=${product}&api_service_name=${service}`));

      }
      // Log de la información obtenida
      //console.log('Información de /api_services_info:', servicesInfo.data);
      var payload_unittest = {
        api_product: servicesInfo.data.api_product,
        path: servicesInfo.data.path,
        file: servicesInfo.data.file,
      };

      if (service != 'All') {
        payload_unittest['api_service'] = servicesInfo.data.api_service;
      }

      if (params && Object.keys(params).length != 0) {
        payload_unittest['parameters'] = params;
      }
      // Executing test and storing the result
      setLoading(true);
      var test_result = (await ObexRequestHandler.post(`/api_unittest`, payload_unittest)).data;
      if (!test_result) {
        test_result =
          [{
            error: "Execution failure",
            message: "The test has not been executed correctly. Please try later or contact with support."
          }]
      }
      setTestResults(test_result);
      setLoading(false);

    } catch (error) {
      console.error('Error al ejecutar la prueba:', error);
    }
  };

  useEffect(() => {
    getApiProducts();
  }, []);

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setSelectedService(null); // Reiniciar la selección de servicios al cambiar el producto
    setSelectedParams({});
    setTestResults(null);
    getApiServices(product);
    //console.log('API seleccionada:', product);
  };

  const handleServiceSelect = (service) => {
    setSelectedService(service);
    setTestResults(null);
    //console.log('Servicio seleccionado:', service ? service.api_service : null);
  };
  const handleParamsSelect = (key, value) => {
    setSelectedParams(previous => ({ ...previous, [key]: value }));
  };

  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active="developers">
          <h3>API Products</h3>
          <select onChange={(e) => handleProductSelect(e.target.value)} value={selectedProduct}>
            <option value="">Seleccione un API Product</option>
            {apiProducts.map((product) => (
              <option key={product.id} value={product.api_product}>
                {product.api_product}
              </option>
            ))}
          </select>

          {selectedProduct && (
            <>
              <h3>API Services for {selectedProduct}</h3>
              <select
                onChange={(e) =>
                  handleServiceSelect(apiServices.find((s) => {
                    if (selectedProduct == 'DynamicApi') {
                      return s.id_service === e.target.value;
                    } else {
                      return s.api_service === e.target.value;
                    }
                  }))
                }
                value={selectedService ? (selectedProduct == 'DynamicApi' ? selectedService.api_service : selectedService.id_service) : ''}
              >
                <option value="">Seleccione un API Service</option>
                {apiServices.map((service) => {
                  if (selectedProduct == 'DynamicApi') {
                    return <option key={service.id} value={service.id_service}>
                      {service.id_service}
                    </option>
                  } else {
                    return <option key={service.id} value={service.api_service}>
                      {service.api_service}
                    </option>
                  }

                })}
              </select>
              {selectedService && selectedProduct == 'DynamicApi' &&

                selectedService.configuration_value.sequence.map(
                  (config_param) => {
                    if (config_param.type == 'addParam') {
                      return <> <br />
                        <label>
                          {config_param.properties.param}&nbsp;&nbsp;
                          <input
                            name={config_param.properties.param}
                            onChange={(e) => handleParamsSelect(config_param.properties.param, e.target.value)}
                          />
                        </label>

                      </>
                    }
                  }
                )}
              {selectedService && <button onClick={() => runTests(selectedProduct, selectedService, selectedParams)}>Run Tests</button>}
              <br /><br />
              {loading && <Spinner animation="border" style={{ marginLeft: '8px' }} />}
              {testResults &&
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th className='textBold'>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testResults.map(
                      (output) => (
                        <tr style={{ border: "1pt solid dimgrey" }}><pre>{JSON.stringify(output, undefined, 4)}</pre></tr>
                      )
                    )}

                  </tbody>
                </Table>
              }
            </>
          )}
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
};

export default ApiTestContent;
