import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import BaseLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Button, Spinner } from 'react-bootstrap';
import TransactionsChart from './GraphComponents/TransactionsChart';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import UpdateRateLimitModal from '../../components/Modals/updateRateLimitModal';
import AddTxSelModal from '../Modals/addTxModalSelect';
import { useHistory } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type TransactionsProps = {
};


const TransactionsContent: FunctionComponent<TransactionsProps> = props => {

  const history = useHistory();
  var puntose = '';
  var contadore = 0;
  const [loadingTable, setLoadingTable] = useState(false);
  const [projects, setProjects] = useState([]);
  const [sublinks, setSublinks] = useState([

    {
      "path": `#`,
      "title": "Loading Data ",
    }
  ]);

  const getInfo = async () => {
    try {
      setLoadingTable(true);

      const interval = setInterval(() => {
        if (contadore < 5) {
          puntose = puntose + "."
          contadore++;

        } else {
          contadore = 0;
          puntose = '.';
        }

        setSublinks([{ "path": `#`, "title": "Loading Data " + puntose }]);

      }, 100);

      const result = (await ObexRequestHandler.get('/projects?basic=true')).data || [];
      setProjects(result);
      const sublinksRequests = [];
      clearInterval(interval);
      result.filter(crea => crea.isCreating).map(p => (
        //const { description } = projects.find(pro => pro.name === p);
        sublinksRequests.push({
          "path": p.name,
          "title": p.description
        })

      ))
      setSublinks(sublinksRequests);
    } catch (error) {
      console.error('ERROR GETTING INFO ', error)
    }
    setLoadingTable(false);
  }

  const projectRows = projects.filter(crea => crea.isCreating).map(p => (
    <tr>
      <td>{p.description}</td>
      <td>{p.name}</td>
      <td className="text-left">
        <FontAwesomeIcon
          onClick={() => history.push(`/exchange/${p.name}`)}
          cursor="pointer"
          icon={faPencilAlt}
          size="1x"
          className="mr-1" />
      </td>
    </tr>
  ))


  const loadingSpinner = <Row><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText = <Row>
    <Col className="mx-auto text-center">
      <span className="d-block">
        You have not yet registered any project in your account.
      </span>
    </Col>
  </Row>;


  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <BrowserView>
        <BaseLayout sider={DashRouters} active={'exchange'} sublinks={sublinks}>
          <Row>
            <Col md={10}>
              <h1>Fx Exchange</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive>
                <thead style={{ fontFamily: 'Regular Intro Bold' }}>
                  <tr>
                    <th className='textBold'>Project Name</th>
                    <th className='textBold'>Project ID</th>
                    <th className='textBold'>Edit exchange</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingTable && projectRows}
                </tbody>
              </Table>
            </Col>
          </Row>
          {loadingTable && loadingSpinner}
          {(!loadingTable && projects.length <= 0) && noProjectsText}
        </BaseLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default TransactionsContent;