import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Form, Modal, Button, Spinner } from 'react-bootstrap';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { useParams, useHistory } from 'react-router-dom';
import ObexToast from '../basicComponents/ObexToasts';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FetchingSpinner from '../basicComponents/loaderModal';
import ObexAlert from '../basicComponents/ObexAlerts';
import ConfirmDeleteProjectModal from '../Modals/ConfirmDeleteProjectModal';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';


type ModalProps = {
  showModal: boolean,
  onCloseModal: () => void,
  project: string
}

const FormCurrency: FunctionComponent<ModalProps> = (props) => {

  const { showModal, onCloseModal, project } = props;
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [name, setName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [symbol, setSymbol] = useState('');
  const [prefix, setPrefix] = useState('');
  const [suffix, setSuffix] = useState('');
  const [decimals, setDecimals] = useState('');
  const [saleChange, setSaleChange] = useState('');
  const [relationChange, setRelationChange] = useState('');
  const [referenceChange, setReferenceChange] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleName = (e) => { setName(e.target.value); }
  const handleAbbreviation = (e) => { setAbbreviation(e.target.value); }
  const handleSymbol = (e) => { setSymbol(e.target.value); }
  const handlePrefix = (e) => { setPrefix(e.target.value); }
  const handleSufix = (e) => { setSuffix(e.target.value); }
  const handleDecimals = (e) => { setDecimals(e.target.value); }
  const handleSaleChange = (e) => { setSaleChange(e.target.value); }
  const handleRelationChange = (e) => { setRelationChange(e.target.value); }
  const handleReferenceChange = (e) => { setReferenceChange(e.target.value); }



  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    try {
      const payload = {
        name: name,
        abbreviation: abbreviation,
        symbol: symbol,
        prefix: prefix,
        suffix: suffix,
        currencyPrecision: decimals,
        saleChange: saleChange,
        format: `${prefix} %.${decimals}f ${suffix}`,
        relationChange: relationChange,
        actualChange: referenceChange,
      };
      const result = await ObexRequestHandler.post(`/projects/${project}/currencies`, payload);
      setSuccess('Currency created');
      setTimeout(() => {
        setSuccess('')
        setSubmitDisabled(true);
        onCloseModal();
      }, 2000);
    } catch (error) {
      console.error('ERROR CREATING CURRENCY ', error);
      setError(error);
    }
    setLoading(true);
  }

  return (
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={showModal}
      onHide={onCloseModal}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col md="12" className="addons-title">
              <span>Create currency</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
        <Row>
          <Col>
            <Form className="obex-form">
              <Row>
                <Col md="4">
                  <Form.Row className="mb-3">
                    <Form.Label column sm="12" className="obex-form-label">
                      Name
                    </Form.Label>
                    <Col md="12">
                      <Form.Control required type="text" value={name} placeholder="Name" onChange={handleName} />
                    </Col>
                  </Form.Row>
                </Col>
                <Col md="4">
                  <Form.Row className="mb-3">
                    <Form.Label column sm="12" className="obex-form-label">
                      Abbreviation
                    </Form.Label>
                    <Col md="12">
                      <Form.Control required type="text" value={abbreviation} placeholder="Abbreviation" onChange={handleAbbreviation} />
                    </Col>
                  </Form.Row>
                </Col>
                <Col md="4">
                  <Form.Row className="mb-3">
                    <Form.Label column sm="12" className="obex-form-label">
                      Symbol
                    </Form.Label>
                    <Col md="12">
                      <Form.Control required type="text" value={symbol} placeholder="Symbol" onChange={handleSymbol} />
                    </Col>
                  </Form.Row>
                </Col>
                <Col md="4">
                  <Form.Row className="mb-3">
                    <Form.Label column sm="12" className="obex-form-label">
                      Prefix
                    </Form.Label>
                    <Col md="12">
                      <Form.Control type="text" value={prefix} placeholder="Prefix" onChange={handlePrefix} />
                    </Col>
                  </Form.Row>
                </Col>
                <Col md="4">
                  <Form.Row className="mb-3">
                    <Form.Label column sm="12" className="obex-form-label">
                      Suffix
                    </Form.Label>
                    <Col md="12">
                      <Form.Control type="text" value={suffix} placeholder="Suffix" onChange={handleSufix} />
                    </Col>
                  </Form.Row>
                </Col>
                <Col md="4">
                  <Form.Row className="mb-3">
                    <Form.Label column sm="12" className="obex-form-label">
                      Decimals
                    </Form.Label>
                    <Col md="12">
                      <Form.Control required type="text" value={decimals} placeholder="Decimals" onChange={handleDecimals} />
                    </Col>
                  </Form.Row>
                </Col>
                <Col md="4">
                  <Form.Row className="mb-3">
                    <Form.Label column sm="12" className="obex-form-label">
                      Sale Change
                    </Form.Label>
                    <Col md="12">
                      <Form.Control required type="text" value={saleChange} placeholder="Sale change" onChange={handleSaleChange} />
                    </Col>
                  </Form.Row>
                </Col>
                <Col md="4">
                  <Form.Row className="mb-3">
                    <Form.Label column sm="12" className="obex-form-label">
                      Reference Change
                    </Form.Label>
                    <Col md="12">
                      <Form.Control required type="text" value={referenceChange} placeholder="Reference Change" onChange={handleReferenceChange} />
                    </Col>
                  </Form.Row>
                </Col>
                <Col md="4">
                  <Form.Row className="mb-3">
                    <Form.Label column sm="12" className="obex-form-label">
                      Relation Change
                    </Form.Label>
                    <Col md="12">
                      <Form.Control required type="text" value={relationChange} placeholder="Relation Change" onChange={handleRelationChange} />
                    </Col>
                  </Form.Row>
                </Col>
              </Row>
              <Form.Row>
                <Col>
                  <ButtonSubmit
                    loading={loading}
                    success={success}
                    error={error}
                    handleSubmit={handleSubmit}
                    submitDisabled={submitDisabled}
                    textButton={'Create'} />
                </Col>
              </Form.Row>
              <Row>
                <Col>
                  <ObexAlert type='error' msg={error} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}


const ProjectCurrencies: FunctionComponent<{}> = () => {

  const { project } = useParams();
  let puntose = '';
  let contadore = 0;
  const [currencies, setCurrencies] = useState([]);
  const [editingRow, setEditingRow] = useState('');
  const [editingKey, setEditingKey] = useState('');
  const [editingValue, setEditingValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [projectDescripton, setDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [sublinks, setSublinks] = useState([

    {
      path: '#',
      title: 'Loading Data ',
    }
  ]);

  const getCurrencies = async () => {
    try {
      setLoading(true);

      const interval = setInterval(() => {
        if (contadore < 5) {
          puntose = puntose + '.'
          contadore++;

        } else {
          contadore = 0;
          puntose = '.';
        }
        setSublinks([{ path: '#', title: 'Loading Data ' + puntose }]);

      }, 100);

      const processProject = (p) => {
        sublinksRequests.push({
          path: p.name,
          title: p.description
        })
        if (p.name == project) setDescription(p.description);
      }
      const result = (await ObexRequestHandler.get(`/projects/${project}/currencies`, { all: true })).data || {};
      const sortCurrencies = result.sort(sortByCurrencyName).map(c => {
        const { cambio_venta, cambio_compra, cambio_referencia } = c;
        c.cambio_venta = parseFloat(cambio_venta).toFixed(2);
        c.cambio_compra = parseFloat(cambio_compra).toFixed(2);
        c.cambio_referencia = parseFloat(cambio_referencia).toFixed(2);
        return c;
      })
      setCurrencies(sortCurrencies)
      const projects = (await ObexRequestHandler.get('/projects')).data || [];
      const sublinksRequests = [];
      projects.map(p => (
        //const { description } = projects.find(pro => pro.name === p);
        processProject(p)

      ))
      clearInterval(interval);
      setSublinks(sublinksRequests);

    } catch (error) {
      console.error('ERROR FETCHING CURRENCIES ', error);
    }
    setLoading(false);

  }

  const sortByCurrencyName = (a, b) => {
    if (a.nombre > b.nombre) return 1;
    if (a.nombre < b.nombre) return -1;
    return 0;
  }

  const startSaving = () => {
    setError('');
    setSaving(true);
  }

  const save = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      startSaving();
      const payload = {
        currencyId: editingRow,
        key: editingKey,
        value: editingValue
      }
      const result = await ObexRequestHandler.put(`/projects/${project}/currencies`, payload);
      const { success, data, message } = result;
      if (!success) throw { message };
      await getCurrencies();
    } catch (error) {
      console.error('Error saving currencies ', error);
      setError(error.message || error);
    }
    setSaving(false);
    setEditingRow('');
    setEditingKey('');
  }

  const enableEdition = (id, key, value) => {
    setEditingKey(key);
    setEditingRow(id);
    setEditingValue(value);
  }

  const handleValue = (e) => {
    const { value } = e.target;
    setEditingValue(value)
  }

  const whenClosing = () => {
    setShowModal(false);
    getCurrencies();
  }

  const renderCurrencies = () => {
    const currenciesRows = [];
    for (let i = 0; i < currencies.length; i++) {
      const currency = currencies[i];
      const { cambio_venta, datos_divisa, cambio_compra, cambio_referencia, nombre } = currency;
      const { abreviatura, simbolo, id, sufijo, prefijo, decimales } = datos_divisa
      const editingThis = editingRow === id;
      currenciesRows.push(
        <Row id={id} className="border-bottom">

          <Col className="mt-2">
            <span>{nombre}</span>
          </Col>
          <Col className="mt-2">
            <span>{abreviatura}</span>
          </Col>
          <Col className="mt-2">
            <span>{simbolo}</span>
          </Col>
          <Col className="mt-2">
            <span>{prefijo}</span>
          </Col>
          <Col className="mt-2">
            <span>{sufijo}</span>
          </Col>
          <Col className="mt-2">
            <span>{decimales}</span>
          </Col>

          {/* CAMBIO VENTA */}
          <Col className="mt-2">
            {(editingKey === 'cambio_venta' && editingThis)
              ? <Form.Control
                type="custom"
                placeholder="Sale change"
                onChange={handleValue}
                value={editingValue}
              />
              : <span>
                {cambio_venta + '  '}
                <FontAwesomeIcon
                  cursor="pointer"
                  icon={faPencilAlt}
                  onClick={() => enableEdition(id, 'cambio_venta', cambio_venta)}
                />
              </span>
            }
          </Col>
          {/* CAMBIO COMPRA */}
          <Col className="mt-2">
            {(editingKey === 'cambio_compra' && editingThis)
              ? <Form.Control
                type="custom"
                placeholder="Buy change"
                onChange={handleValue}
                value={editingValue}
              />
              : <span>
                {cambio_compra + '  '}
                <FontAwesomeIcon
                  cursor="pointer"
                  icon={faPencilAlt}
                  onClick={() => enableEdition(id, 'cambio_compra', cambio_compra)}
                />
              </span>
            }
          </Col>
          {/* CAMBIO REFERENCIA */}
          <Col className="mt-2">
            {(editingKey === 'cambio_referencia' && editingThis)
              ? <Form.Control
                type="custom"
                placeholder="Reference change"
                onChange={handleValue}
                value={editingValue}
              />
              : <span>
                {cambio_referencia + '  '}
                <FontAwesomeIcon
                  cursor="pointer"
                  icon={faPencilAlt}
                  onClick={() => enableEdition(id, 'cambio_referencia', cambio_referencia)}
                />
              </span>
            }
          </Col>
          {/*BOTONES SAVE AND CANCEL*/}
          {editingThis &&
            <Col className="mt-2">
              <div style={{ display: 'inline-flex' }}>
                <Button
                  size="sm"
                  style={{ width: '65px' }}
                  onClick={save}
                  type="submit"
                  className="obex-btn btn-green"
                  disabled={saving}
                >
                  {saving && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  Save
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  className="obex-btn btn-orange"
                  style={{ width: '65px' }}
                  onClick={() => {
                    setEditingKey('');
                    setEditingRow('');
                    setEditingValue('');
                  }}>
                  Cancel
                </Button>
              </div>
            </Col>
          }
        </Row>
      )
    }
    return currenciesRows;
  }

  useEffect(() => {
    getCurrencies();
  }, [])

  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'exchange'} sublinks={sublinks}>
          <Row>
            <Col md="11" className="mx-auto">
              <Row className="mb-4">
                <Col md={10}>
                  <h1>Project Exchanges {projectDescripton}</h1>
                </Col>
              </Row>
              <Row className="pb-2 border-bottom" style={{
                fontFamily: 'Regular Intro Bold',
                textAlign: 'start',
                backgroundColor: 'transparent',
                color: 'black',
                borderWidth: '1px',
                borderRadius: '15px',
                borderStyle: 'hidden'
              }}>
                <Col>
                  <span>Name</span>
                </Col>
                <Col>
                  <span>Abbreviation</span>
                </Col>
                <Col>
                  <span>Symbol</span>
                </Col>
                <Col>
                  <span>Prefix</span>
                </Col>
                <Col>
                  <span>Suffix</span>
                </Col>
                <Col>
                  <span>Decimals</span>
                </Col>
                <Col>
                  <span>Sale change</span>
                </Col>
                <Col>
                  <span>Purchase change</span>
                </Col>
                <Col>
                  <span>Reference change</span>
                </Col>
                {(editingKey.length > 0) &&
                  <Col>
                    <span>Edit</span>
                  </Col>
                }

                <ConfirmDeleteProjectModal
                  handleSubmit={null}
                  showModal={null}
                  onCloseModal={null}
                  loading={null}
                  error={null}
                  success={null}
                  name_to_check={''}
                  title_to_show={''}
                />

              </Row>
              {loading
                ? <Row style={{ marginTop: '15px' }}>
                  <Col>
                    <FetchingSpinner />
                  </Col>
                </Row>
                : <Fragment>
                  <FormCurrency project={project} showModal={showModal} onCloseModal={whenClosing} />
                  <Form>
                    {renderCurrencies()}
                  </Form>
                </Fragment>
              }
            </Col>
          </Row>
          <ObexToast type="error" msg={error} clean={() => setError('')} />
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default ProjectCurrencies;