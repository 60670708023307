import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DevDashboardLayout';
import DashRouters from '../../configs/routers/dev-dashboard-routers.json';
import { Row, Col, Alert, Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faExternalLinkSquareAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';


type IncomeProps = {
};


const DownloadDownloadContent: FunctionComponent<IncomeProps> = ({ }) => {
  const history = useHistory();
  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'github'} sublinks={[]}>
          <Row>
            <Col md="9" className="mx-auto">
              <Row>
                <Col>
                  <h1 className="mb-3 mt-5">101OBeX Cli Download</h1>
                </Col>
              </Row>
              <Row className="income">
                <Col>
                  <Card className="mb-3 obex-card">
                    <Card.Body className="p-0 income-detail-card">
                      <Card.Text className="px-3 pt-2 mb-2">

                        <Row>
                          <Col md="8">
                            <a href="https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease-staging/101obexcli-macosx.zip">Mac OSX</a>
                          </Col>
                          <Col md="4" className="text-right">
                            <a href="https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease-staging/101obexcli-macosx.zip"> <FontAwesomeIcon cursor="pointer" icon={faDownload} size="1x" /></a>
                          </Col>
                        </Row>
                      </Card.Text>
                      <Card.Text className="px-3 pt-2 mb-2">
                        <Row>
                          <Col md="8">
                            <a href="https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease/101obexcli.-.linux.zip">Linux</a>
                          </Col>
                          <Col md="4" className="text-right">
                            <a href="https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease/101obexcli.-.linux.zip"> <FontAwesomeIcon cursor="pointer" icon={faDownload} size="1x" /></a>
                          </Col>
                        </Row>

                      </Card.Text>


                      <Card.Text className="px-3 pt-2 mb-2">
                        <Row>
                          <Col md="8">
                            <a href="https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease/101obexcli-win32.zip">Windows</a>
                          </Col>
                          <Col md="4" className="text-right">
                            <a href="https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease/101obexcli-win32.zip"> <FontAwesomeIcon cursor="pointer" icon={faDownload} size="1x" /></a>
                          </Col>
                        </Row>

                      </Card.Text>



                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {/*
          <Row>
            <Col>
              <h1 className="my-3">Visual Studio Code </h1>
            </Col>
          </Row>
          <Row className="income">
            <Col>
              <Card className="mb-3 obex-card">
                <Card.Body className="p-0 income-detail-card">
                  <Card.Text className="px-3 pt-2 mb-2">
                    <Row>
                      <Col md="8">
                        <a onClick={()=>{history.push(`/developers_downloads/vs_code_extension`)}}>VS Code Extension Repository</a>
                      </Col>
                      <Col md="4" className="text-right">
                      <span> <FontAwesomeIcon cursor="pointer" icon={faExternalLinkSquareAlt} size="1x" onClick={()=>{history.push(`/developers/apikeys`)}}/></span>
                      </Col>
                    </Row>
                  </Card.Text>
                  <Card.Text className="px-3 pt-2 mb-2">
                    <Row>
                      <Col md="8">
                        <a onClick={()=>{history.push(`/developers_downloads/vs_code_completion`)}}>VS Code Completion Repository</a>
                      </Col>
                      <Col md="4" className="text-right">
                      <span> <FontAwesomeIcon cursor="pointer" icon={faExternalLinkSquareAlt} size="1x" onClick={()=>{history.push(`/developers/logs`)}}/></span>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>

              </Card>
            </Col>
          </Row>
*/}
              {/*
          <Row>
            <Col>
              <h1 className="my-3">SandBox</h1>
            </Col>
          </Row>
          <Row className="income">
            <Col>
              <Card className="income-detail-card mb-3 obex-card">
                <Card.Body className="p-0">
                  <Card.Text className="px-3 pt-2 mb-2">
                    <Row>
                      <Col md="8">
                        <a onClick={()=>{history.push(`/developers/sandbox`)}}>
                          Here you can test all the API Products.
                        </a>
                      </Col>
                      <Col md="4" className="text-right">
                      <span> <FontAwesomeIcon cursor="pointer" icon={faExternalLinkSquareAlt} size="1x" onClick={()=>{history.push(`/developers/sandbox`)}}/></span>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>

              </Card>
            </Col>
  </Row>*/}
            </Col>
          </Row>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default DownloadDownloadContent;