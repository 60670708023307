import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Card, Button, Container, Spinner, Modal } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin, parseToNumberFormat } from '../../services/AmountService';
import VerifyCancelSubModal from '../Modals/VerifyCancelSubModal';
import PaymentConfirmationModal from '../Modals/paymentConfirmation';
import ConfirmDeleteProjectModal from '../Modals/ConfirmDeleteProjectModal';
import PaymentConfirmation from '../Modals/paymentConfirmation';
import PaymentSuccess from '../Modals/paymentSuccess';
import SessionService from '../../services/SessionsService';
import { useHistory } from 'react-router-dom';
import AddCreditCard from '../Modals/addCreditCard';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';


type subProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  monthRateLimit: number,
  maxMonthRateLimit: number,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean
}


const SubscriptionContent: FunctionComponent<subProps> = (props: subProps) => {

  const { name, price, purchased, expires,
    monthRateLimit, maxMonthRateLimit,
    annualPayment, active, renovate } = props;

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal(!showModal);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteSubscription = async () => {
    try {
      setError('');
      setSuccess('');
      setLoading(true);
      const result = await ObexRequestHandler.delete('/subscription');
      const { success } = result;
      if (success) {
        setSuccess(success);
        setLoading(false);
        setTimeout(function () {
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      } else {
        setError(error)
      }
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
    history.push('/plan_subcription')
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteSubscription();
  }

  return (
    <Row className="mb-3">
      <Col md="12">
        <Card className="product-detail-card mb-3 obex-card">
          <Card.Body className="p-0">
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>Type to subscription: <span className="text-bold"> {name}</span></span>
                </Col>
                <Col md="4" className="text-right">
                  <span className="text-bold">{parseToCoin(price)}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Purchased</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{purchased.toString().split(' ')[0]}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Expires</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{expires.toString().split(' ')[0]}</span>
                </Col>
              </Row>
            </Card.Text>
            {/*} <div className="border-top my-2 mx-3"></div>*/}
            <Card.Text className="px-3 pt-3">

              {/*}
              <Row>
                <Col md="8">
                  <span>Monthly rate limit</span>
                </Col>
                <Col md="4" className="text-right">
                <span><span className="text-bold">{parseToNumberFormat(monthRateLimit)}</span> Requests</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>Max Monthly rate limit</span>
                </Col>
                <Col md="4" className="text-right">
                <span><span className="text-bold">{parseToNumberFormat(maxMonthRateLimit)}</span> Requests</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 py-3">
              <Row>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Annual Payment</span></Col>
                    <Col md="12" className="text-center"><span>{annualPayment ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Active</span></Col>
                    <Col md="12" className="text-center"><span>{active ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Renovate</span></Col>
                    <Col md="12" className="text-center"><span>{renovate ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
              </Row>


                */}

            </Card.Text>


          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Row>

        </Row>
      </Col>
      <VerifyCancelSubModal
        showModal={showModal}
        onCloseModal={handleModal}
        handleSubmit={handleSubmit}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name}
      />
    </Row>
  )
}


type extraProjectProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean,
  id: number,
  pendindPayments: boolean,
}

const SubExtraProject: FunctionComponent<extraProjectProps> = (props: extraProjectProps) => {

  const { name, price, purchased, expires, annualPayment, active, renovate,
    id, pendindPayments } = props;
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal)
    const history = useHistory();
    if (!showModal) history.push('/plan_subcription');
  };
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);


  const deleteExtraProjects = async (id) => {
    try {
      setError('');
      setSuccess('');
      setLoading(true);
      const result = await ObexRequestHandler.delete(`/extraprojects/${id}`);
      const { success } = result;
      if (success) {
        setSuccess(success);
        setLoading(false);
        setTimeout(function () {
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      } else {
        setError(error)
      }
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    deleteExtraProjects(id);
  }

  return (
    <Row className="mb-3">
      <Col md="12">
        <Card className="product-detail-card mb-3 obex-card">
          <Card.Body className="p-0">
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>{name}</span>
                </Col>
                <Col md="4" className="text-right">
                  <span className="text-bold">{parseToCoin(price)}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Purchased</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{purchased}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Expired</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{expires}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 py-3">
              <Row>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Annual Payment</span></Col>
                    <Col md="12" className="text-center"><span>{annualPayment ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Active</span></Col>
                    <Col md="12" className="text-center"><span>{active ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Renovate</span></Col>
                    <Col md="12" className="text-center"><span>{renovate ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="4" className="my-auto">
                      <span>Cancel Subscription</span>
                    </Col>
                    <Col md="8" className="">
                      <Row>
                        <Col md="6" className="ml-auto">
                          <Button className="btn-block obex-btn btn-orange" onClick={handleModal} disabled={pendindPayments}>Cancel Subscription</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <VerifyCancelSubModal
        showModal={showModal}
        onCloseModal={handleModal}
        handleSubmit={(e) => handleSubmit(e, id)}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name} />
    </Row>
  )
}

//

type extraDevelopersProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean,
  id: number,
  pendindPayments: boolean
}

const SubExtraDevelopers: FunctionComponent<extraDevelopersProps> = (props: extraDevelopersProps) => {

  const { name, price, purchased, expires, annualPayment, active, renovate,
    id, pendindPayments } = props;

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal(!showModal);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteExtraRequests = async (id) => {
    try {
      setError('');
      setLoading(true);
      const result = await ObexRequestHandler.delete(`/extrarequests/${id}`);
      const { success } = result;
      if (success) {
        setSuccess(success);
        setLoading(false);
        setTimeout(function () {
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      }
      setError(error)
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(e);
    console.log(id)
    //deleteExtraRequests(id);
  }

  return (
    <Row className="mb-3">
      <Col md="12">
        <Card className="product-detail-card mb-3 obex-card">
          <Card.Body className="p-0">
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>{name}</span>
                </Col>
                <Col md="4" className="text-right">
                  <span className="text-bold">{parseToCoin(price)}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Purchased</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{purchased}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Expired</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{expires}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 py-3">
              <Row>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Annual Payment</span></Col>
                    <Col md="12" className="text-center"><span>{annualPayment ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Active</span></Col>
                    <Col md="12" className="text-center"><span>{active ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Renovate</span></Col>
                    <Col md="12" className="text-center"><span>{renovate ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="4" className="my-auto">
                      <span>Cancel Subscription</span>
                    </Col>
                    <Col md="8" className="">
                      <Row>
                        <Col md="6" className="ml-auto">
                          <Button className="btn-block obex-btn btn-orange"
                            onClick={handleModal}
                            disabled={pendindPayments}>Cancel Subscription</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <VerifyCancelSubModal
        showModal={showModal}
        onCloseModal={handleModal}
        handleSubmit={(e) => handleSubmit(e, id)}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name}
      />
    </Row>
  )
}

//

type extraRequestProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean,
  id: number,
  pendindPayments: boolean
}

const SubExtraRequest: FunctionComponent<extraRequestProps> = (props: extraRequestProps) => {

  const { name, price, purchased, expires, annualPayment, active, renovate,
    id, pendindPayments } = props;

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal(!showModal);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteExtraRequests = async (id) => {
    try {
      setError('');
      setLoading(true);
      const result = await ObexRequestHandler.delete(`/extrarequests/${id}`);
      const { success } = result;
      if (success) {
        setSuccess(success);
        setLoading(false);
        setTimeout(function () {
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      }
      setError(error)
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(e);
    console.log(id)
    deleteExtraRequests(id);
  }

  return (
    <Row className="mb-3">
      <Col md="12">
        <Card className="product-detail-card mb-3 obex-card">
          <Card.Body className="p-0">
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>{name}</span>
                </Col>
                <Col md="4" className="text-right">
                  <span className="text-bold">{parseToCoin(price)}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Purchased</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{purchased}</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="6">
                  <span>Expired</span>
                </Col>
                <Col md="6" className="text-right">
                  <span>{expires}</span>
                </Col>
              </Row>
            </Card.Text>

            {/*}
            <div className="border-top my-2 mx-3"></div>
            
            */}

            <Card.Text className="px-3 py-3">
              <Row>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Annual Payment</span></Col>
                    <Col md="12" className="text-center"><span>{annualPayment ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Active</span></Col>
                    <Col md="12" className="text-center"><span>{active ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
                <Col md="4" className="border-right">
                  <Row>
                    <Col md="12" className="text-center"><span>Renovate</span></Col>
                    <Col md="12" className="text-center"><span>{renovate ? 'True' : 'False'}</span></Col>
                  </Row>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Card.Text>
                  <Row>
                    <Col md="4" className="my-auto">
                      <span>Cancel Subscription</span>
                    </Col>
                    <Col md="8" className="">
                      <Row>
                        <Col md="6" className="ml-auto">
                          <Button className="btn-block obex-btn btn-orange"
                            onClick={handleModal}
                            disabled={pendindPayments}>Cancel Subscription</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <VerifyCancelSubModal
        showModal={showModal}
        onCloseModal={handleModal}
        handleSubmit={(e) => handleSubmit(e, id)}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name}
      />
    </Row>
  )
}


type planSubcriptionProps = {
  sider: any
}

const PlanSubscriptionContent: FunctionComponent<planSubcriptionProps> = (props: planSubcriptionProps) => {

  const [subContent, setSubContent] = useState(<div></div>);
  const [projectsPlans, setProjectsPlans] = useState([]);
  const [requestsPlans, setRequestPlans] = useState([]);
  const [stillLoaded, setStillLoaded] = useState(true);
  const [ExtraDevsSlots, setextraDevsSlots] = useState([]);
  const [PendingInvoice, setPendingInvoice] = useState({ invoice: [], total: 0 });
  const [Pending, setPending] = useState(false);
  const [shoWDel, setShowDel] = useState(false)

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentEnable, setPaymentMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [pendindPayments, setpendingPayments] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cards_, setCards] = useState([]);
  const [card_id_stripe, setCardID] = useState('');
  const [newadded_card, setAddedCard] = useState('');

  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if (cards_.some(e => e.id == c.id)) {
            //console.log('exists');
          } else {
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);

    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }
  const handleModal = () => {
    setShowModal(!showModal);
    //onCloseAction();
    getCards();

  };


  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value == 'addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();

  }

  // const [priceSuscription, SetPrecio] = useState(0);

  const whenClosing = () => {
    setShowDel(false);
    //getData();
  }
  const history = useHistory();


  const getCurrentSubcription = async () => {


    const resulta = await ObexRequestHandler.get('/check_renovated_suscription', {}, true);
    setPending(resulta.data.renovated);
    /*
    const { data } = resulta;

      const { renovated } = data;
      */


    //wast.style.display = 'block';


    /*
    if (!renovated) {
      wast.style.display = 'block';
      */
    const resultPending = (await ObexRequestHandler.get('/get_pending_invoice', {}, true)).data || {};

    console.log('PENDING');
    console.log(resultPending);
    setPendingInvoice(resultPending);
    setpendingPayments(!resultPending);
    /*    }
  */
    const result = (await ObexRequestHandler.get('/purchased_services', {}, true)).data || {};
    // console.log(result);
    if (result) {
      const { subscription, extraProjectsPlans, extraRequestsPlans, extraDevsSlots } = result;
      let suscriptionPrice = 0;

      if (!subscription.annual_payment) {
        /*SetPrecio(subscription.monthly_price);*/suscriptionPrice = subscription.monthly_price;
      } else {
        /*SetPrecio(subscription.anual_price * 10);*/suscriptionPrice = (subscription.annual_price * 10);
      }

      setSubContent(<SubscriptionContent
        name={subscription.name}
        price={suscriptionPrice}
        purchased={subscription.purchased}
        expires={subscription.expires}
        monthRateLimit={subscription.month_rate_limit}
        maxMonthRateLimit={subscription.max_month_rate_limit}
        annualPayment={subscription.annual_payment}
        active={subscription.active}
        renovate={subscription.renovate} />);
      setProjectsPlans(extraProjectsPlans);
      setRequestPlans(extraRequestsPlans);
      setextraDevsSlots(extraDevsSlots);
    }
    setStillLoaded(false);
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const makePayment = async () => {
    try {
      startPayment();
      const payload = { Invoice: PendingInvoice, card_stripe_id: card_id_stripe };
      const result = await ObexRequestHandler.post('/pay_pending_invoice', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentMode(false);

      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getCurrentSubcription();
    getCards();
  }, [])

  const OnSuccesAction = () => {
    // SessionService.renewSession('/plan_subcription');

    history.push('/cloud_dashboard');

    //onCloseAction();
  }

  return (
    <>
      <BrowserView>
        <DashLayout sider={props.sider} active={'plan_subcription'}>
          <div style={{ marginLeft: '-15px' }}>
            {stillLoaded ?
              <Container className="vh-100 d-flex flex-column vw-100">
                <Row className="h-100" >
                  <Col md="1" className="mx-auto d-flex align-items-center">
                    <Spinner animation="border" />
                  </Col>
                </Row>
              </Container>
              :

              <Row>
                <Col className="mx-auto">
                  <Row style={{ marginBottom: '2rem' }}>
                    <Col>
                      <h2 className='textBold'>Subscription</h2>
                    </Col>
                  </Row>
                  {subContent}
                  <Row>
                    <Col>
                      {projectsPlans.length > 0 &&
                        <h1 className="mb-3">Extra Projects Plan</h1>}
                    </Col>
                  </Row>
                  {projectsPlans.map(plan =>
                    <SubExtraProject
                      key={'SubExtraProject' + plan.id + Math.random()}
                      name={plan.title}
                      price={plan.monthly_price}
                      purchased={plan.purchased}
                      expires={plan.expires}
                      annualPayment={plan.annual_payment}
                      active={plan.active}
                      renovate={plan.renovate}
                      id={plan.purchase_id}
                      pendindPayments={pendindPayments}
                    />
                  )}
                  <Row>
                    <Col>
                      {requestsPlans.length > 0 &&
                        <h1 className="mb-3">Extra Requests Plans</h1>}
                    </Col>
                  </Row>
                  {requestsPlans.map(plan =>
                    <SubExtraRequest
                      key={'SubExtraRequests' + plan.id + Math.random()}
                      name={plan.title}
                      price={plan.monthly_price}
                      purchased={plan.purchased}
                      expires={plan.expires}
                      annualPayment={plan.annual_payment}
                      active={plan.active}
                      renovate={plan.renovate}
                      id={plan.purchase_id}
                      pendindPayments={pendindPayments}
                    />
                  )}



                  <Row>
                    <Col>
                      {ExtraDevsSlots.length > 0 &&
                        <h1 className="mb-3">Extra Developers Slot Plans</h1>}
                    </Col>
                  </Row>
                  {ExtraDevsSlots.map(plan =>
                    <SubExtraDevelopers
                      key={'SubExtraRequests' + plan.id + Math.random()}
                      name={plan.title}
                      price={plan.monthly_price}
                      purchased={plan.purchased}
                      expires={plan.expires}
                      annualPayment={plan.annual_payment}
                      active={plan.active}
                      renovate={plan.renovate}
                      id={plan.purchase_id}
                      pendindPayments={pendindPayments}
                    />
                  )}

                  {!Pending &&
                    <><Row id='nopayment2' style={{ backgroundColor: 'linen', marginBottom: '40px' }}>
                      <Col id='nopayment'>
                        {PendingInvoice.invoice.map(plan => {
                          return (
                            <Row style={{ marginTop: '10px' }}>
                              <div style={{ paddingLeft: '20px', fontSize: '20px', width: '50%' }}>{plan.detail}</div><div style={{ textAlign: 'end', width: '50%', paddingRight: '20px', fontSize: '20px' }}>{parseToCoin(plan.amount)}</div>
                            </Row>
                          );
                        })}
                        <Row style={{ marginTop: '20px', marginBottom: '10px' }}>
                          <div style={{ paddingLeft: '20px', fontSize: '20px', width: '50%' }}>Total</div><div style={{ textAlign: 'end', width: '50%', paddingRight: '20px', fontSize: '20px' }}>{parseToCoin(PendingInvoice.total)}</div>
                        </Row>
                        <Button style={{ marginBottom: '20px' }} className="btn-block obex-btn btn-orange" onClick={() => { console.log('ok'); setShowDel(true); }} disabled={false}>Make the Payment</Button>
                      </Col>
                    </Row>


                    </>
                  }


                  <Modal dialogClassName="addons-dialog w-75 obex-dialog"
                    show={shoWDel}
                    onHide={whenClosing}
                    animation={false}>
                    <Modal.Header closeButton className="border-0 pb-0">
                      <Modal.Title className="text-center w-100">
                        <Row>
                          <Col className="addons-title">
                            <span >Pay your outdated suscriptions</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="10" className="mx-auto text-center modal-subtitle">

                            {paymentEnable ?
                              <span className="text-center d-block">Review your payment and proceed.</span> :
                              <span className="text-center d-block">Choose your package and continue to checkout.</span>
                            }
                          </Col>
                        </Row>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {paymentEnable ?
                        <PaymentConfirmation
                          actionSubmit={makePayment}
                          handleGoBack={() => { setShowDel(false); }}
                          nameServiceSelected={`Paying ${PendingInvoice.total} for suscription.`}
                          handleAnnualPayment={null}
                          amountMonth={PendingInvoice.total}
                          amountYear={0}
                          processingPayment={loading}
                          success={success}
                          error={error}
                          spanish={false}
                        />
                        : (paymentSuccess ? <PaymentSuccess blue={true} onCloseAction={OnSuccesAction} paymentDescription={''} paymentType={''} /> : <></>)
                      }
                    </Modal.Body>
                  </Modal>
                  {showModal && <AddCreditCard show={showModal} handleClose={handleModal} isGreen={false} spanish={false} />}

                </Col>
              </Row>

            }
          </div>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>

  )
}

export default PlanSubscriptionContent;