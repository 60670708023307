import MobileLayout from "../functionalComponents/Mobile_Layout/MobileLayout";
import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Alert, Card, Button, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faDesktop, faMobileAlt } from "@fortawesome/free-solid-svg-icons";


type privateInfProps = {
};

const Disclaimer:FunctionComponent<privateInfProps> = ({ }) => {


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);


  // useEffect(() => {/**/})

  return(
    <MobileLayout>
      <Row className="pt-4 pl-4 pr-4 mr-0 ml-0" style={{marginRight:'9rem'}}>
        <Col md="9" className="mx-auto">
          <Row>
            <Col className="d-flex flex-column justify-content-center">
            <h1 className="mb-3" style={{width : 'fit-content'}}>Disclaimer</h1>
            <p>
                This webpage is not available on phones. Please check it out in other devices.
            </p>
          
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon icon={faCheck} size="3x" style={{color: "#68bb5d",position:"absolute"}} />
            <FontAwesomeIcon icon={faDesktop} size="9x"  />
            </Col>
            <Col className="d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon icon={faBan} size="10x" color="Red" style={{position:"absolute"}}/>
            <FontAwesomeIcon icon={faMobileAlt} size="6x"  />
            </Col>
          </Row>
        </Col>
      </Row>
    </MobileLayout>
  )
}

export default Disclaimer;