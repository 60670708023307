import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type IncomeProps = {
};


const IncomeContent: FunctionComponent<IncomeProps> = ({ }) => {
  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'income'} sublinks={[]}>
          <Row>
            <Col md="9" className="mx-auto">
              <Row>
                <Col>
                  <h1 className="mb-3">Incoming Balance to Wallet</h1>
                </Col>
              </Row>
              <Row className="income">
                <Col>
                  <Card className="mb-3 obex-card">
                    <Card.Body className="p-0 income-detail-card">
                      <Card.Text className="px-3 pt-2 mb-2">
                        <Row>
                          <Col md="8">
                            <span>On the way to your bank</span>
                          </Col>
                          <Col md="4" className="text-right">
                            <span><span className="text-bold">0.00</span> EUR</span>
                          </Col>
                        </Row>
                      </Card.Text>
                      <div className="border-top mx-3"></div>
                      <Card.Text className="px-3 pt-2 mb-2">
                        <Row>
                          <Col md="8">
                            <span>Estimated future payouts</span>
                          </Col>
                          <Col md="4" className="text-right">
                            <span><span className="text-bold">0.00</span> EUR</span>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="income-footer">
                      <Card.Text>
                        <Row>
                          <Col md="8">
                            <span className="text-bold">Total Price</span>
                          </Col>
                          <Col md="4" className="text-right">
                            <span><span className="text-bold">0.00</span> EUR</span>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 className="my-3">On the way to your bank</h1>
                </Col>
              </Row>
              <Row className="income">
                <Col>
                  <Card className="mb-3 obex-card">
                    <Card.Body className="p-0 income-detail-card">
                      <Card.Text className="px-3 pt-2 mb-2">
                        <Row>
                          <Col md="12">
                            <span>These funds should arrive in your bank account soon</span>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="income-footer">
                      <Card.Text>
                        <Row>
                          <Col md="8">
                            <span className="text-bold">Total Price</span>
                          </Col>
                          <Col md="4" className="text-right">
                            <span><span className="text-bold">0.00</span> EUR</span>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 className="my-3">Estimated future payouts</h1>
                </Col>
              </Row>
              <Row className="income">
                <Col>
                  <Card className="income-detail-card mb-3 obex-card">
                    <Card.Body className="p-0">
                      <Card.Text className="px-3 pt-2 mb-2">
                        <Row>
                          <Col>
                            <span>
                              These amounts are estimated because transactions are still accumulating.
                              Payouts are scheduled to <a href="/docs/Currencies/Currencies_FX_Exchange_EN">automatically send daily.</a>
                            </span>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="income-footer">
                      <Card.Text>
                        <Row>
                          <Col md="8">
                            <span className="text-bold">Total Price</span>
                          </Col>
                          <Col md="4" className="text-right">
                            <span><span className="text-bold">0.00</span> EUR</span>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default IncomeContent;