import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DevDashboardLayout';
import DashRouters from '../../../configs/routers/dev-dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, InputGroup, FormControl, AlertProps, Spinner, Container } from 'react-bootstrap';
import PhoneVerificationModal from '../../Modals/phoneVerificationModal';
import AddBankAccount from '../../Modals/addBankAccount';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Console } from 'console';
import ConfirmDeleteProjectModal from '../../Modals/ConfirmDeleteProjectModal';
import SessionService from '../../../services/SessionsService';
import ModifyCompanyLogo from '../../Clientscomponent/ModifyCompanyLogo';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../mobileComponents/Disclaimer';


type AccountInfProps = {
};

const accountSetting = {
  account_name: '',
  address_line_1: '',
  address_line_2: '',
  postal_code: 0,
  locality: '',
  obex_city_id: 0,
  city: '',
  country: '',
  country_code: '',
  latitude: '',
  longitude: '',
  phone_verification: false,
  obex_timezone_id: 0,
  timezone: '',
  statement_descriptor: ''
};

const DevAccountInformationContent: FunctionComponent<AccountInfProps> = ({ }) => {


  const [loading, setLoading] = useState(false);
  const [accountSettingsStored, setAccountSettings] = useState(accountSetting);
  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [cities, setCities] = useState([]);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [cityId, setCity] = useState('');
  const [locality, setLocality] = useState('');
  const [phone, setPhone] = useState('');
  const [timeZoneId, setTimeZone] = useState('');
  const [statementDescriptor, setStateDescriptor] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [verifyEnabled, setVerify] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [poneVerified, setPhoneVerified] = useState(false);
  const history = useHistory();
  const [showDeleteModal, setDeleteAccount] = useState(false);

  const [RegisteredConfigAccount, setCreated] = useState(false);
  const [show3, setShow3] = useState(false);

  const [enterpriseLogo, setEnterpriseLogo] = useState(SessionService.Logo);

  const handleEnterpriseLogo = () => setShow3(true);

  const handleClose = () => {


    setShow3(false);



  }
  const handleEnLogo = (url) => setEnterpriseLogo(url);

  const requestOtpVerification = async () => {
    try {
      await ObexRequestHandler.post('/request_otp', { phoneNumber: phone });
      setShowOtpModal(true);
    } catch (error) {
      console.error('ERROR REQUESTING OTP CODE ', error);
    }
  };

  const handleAccountName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setAccountName(value);
    setSubmitDisabled(false);
  }

  const handleAddressLine1 = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setAddressLine1(value);
    setSubmitDisabled(false);
  }

  const handleAddressLine2 = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setAddressLine2(value);
    setSubmitDisabled(false);
  }

  const handlePostalCode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setPostalCode(value);
    setSubmitDisabled(false);
  }

  const handleCity = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setCity(value);
    setSubmitDisabled(false);
  }

  const handleCountry = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    const cities = (await ObexRequestHandler.get(`/cities?country_code=${value}`)).data;
    e.preventDefault();
    e.stopPropagation();
    setCities(cities);
    setSubmitDisabled(false);
  }

  const handlePhone = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setPhone(value);
    const verify = value.length >= 8;
    setVerify(verify);
    setSubmitDisabled(false);
  }

  const handleTimeZone = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setTimeZone(value);
    setSubmitDisabled(false);
  }

  const handleStateDescriptor = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setStateDescriptor(value);
    setSubmitDisabled(false);
  }

  const getAccountSettings = async () => {
    try {
      const result = await ObexRequestHandler.get('/account_settings');

      const { success, data } = result;
      if (success) {
        if (Object.keys(data).length) {
          setAccountName(data.account_name);
          setAddressLine1(data.address_line_1);
          setCity(data.city);
          setPostalCode(data.postal_code);
          setStateDescriptor(data.statement_descriptor);
          setCreated(true);
          setStillLoaded(false);
        }
        else {
          setCreated(false);
          setStillLoaded(false);
        }
      } else {
        console.log('Cannot get the account settings information');
        setStillLoaded(false);
      }
    } catch (error) {
      console.error('ERROR FETCHING ACCOUNT SETTINGS ', error);
      setStillLoaded(false);
    }
    setStillLoaded(false);
  }

  const getCountries = async () => {
    try {
      const result = (await ObexRequestHandler.get('/countries')).data;
      setCountries(result);
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }
  }

  const getTimezones = async () => {
    try {
      const result = (await ObexRequestHandler.get('/timezones')).data;
      setTimezones(result);
    } catch (error) {
      console.error('ERROR FETCHING TIMEZONES ', error);
    }
  }

  const handleDeleteProject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteAccount();
  }

  const deleteAccount = async () => {
    setStillLoaded(true);
    try {
      const result = (await ObexRequestHandler.delete('/subscription')).data;
      setStillLoaded(false);
      SessionService.logout();
      history.push('/login');
    } catch (error) {
      setStillLoaded(false);
      console.error('ERROR DELETING ACCOUNT ', error);
    }
    setStillLoaded(false);
  }

  const routeChangePassword = () => {
    history.push('/settings/change_password');
  }

  const countriesList = countries.map(c => <option value={c.country_code} selected={locality == c.country_code}>{c.country}</option>);
  const citiesList = cities.map(c => <option value={c.id} >{c.city}</option>);
  const timezonesList = timezones.map(t => <option value={t.id} >{t.name}</option>);
  const [stillLoaded, setStillLoaded] = useState(true);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setError('');
      setSuccess('');
      setLoading(true);
      const requestFunc = RegisteredConfigAccount ? async (path, payload) => await ObexRequestHandler.patch(path, payload) : async (path, payload) => await ObexRequestHandler.post(path, payload);

      // Limpiamos los posibles errores
      const payload = {
        account_name: accountName,
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        postal_code: postalCode,
        locality: locality || 'inventadaParaQueNoPete',
        obex_city_id: cityId,
        phone_verification: true || phone, //TODO quitar el true este porque espera boolean no telefono...
        obex_timezone_id: timeZoneId,
        statement_descriptor: statementDescriptor,
      }
      const result = await requestFunc('/account_settings', payload)
      const { success, data, message } = result;

      setStillLoaded(false);
      if (success) {
        if (!RegisteredConfigAccount) setCreated(true);
        setSuccess(data);
        setTimeout(function () {
          setSuccess('');
          setSubmitDisabled(true);
        }.bind(this), 1000)
        setStillLoaded(false);
      } else {
        setError(message);
        setStillLoaded(false);
      }
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
      setStillLoaded(false);
    }
    setLoading(false);
    setStillLoaded(false);
  };

  useEffect(() => {
    getAccountSettings();
    getCountries();
    getTimezones();
  }, [])

  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'setting'}>
          <Row>
            <Col md="9" className="mx-auto">
              <Row>
                <Col>
                  <h1 className="mb-3">Account settings</h1>
                </Col>
              </Row>
              {stillLoaded ?
                <Container className="vh-100 d-flex flex-column vw-100">
                  <Row className="h-100" >
                    <Col md="1" className="mx-auto d-flex align-items-center">
                      <Spinner animation="border" />
                    </Col>
                  </Row>
                </Container>
                :
                <>

                  <ModifyCompanyLogo
                    show={show3}
                    handleClose={handleClose}
                    handleLogo={handleEnLogo}
                    nameProject={"SessionService.DatosEmpresa['company_name']"}
                    planID={'clientIDToSend'}
                    priceMonthly={enterpriseLogo}
                    priceYear={'enterpriseAddress'}
                    logoname={'LogoName'}
                  />




                  <Row>
                    <Col>
                      <Card className="product-detail-card mb-3">
                        <Card.Body className="p-3">
                          <button className="btn-transparent" style={{ float: 'right' }}><FontAwesomeIcon icon={faEdit} onClick={handleEnterpriseLogo} /> </button>
                          <Row>
                            <Col className="mx-auto d-block text-center">
                              <Form className="obex-form">
                                <Form.Row className="mb-3 mx-auto d-block">

                                  <img id='logo_settings'
                                    src={SessionService.imageContent}
                                    alt=""
                                    title="Logo de la empresa"

                                    height="200" />

                                </Form.Row>
                              </Form>

                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>


                  <Row>
                    <Col>
                      <Card className="product-detail-card mb-3">
                        <Card.Body className="p-3">
                          <Row>
                            <Col className="mx-auto">
                              <Form className="obex-form" onSubmit={handleSubmit}>
                                <Form.Row className="mb-3">
                                  <Form.Label column sm="12" className="obex-form-label">
                                    Account Name (email)
                                  </Form.Label>
                                  <Col md="12">
                                    <Form.Control disabled type="email" value={accountName} placeholder="Account Name (email)" onChange={null} />
                                  </Col>
                                </Form.Row>
                                <Form.Row className="mb-3">
                                  <Form.Label column sm="12" className="obex-form-label">
                                    Business Address
                                  </Form.Label>
                                  <Col md="12">
                                    <Form.Control required type="text" value={addressLine1} placeholder="Addresss line" onChange={handleAddressLine1} />
                                  </Col>
                                </Form.Row>
                                <Form.Row className="mb-3">
                                  <Col md="9">
                                    <Form.Control type="text" value={addressLine2} placeholder="Addres line 2" onChange={handleAddressLine2} />
                                  </Col>
                                  <Col md="3">
                                    <Form.Control required type="number" value={postalCode} placeholder="Postal code" onChange={handlePostalCode} />
                                  </Col>
                                </Form.Row>
                                <Form.Row className="mb-3">

                                  <Col md="4">
                                    <Form.Control required as="select" placeholder='Country' custom onChange={handleCountry}>
                                      <option value="" selected disabled hidden>Choose country</option>
                                      {countriesList}
                                    </Form.Control>
                                  </Col>
                                  <Col md="8">
                                    <Form.Control required as="select" placeholder='City' custom onChange={handleCity}>
                                      {cities.length > 0
                                        ? citiesList
                                        : <option value="" selected disabled hidden>Choose a city</option>}
                                    </Form.Control>
                                  </Col>
                                </Form.Row>
                                <Form.Row className="mb-3">
                                  <Form.Label column sm="12" className="obex-form-label">
                                    Phone verification
                                  </Form.Label>
                                  <Col md={12}>
                                    <Row>
                                      <Col md="8" className="pr-0">
                                        <FormControl
                                          type="number"
                                          placeholder="Phone"
                                          aria-describedby="phone-verification"
                                          onChange={handlePhone} />
                                      </Col>
                                      <Col md={4}>
                                        <Button className="btn-block obex-btn btn-green btn-verify"
                                          onClick={requestOtpVerification}
                                          disabled={accountSettingsStored.phone_verification ? accountSettingsStored.phone_verification : !verifyEnabled}>
                                          {poneVerified ? 'Verified' : 'Verify'}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Form.Row>
                                <Form.Row className="mb-3">
                                  <Form.Label column sm="12" className="obex-form-label">
                                    Time zone
                                  </Form.Label>
                                  <Col md="12">
                                    <Form.Control required as="select" custom onChange={handleTimeZone}>
                                      <option value="" selected disabled hidden>Choose a timezone</option>
                                      {timezonesList}
                                    </Form.Control>
                                  </Col>
                                </Form.Row>
                                <Form.Row className="mb-3">
                                  <Form.Label column sm="12" className="obex-form-label">
                                    Statement descriptor
                                  </Form.Label>
                                  <Col md="12">
                                    <Form.Control
                                      required
                                      type="text"
                                      value={statementDescriptor}
                                      placeholder="Statement descriptor"
                                      onChange={handleStateDescriptor} />
                                  </Col>
                                </Form.Row>
                                <Row className="mt-3">
                                  <Col md="4" className="ml-auto">
                                    <Button type="submit" disabled={submitDisabled} className="custom-button-blue w-80 textBold">
                                      Update {success && <FontAwesomeIcon icon={faCheck} />}
                                      {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                                    </Button>
                                  </Col>
                                  {error.length > 0 && (
                                    <Alert variant='danger'>
                                      {error}
                                    </Alert>)}
                                  {success.length > 0 && (
                                    <Alert variant='success'>
                                      {success}
                                    </Alert>)}
                                </Row>
                              </Form>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row><Row>
                    <Col>
                      <h1 className="my-3">Change Password</h1>
                    </Col>
                  </Row><Row>
                    <Col>
                      <Card className="product-detail-card mb-3">
                        <Card.Body className="p-3">
                          <Card.Text>
                            <Row>
                              <Col md="4" className="my-auto">
                                <span>Change password</span>
                              </Col>
                              <Col md="8" className="">
                                <Row>
                                  <Col md="6" className="ml-auto">
                                    <Button className="btn-block obex-btn btn-green" onClick={routeChangePassword}>Change Password</Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row><Row>
                    <Col>
                      <h1 className="my-3">Your Account</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="product-detail-card mb-3">
                        <Card.Body className="p-3">
                          <Card.Text>
                            <Row>
                              <Col md="8" className="">
                                <span>By closing your account, you will lose access to
                                  your financial data and your key will expire.
                                  This is irreversible.</span>
                              </Col>
                              <Col md="4" className="my-auto">
                                <Button className="btn-block obex-btn btn-orange" onClick={() => { setDeleteAccount(true); }}>Close Account</Button>
                              </Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row></>
              }
              {showOtpModal && <PhoneVerificationModal phoneNumber={phone} show={showOtpModal} countryCode='' handleClose={() => setShowOtpModal(false)} />}
              {showDeleteModal && <ConfirmDeleteProjectModal showModal={showDeleteModal} handleSubmit={handleDeleteProject} onCloseModal={() => setDeleteAccount(false)} loading={false} error={''} success={''} name_to_check={'cancel account'} title_to_show={'Account'} />}
            </Col>
          </Row>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>

  )
}

export default DevAccountInformationContent;