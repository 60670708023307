import React, { FunctionComponent, useState, useEffect } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Table, Container, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../../services/AmountService';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../mobileComponents/Disclaimer';

type PaymentsProps = {

}


const PaymentsContent: FunctionComponent<PaymentsProps> = (props) => {

  const [payments, setPayments] = useState([]);
  const [stillLoaded, setStillLoaded] = useState(true);
  const [sublinks, setSublinks] = useState([
    {
      "path": "upgrade_subcription",
      "title": "Upgrade Subscription"
    },

    {
      "path": "change_payment_duration",
      "title": "Change Payment Duration"
    },

    {
      "path": "payments",
      "title": "Payments"
    }

  ]);

  const getPayments = async () => {
    try {
      const result = await ObexRequestHandler.get('/payments');
      const { success, data } = result;
      if (success) {
        setStillLoaded(false);
        setPayments(data);
      } else {
        setStillLoaded(false);
        console.log('Cannot get the account settings information');
      }
    } catch (error) {
      setStillLoaded(false);
      console.error('ERROR FETCHING ACCOUNT SETTINGS ', error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getPayments()
  }, [])

  return (
    <>
      <BrowserView>
        <DashLayout active="plan_billing" sider={DashRouters} >
          <div style={{ marginLeft: '-15px' }}>
            {stillLoaded ?

              <Container className="vh-100 d-flex flex-column vw-100">

                <Row className="h-100" >


                  <Col md="1" className="mx-auto d-flex align-items-center">
                    <Spinner animation="border" />
                  </Col>

                </Row>
              </Container>
              :

              <><Row style={{ marginBottom: '2rem' }}>
                <Col>
                  <h2 className='textBold'>Subscription</h2>
                </Col>
              </Row>
                <Row>

                  <Col>
                    <div style={{ borderStyle: 'solid', borderColor: '#ededee', borderWidth: '1px', borderRadius: '7px' }}>
                      <Table responsive>

                        <thead style={{ fontFamily: 'Regular Intro Bold', textAlign: 'start', backgroundColor: 'transparent', color: '#595959', borderBottomColor: '#ededed', borderBottomStyle: 'solid', borderBottomWidth: '2px' }}>
                          <div className='nowarp fontSizeTitleCard textBold' style={{ paddingTop: '10px', fontSize: '1.2vw', height: '50px', paddingLeft: '34px' }}>Subscription Payments</div>
                        </thead>

                        <tbody>
                          <tr style={{ color: '#595959' }}>
                            <th className='textBold'>Type</th>
                            <th style={{ textAlign: 'center' }} className='textBold'>NET</th>
                            <th style={{ textAlign: 'center' }} className='textBold'>Amount</th>
                            <th style={{ textAlign: 'center' }} className='textBold'>FEE</th>
                            <th style={{ textAlign: 'center' }} className='textBold'>Description</th>
                            <th style={{ textAlign: 'end' }} className='textBold'>Available on</th>

                          </tr>

                          {payments.map(payment => (
                            <tr>
                              <td>Payout</td>
                              <td style={{ textAlign: 'center' }} >({parseToCoin(payment.amount)})</td>
                              <td style={{ textAlign: 'center' }} >{parseToCoin(payment.amount)}</td>
                              <td style={{ textAlign: 'center' }} > - </td>
                              <td style={{ textAlign: 'center' }} >{payment.description}</td>
                              <td style={{ textAlign: 'end' }} >{payment.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row></>
            }
          </div>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default PaymentsContent;