import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/ClientDashboardLayout';
import { Row, Col, } from 'react-bootstrap';
import ConsumptionChart from './GraphComponents/ConsumptionChart';
//import ConsumptionChart from './GraphComponents/LineChart2';
import Consumptions from './Consumption/Consumptions';
import ClientCategoriesConsumption from './Consumption/ClientCategoriesConsumption';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type DashProps = {
  sider: any
};


const ClientDashboardContent: FunctionComponent<DashProps> = ({ sider }) => {

  return (
    <>
      <BrowserView>
        <DashLayout sider={sider}>
          {/*}   <Consumptions/> */}
          <Row className="">
            <Col className="mt-4 p-0">
              {/*        <ConsumptionChart/>*/}
            </Col>
          </Row>
          <ClientCategoriesConsumption />
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default ClientDashboardContent;