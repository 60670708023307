import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Button, Form, Table, Spinner, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import AddOrganizationModal from '../Modals/addOrganizationModal';
import EditOrganizationModal from '../Modals/editOrganizationModal';
import SessionService from '../../services/SessionsService';
import AddOrganizationSlots from '../Modals/addOrganizationSlots';

import Logo from '../../assets/img/ilustracion_noInfo2.png'
import editicon from '../../assets/img/icon_edit.png'
import SupportBanner from './supportBanner';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type IncomeProps = {
};


const Organizations: FunctionComponent<IncomeProps> = ({ }) => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [filtro, setFilter] = useState('');
  const [selected_org, setSelectedOrg] = useState(0);
  const [selected_org_name, setSelectedOrgName] = useState('');
  const [selected_org_description, setSelectedOrgDescription] = useState('');
  const [currentOrganizations, setCurrentOrganizations] = useState(0);
  const [maxOrganizations, setMaxOrganizations] = useState(0);
  const [loading, setLoading] = useState(true);
  const [addingOrg, setaddingOrg] = useState(false)

  const changeFilter = (e) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
  }

  const handleOrg = () => {
    setaddingOrg(!addingOrg);
    getProjects('');
  }

  const handleModal = () => {
    getProjects('');
    setShowModal(!showModal);
    //SessionService.dispatchOrganizationChangedEvent();
    setTimeout(function () {


      SessionService.dispatchOrganizationChangedEvent();

    }.bind(this), 1000)
  };

  const handleModal2 = () => {
    getProjects('');
    setShowModal2(!showModal2);
    //SessionService.dispatchOrganizationChangedEvent();
    setTimeout(function () {


      SessionService.dispatchOrganizationChangedEvent();

    }.bind(this), 1000)
  };

  const getProjects = async (filtro) => {
    try {
      setLoading(true);
      setProjects([]);

      const projects = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];

      setCurrentOrganizations(projects['result'].length);
      setMaxOrganizations(projects['max']);


      if (filtro == '') setProjects(projects['result']);
      else {

        const filter_projects = [];
        projects['result'].forEach(element => {
          if (element.name.toLowerCase().includes(filtro.toLowerCase())) filter_projects.push(element);
        });

        setProjects(filter_projects);

      }

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setLoading(false);

    }
    setLoading(false);

  }


  useEffect(() => {
    getProjects('');
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'organizations'} sublinks={[]}>
          <Row>
            <SupportBanner />
            <Col id='pagina' style={{ width: '100%' }} className="" >


              <Row style={{ marginTop: '0px' }}>
                <div className="textBold" style={{ width: '70%', marginBottom: '2rem' }}><h2>Manage Organizations</h2></div>
                <div style={{ width: '30%', textAlign: 'end' }}>


                  {currentOrganizations < maxOrganizations ?
                    <Button style={{ width: '225px', marginRight: '2rem' }} disabled={null} className="custom-button-blue  textBold px-4"
                      onClick={() => { setShowModal(true) }}>Create Organization</Button>
                    :
                    <Button style={{ width: '225px', marginRight: '2rem' }} disabled={null} className="custom-button-blue  textBold px-4"
                      onClick={() => {
                        setaddingOrg(true);
                      }
                      }>Create Organization</Button>
                  }

                </div>

              </Row>
              <Form.Row >
                <Col style={{ marginLeft: '-15px' }}>
                  <Form.Control required type="text" value={filtro} placeholder="Search by Organization name"
                    onChange={changeFilter} />
                </Col>

                <Col md={3} style={{ marginRight: '1rem' }}>
                  <Button disabled={null} className=" custom-button-blue w-100 textBold px-4"
                    onClick={() => { getProjects(filtro) }}><FontAwesomeIcon icon={faSearch} size="1x"
                      className="mr-1" /></Button>
                </Col>
              </Form.Row>



              <Row style={{ marginTop: '20px' }}>
                <Col style={{ marginLeft: '-20px' }}>

                  {(addingOrg) && <AddOrganizationSlots shown={addingOrg} onCloseAction={handleOrg} onSuccess={handleOrg} MaxDBSize={1} ActualDBSize={0} />}

                  {loading &&

                    <Row style={{ textAlign: 'center' }}>
                      <Col>
                        <Spinner animation="border" style={{ marginLeft: '8px' }} />
                      </Col>
                    </Row>

                  }

                  {!loading &&

                    <div style={{ borderStyle: 'solid', borderColor: '#ededee', borderWidth: '1px', borderRadius: '7px' }}>
                      <Table responsive>


                        <thead style={{ fontFamily: 'Regular Intro Bold', textAlign: 'start', backgroundColor: 'transparent', color: '#595959', borderBottomColor: '#ededed', borderBottomStyle: 'solid', borderBottomWidth: '2px' }}>
                          <div className='nowarp' style={{ paddingTop: '10px', fontSize: '1.2vw', height: '50px', paddingLeft: '15px' }}>Organizations list</div>
                        </thead>

                        <tbody>
                          <tr style={{ color: '#595959' }}>
                            <th className='textBold'>Name</th>
                            <th className='textBold' style={{ textAlign: 'center' }}>Description</th>
                            <th className='textBold' style={{ textAlign: 'center' }}># Of Developers</th>
                            <th className='textBold' style={{ textAlign: 'end' }}>Action</th>

                          </tr>

                          {projects.map(project => (
                            <tr key={project.id}>
                              <td>{project.name}</td>
                              <td style={{ textAlign: 'center' }}>{project.description}</td>
                              <td style={{ textAlign: 'center' }}>{project.developers}</td>
                              <td className="text-right">
                                Edit
                                <a className="edit-link" onClick={async () => {
                                  setSelectedOrg(project.id);
                                  setSelectedOrgName(project.name);
                                  setSelectedOrgDescription(project.description)
                                  setShowModal2(true);
                                }
                                }><Image src={editicon} style={{ marginTop: '-5px', marginBottom: '', paddingLeft: '10px' }} width="30px" alt="Logo" /></a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  }
                </Col>
              </Row>

              {(projects.length == 0 && loading == false) &&
                <div>
                  <><Row className="justify-content-center my-4">
                    <Col xs="6" style={{ display: 'flex', justifyContent: 'center' }}>
                      <Image style={{ width: '50%', marginTop: '60px', marginRight: '0' }} src={Logo} fluid />
                    </Col>
                  </Row>
                    <Col style={{ textAlign: 'center', marginBottom: '60px', marginRight: '0' }}>
                      <Row style={{ marginRight: '0' }}><Col><h5>You don’t have data to show</h5></Col></Row>
                      <Row style={{ marginRight: '0' }}><Col>When you have data to show, you’ll find here.</Col></Row>
                      {/*} <Row style={{marginRight:'293px'}}><Col>want to track, you'll find here. </Col></Row>*/}
                    </Col>
                  </>
                </div>
              }


              {showModal &&
                <AddOrganizationModal
                  show={showModal}
                  handleClose={handleModal} />
              }
              {showModal2 &&
                <EditOrganizationModal show={showModal2} organization={selected_org}
                  organization_name={selected_org_name} organization_description={selected_org_description}
                  handleClose={handleModal2} />
              }
            </Col>
          </Row>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>


  )
}

export default Organizations;




