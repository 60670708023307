import React, { FunctionComponent, Suspense, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import BaseLayout from '../functionalComponents/baseLayout/BaseLayout';
import ReactMarkdown from 'react-markdown';
import '../../assets/css/prism.css';
import '../../assets/css/contentdoc.css';
import { readdir } from 'fs';
import { useDispatch, useSelector } from 'react-redux';
import { updateName } from '../../redux/slices/KnowDivInScreen';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type wikiProps = {
    doc: string,
    sider: any,
    cat: string
};

const ContentWiki: FunctionComponent<wikiProps> = props => {

    const { doc, cat, sider } = props;
    //   const md = require(`../../assets/docs/${doc}.md`);

    //   console.log(doc);
    //   console.log(sider.menus);

    const [alldivs, setAllDivs] = useState([]);

    const divRefs = useRef<Array<HTMLDivElement | null>>([]);

    const sendToNameSection = useSelector((state: any) => state.nameDiv.sendToNameSection)
    const sendToNameSubSection = useSelector((state: any) => state.nameDiv.sendToNameSubSection)
    const getToSectionAlgolia = useSelector((state: any) => state.nameDiv.getToSectionAlgolia)

    const dispatch = useDispatch();
    const currentName = useSelector((state: any) => state.nameDiv.name)

    useEffect(() => {
        // Importamos prism cada vez que cambia el markdown file y ejecutamos el highlightAll
        const prism = require('../../assets/js/prism.js');
        prism.highlightAll();

        let divs = [];
        let menus = [...sider.menus];
        let showFirst = sider.showFirst
        if (showFirst) {
            const name_section = showFirst.id;
            const sections = showFirst.links;
            console.log(sections);

            sections.forEach(items => {
                let path = items.path;
                divs.push(
                    <div className='py-5 border-bottom' key={name_section + "-" + path} id={name_section + "-" + path} ref={ref => divRefs.current.push(ref)}>
                        {items.view}
                    </div>
                )
            });
        }

        for (let index = 0; index < menus.length; index++) {
            const name_section = menus[index].id;
            const sections = menus[index].links;

            sections.forEach(items => {
                let path = items.path;

                divs.push(
                    <div className='py-5 border-bottom' key={name_section + "-" + path} id={name_section + "-" + path} ref={ref => divRefs.current.push(ref)}>
                        {items.view}
                    </div>
                )
            });
        }

        setAllDivs(divs);

    }, [doc, sider]);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;

            for (let i = 0; i < divRefs.current.length; i++) {
                const div = divRefs.current[i];
                if (!div) continue;

                const rect = div.getBoundingClientRect();
                if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
                    const newName = div.id;
                    if (newName !== currentName) {
                        dispatch(updateName(newName));
                    }
                    break;
                } else if (currentName !== "") {
                    dispatch(updateName(""));
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        // console.log("change",sendToNameSection);
        // const current = divRefs.current.find(ref => console.log(ref.id));
        for (let index = 0; index < divRefs.current.length; index++) {
            const divId = divRefs.current[index].id;
            const div = divRefs.current[index];

            if (divId.includes(sendToNameSection)) {
                div.scrollIntoView({ behavior: 'auto' });
                break;
            }
        }
    }, [sendToNameSection]);


    useEffect(() => {

        for (let index = 0; index < divRefs.current.length; index++) {
            const divId = divRefs.current[index].id;
            const div = divRefs.current[index];

            if (divId === sendToNameSubSection) {
                div.scrollIntoView({ behavior: 'auto' });
                break;
            }
        }
    }, [sendToNameSubSection]);

    useEffect(() => {
        //To send sections when use ALGOLIA
        for (let index = 0; index < divRefs.current.length; index++) {
            const divId = divRefs.current[index].id;
            const div = divRefs.current[index];

            if (divId.includes(getToSectionAlgolia)) {
                div.scrollIntoView({ behavior: 'auto' });
                break;
            }
        }
    }, [getToSectionAlgolia]);

    return (
        <>
            <BrowserView>
                <BaseLayout sider={sider} active={cat}>
                    <Row className="justify-content-md-center contentMarginX">
                        <Col md={{ span: 12 }}>
                            <Suspense fallback={<div>Cargando...</div>}>
                                {alldivs}
                            </Suspense>
                        </Col>
                    </Row>
                </BaseLayout>
            </BrowserView>
            <MobileView>
                <Disclaimer />
            </MobileView>
        </>
    )
}

export default ContentWiki;
