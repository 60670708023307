import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Button, Container, Spinner } from 'react-bootstrap';
import BuyPluginModal from '../Modals/buyPluginModal';
import SettingPluginModal from '../Modals/settingPluginModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import AddBalancePluginModal from '../Modals/addBalancePluginModal';
import { parseToPeso } from '../../services/AmountService';
import { SiderProps } from 'antd/lib/layout';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';


type MarketProps = {
  sider: any
};

type PluginCardProps = {
  name: string,
  description: string,
  price?: number,
  active?: boolean,
  pluginId: number,
  project: string,
  balance: number,
  rep_id: string,
  rep_pk: string,
  project_plugin_id: string,
  pluginProject: number
  getPlugins: () => void,
}

// Card genérica de stats
const PluginCard: FunctionComponent<PluginCardProps> = (props: PluginCardProps) => {

  const {
    name,
    price,
    description,
    active,
    pluginId,
    project,
    balance,
    rep_id,
    rep_pk,
    project_plugin_id,
    pluginProject,
    getPlugins } = props

  const [showBuyPluginModal, setShowBuyPluginModal] = useState(false);
  const onCloseBuyPluginModal = () => setShowBuyPluginModal(false);
  const [showSettingPluginModal, setShowSettingPluginModal] = useState(false);
  const onCloseSettingPluginModal = () => setShowSettingPluginModal(false);
  const [showAddbalancePlugin, setShowAddbalancePlugin] = useState(false);
  const onCloseAddBalancePluginModal = () => { setShowAddbalancePlugin(false); getPlugins(); }
  const [exchangeFactor, setEFactor] = useState(0);
  const [currency, setCurrecy] = useState('');

  const [stillLoading, setStillLoaded] = useState(false);


  const getCurrencie = async () => {
    setStillLoaded(true);
    const result = await ObexRequestHandler.get(`/currencie?projectID=${pluginProject}`);
    setEFactor(result['data']['cambio_compra']);
    setCurrecy(result['data']['name']);

    setStillLoaded(false);
    setShowAddbalancePlugin(true);

  }

  const onSuccesBought = () => {
    setShowBuyPluginModal(false);
    getPlugins();
  }

  return (
    <Col md="3" className="border-left request pt-2 pb-4">
      <Row>
        <Col className="request-title pl-2">
          <span>{name}</span>
        </Col>
      </Row>
      <Row>
        {active ?
          <Fragment>
            <Col md="8" className="request-text d-flex">
              <span className="text-center my-auto">{parseToPeso(balance)}</span>
            </Col>
          </Fragment>
          :
          <Col md={{ span: 10 }} className="request-text pl-2">
            <Button className="obex-btn btn-add-ligth-gray btn-block mt-2"
              onClick={() => setShowSettingPluginModal(true)} >Description
            </Button>
            <BuyPluginModal showModal={showBuyPluginModal}
              onCloseModal={onCloseBuyPluginModal}
              onSuccess={onSuccesBought}
              pluginName={name}
              pluginPrice={price}
              pluginId={pluginId}
              project={project} />
            <SettingPluginModal showModal={showSettingPluginModal}
              onCloseModal={onCloseSettingPluginModal}
              pluginName={name}
              pluginPrice={price}
              pluginDescription={description} />
          </Col>
        }
      </Row>
      {active &&
        <Row>
          <Col md="8" className="ml-auto">
            <Button className="obex-btn btn-green btn-block mt-2" onClick={() => getCurrencie()} >Settings
              {stillLoading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
            </Button>
            <AddBalancePluginModal
              shown={showAddbalancePlugin}
              onCloseModal={onCloseAddBalancePluginModal}
              pluginName={name} pluginPrice={price}
              plug_project_id={project_plugin_id}
              rep_pk={rep_pk}
              rep_id={rep_id}
              project={project}
              exchangeFactor={exchangeFactor}
              currency={currency} />
          </Col>
        </Row>
      }
      {!active ?
        <FontAwesomeIcon icon={faArrowAltCircleDown} className="button-download btn-request" size="2x" onClick={() => setShowBuyPluginModal(true)} />
        : <FontAwesomeIcon icon={faMinusCircle} className="button-download btn-request" size="2x" onClick={() => alert('deleting this plugin')} />
      }
    </Col>
  )
}


const MartketPlaceContent: FunctionComponent<MarketProps> = (props: MarketProps) => {

  const { sider } = props
  const [availablePlugins, setAvailablePlugins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activedPlugins, setActivedPlugins] = useState([]);
  const [mergedPlugins, setMergedPlugins] = useState([]);
  const [stillLoaded, setStillLoaded] = useState(true);
  const { project } = useParams();

  const checkActivePlugins = (availables, actives) => {
    const activesIds = actives.map(p => p.plugin_id);
    const activedPlugins = availables.map(p => {
      p.active = activesIds.includes(p.id);
      return p;

    });
    setMergedPlugins(activedPlugins);
  }

  const getPlugins = async () => {
    try {
      setLoading(true);
      const availablePlugins = (await ObexRequestHandler.get('/plugins')).data || [];
      const activedPlugins = (await ObexRequestHandler.get(`/actived_plugins/${project}`)).data || [];
      setAvailablePlugins(availablePlugins);
      setActivedPlugins(activedPlugins);
      checkActivePlugins(availablePlugins, activedPlugins);
      setStillLoaded(false);
    } catch (error) {
      console.error('ERROR FETCHING PLUGINS ', error);
      setStillLoaded(false);
    }
    setLoading(false);
    setStillLoaded(false);
  }

  const activePluginCards = activedPlugins.map(p => {
    return <PluginCard
      key={'market' + p.id}
      name={p.name}
      pluginId={p.id}
      description={p.description}
      price={p.monthly_price}
      active={true}
      project={project}
      balance={p.balance}
      rep_id={p.representative_id}
      rep_pk={p.representative_pvk}
      project_plugin_id={p.plugin_id}
      pluginProject={p.obex_project_id}
      getPlugins={getPlugins} />
  })
  const inactivePluginCards = mergedPlugins.map(p => {
    if (!p.active)
      return <PluginCard
        key={'inactiveplugins' + p.id}
        name={p.name}
        pluginId={p.id}
        description={p.description}
        price={p.monthly_price}
        active={p.active}
        project={project}
        balance={p.balance}
        rep_id={p.representative_id}
        rep_pk={p.representative_pvk}
        project_plugin_id={p.plugin_id}
        pluginProject={p.project_id}
        getPlugins={getPlugins} />
  });

  useEffect(() => {
    getPlugins();
  }, [])

  return (
    <>
      <BrowserView>
        <DashLayout sider={sider} active="market_place">


          {stillLoaded ?
            <Container className="vh-100 d-flex flex-column vw-100">
              <Row className="h-100" >
                <Col md="1" className="mx-auto d-flex align-items-center">
                  <Spinner animation="border" />
                </Col>
              </Row>
            </Container>
            :
            <><Row>
              <h1 className="mb-3">Installed</h1>
            </Row><Row className="Tx-stats Rq-stats border-top mb-2 pt-2">
                {activePluginCards}
              </Row><Row>
                <h1 className="mb-3">Available</h1>
              </Row>
              <Row className="Tx-stats Rq-stats border-top mb-2 pt-2">
                {inactivePluginCards}

              </Row></>
          }
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default MartketPlaceContent;