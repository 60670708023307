import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Button, Form, Table, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { Select } from 'antd';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type PrivilegesProp = {
  developer_id: string
};


const Privileges: FunctionComponent<PrivilegesProp> = (props: PrivilegesProp) => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [projectstoSel, setProjectstoSel] = useState([]);
  const [developer, setDeveloper] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadDevs, setloadDevs] = useState(false);
  const [ActualPlan, setActualPlan] = useState(0);
  const [projectSelected, setProjectSelected] = useState('0');
  const [Projec_Sel, setProjectSel] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(0);
  let yx = null;
  const { developer_id } = props;
  const apiOps = [
    { label: "Inactive", value: "0" },
    { label: "Read", value: "1" },
    { label: "Write", value: "2" }

  ]

  const getProjectstoSel = async () => {
    let optionProjects = [];
    let projects_sel = [];
    try {

      const projects = (await ObexRequestHandler.get('/projects')).data;
      const sublinksProjects = []
      projects_sel = projects.filter(crea => crea.isCreating);
      setProjectSel(projects);
      projects.map((project, i) => {
        const linkProject = {
          path: project.name,
          title: project.description,
        }
        sublinksProjects.push(linkProject)
      });
      optionProjects = projects.filter(crea => crea.isCreating).map(value => <option key={value.id} value={value.name}>{value.description}</option>)
      setProjectstoSel(optionProjects);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    }
    /*console.log('PROJECTS TO SEL');
    console.log(projects_sel);
    console.log(developer_id);*/

    getProjects(developer_id, projects_sel[0].id);
    setSelectedProjectId(projects_sel[0].id);
    setLoading(false);
  }

  //toggle_privilege

  const togglePrivilege = async (dev_id, project_id, rule_id, status) => {
    // setloadDevs(true);

    if (status) { yx = document.getElementById(`spin_on_${rule_id}`); }
    else { yx = document.getElementById(`spin_off_${rule_id}`); }

    yx.hidden = false;

    try {
      await ObexRequestHandler.get(`/toggle_privilege?dev_id=${dev_id}&project_id=${project_id}&rule_id=${rule_id}`, {}, true);
    }
    catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      //setloadDevs(false);
      yx.hidden = true;

    }
    //setloadDevs(false);

    getProjects(dev_id, project_id, false);
  }


  const toggleReadWritePrivilege = async (dev_id, project_id, rule_id) => {
    try {
      await ObexRequestHandler.get(`/toggle_read_write_privilege?dev_id=${dev_id}&project_id=${project_id}&rule_id=${rule_id}`, {}, true);
    }
    catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      //setloadDevs(false);

    }
    //setloadDevs(false);

    getProjects(dev_id, project_id, false);
  }


  const getProjects = async (dev_id, project_id, init = true, filter = '') => {
    try {
      if (init) {
        setloadDevs(true);
        setProjects([]);
      }

      const projects = (
        await ObexRequestHandler.get(`/dev_auth_service_proj?dev_id=${dev_id}&project_id=${project_id}`, {}, true)).data || [];

      const ids_duplicated = []

      projects.map((pro) => {
        if (pro.permissions == 0 || pro.permissions == 2) {
          ids_duplicated.push(pro);
        }
      })
      let projects3 = [];
      let duplicado;

      projects.map((pro1) => {
        duplicado = false;
        ids_duplicated.map((pro2) => {
          if (pro1.id == pro2.id && pro1.permissions == 1) { duplicado = true; console.log("duplicado id:" + pro1.id); }
        })
        if (filter != '') {
          if (!duplicado && pro1.name.toString().includes(filter)) {
            projects3.push(pro1);
            console.log("pushed filter. " + pro1.id + ", permiso: " + pro1.permissions);
          }

        } else {
          if (!duplicado) {
            projects3.push(pro1);
            console.log("pushed. " + pro1.id + ", permiso: " + pro1.permissions);
          }

        }
      })

      /*
      ids_duplicated.map((pre) =>{
       console.log(pre);
        project2 =  project2.filter(function(item) {
          if (item.id == pre.id && pre.permissions == false) {} else return item
      })
      })
      */



      //console.log(projects3);
      setProjects(projects3);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setloadDevs(false);

    }
    setloadDevs(false);
    if (!init && yx != null) yx.hidden = true;
  }

  const getCurrencies = async (project_id) => {
    /*
    try {
      setLoading(true);
      const result = (await ObexRequestHandler.get(`/projects/${projectSelected}/currencies`, { all: false })).data || {};
      const sortCurrencies = result.sort(sortByCurrencyName).map(c => {
        const { cambio_venta, cambio_compra, cambio_referencia } = c;
        c.cambio_venta = parseFloat(cambio_venta).toFixed(2);
        c.cambio_compra = parseFloat(cambio_compra).toFixed(2);
        c.cambio_referencia = parseFloat(cambio_referencia).toFixed(2);
        return c; 
      })
      setCurrencies(sortCurrencies)
      setSubmitDisabled(false)
    } catch (error) {
      console.error('ERROR FETCHING CURRENCIES ', error);
    }
    */

    // console.log(`CAMBIO DE PROYECTO ${project_id} para DEV ${developer_id}`);
    setSelectedProjectId(project_id);
    getProjects(developer_id, project_id);

  }

  const sendInvitation = async () => {

    if (developer != '') {
      getProjects(developer_id, selectedProjectId, false, developer)
    } else {
      getProjects(developer_id, selectedProjectId, false)
    }

    /*
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      console.log(ActualPlan)
      const user = cookies.get('101Obex');
      console.log(user);
      if (ActualPlan==1 && developer!=user.email) {

        history.push('/plan_subcription/upgrade_plan');}

      if (developer.length>5 && developer.includes('@') && developer.includes('.')) {
      if (UsedMembers<=(MaxMembers)) {
              const payload = { email: developer, organization: SessionService.ORGANIZATION };
              const result = await ObexRequestHandler.post('/invite_developer', payload);
              const { success, message } = result;
              setDeveloper('');
              if (!success) throw { message };
              getProjects();
            } else {
              history.push('/plan_subcription/upgrade_plan');
            }
          } else {

          }
    } catch (error) {
      console.error('ERROR WHILE CHANGING PASSWORD ', error);
      setLoading(false);
    }
    setLoading(false);
*/
  }

  const changeDeveloper = (e) => {
    //const newDeveloper = e.target.value;
    setDeveloper(e.target.value);
  }

  const getSuscription = async () => {

    const result = await ObexRequestHandler.get('/purchased_services');
    const { success, data } = result;
    if (success) {
      setActualPlan(data.subscription.subscription_id);

    }

  }


  useEffect(() => {
    getProjectstoSel();

    // getSuscription();



  }, []);


  const handleProjectSelected = (e, init = false) => {
    e.preventDefault();
    e.stopPropagation();
    setProjectSelected(e.target.value);

    let new_project_id = 0;
    Projec_Sel.map((project) => {

      if (project.name == e.target.value) {
        // console.log(`=> ${project}`);
        new_project_id = project.id;
      }

    });
    getCurrencies(new_project_id);
    if (!init) setLoading(false);
  }




  const routerLink = (url) => {
    const pushData = projects
      ? { state: { projects } }
      : {};
    history.push(url, pushData);
  }

  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'developers'} sublinks={[]}>
          <Row style={{ marginTop: '0px' }}>
            <Col className="" style={{ width: '100%' }}>
              <Row style={{ marginTop: '0px' }}>
                <div className="textBold" style={{ width: '70%', marginBottom: '2rem' }}><h2>Priviliges for <b>developer {developer_id}</b></h2></div>
              </Row>

              {/*
        <Row style={{ marginTop:'40px' }}>
        <h1>Organization <b>{SessionService.ORGANIZATION_NAME}</b> Developers</h1>

       </Row>
  */}

              <Row>
                <Col style={{ marginLeft: '-15px' }}>

                  {loadDevs &&

                    <Row style={{ textAlign: 'center' }}>
                      <Col>
                        <Spinner animation="border" style={{ marginLeft: '8px' }} />
                      </Col>
                    </Row>
                  }
                  <Row style={{ marginTop: '0px' }}>
                    <Col style={{ maxWidth: '140px' }}>
                      <h1>Project</h1>
                    </Col>
                    <Col>
                      <Form className="obex-form">
                        <Form.Group>
                          <Form.Control as="select" onChange={handleProjectSelected} custom >
                            {projectstoSel}
                          </Form.Control>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>

                  <Form.Row >
                    <Col md="9" style={{ marginBottom: '20px' }}>
                      <Form.Control required type="text" value={developer} placeholder="Filter"
                        onChange={changeDeveloper} />
                    </Col>

                    <Col md={3}>
                      <Button disabled={loading} className="custom-button-blue w-100 textBold px-4"
                        onClick={() => { sendInvitation() }}>Filter
                        {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                      </Button>
                    </Col>
                  </Form.Row>


                  {!loadDevs &&
                    <Col>
                      <Table responsive className="obex-projects-table" style={{ paddingLeft: '10px' }}>
                        <thead style={{
                          fontFamily: 'Regular Intro Bold',
                          textAlign: 'start',
                          backgroundColor: 'transparent',
                          color: 'black',
                          borderWidth: '1px',
                          borderRadius: '15px',
                          borderStyle: 'hidden'
                        }}>
                          <tr>
                            <th className='textBold'>Description</th>
                            <th className='textBold'>Status</th>
                            <th className='textBold'></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr></tr>
                          {projects.map(project => (
                            project.type == 'service' ?
                              project.permissions ?

                                <tr key={project.id} style={{ borderTopStyle: 'hidden' }}>
                                  <td>{project.name}</td>
                                  <td>{project.permissions ? 'Active' : 'Inactive'}</td>
                                  <td className="text-right">
                                    <a className="edit-link" onClick={() => {
                                      togglePrivilege(developer_id, selectedProjectId, project.rule_id, true);
                                      // console.log(`${developer_id} ${selectedProjectId} ${project.rule_id}`)
                                    }}>
                                      <Row>
                                        <FontAwesomeIcon icon={faToggleOn} size="2x" className="mr-1" /><div style={{ minWidth: '30px', maxWidth: '30px' }}><Spinner id={`spin_on_${project.rule_id}`} size="sm" hidden={true} animation="border" style={{ marginLeft: '8px', marginTop: '6px' }} /></div></Row></a>

                                  </td>
                                </tr> :
                                <tr key={project.id} style={{ borderTopStyle: 'hidden', color: 'grey', fontStyle: 'oblique' }}>
                                  <td>{project.name}</td>
                                  <td>{project.permissions ? 'Allowed' : 'Disallowed'}</td>
                                  <td className="text-right">
                                    <a className="edit-link" onClick={() => {
                                      togglePrivilege(developer_id, selectedProjectId, project.rule_id, false);
                                      // console.log(`${developer_id} ${selectedProjectId} ${project.rule_id}`)
                                    }}>
                                      <Row>
                                        <FontAwesomeIcon icon={faToggleOff} size="2x" className="mr-1" /><div style={{ minWidth: '30px', maxWidth: '30px' }}><Spinner id={`spin_off_${project.rule_id}`} size="sm" hidden={true} animation="border" style={{ marginLeft: '8px', marginTop: '6px' }} /></div></Row></a>

                                  </td>
                                </tr>
                              :
                              project.permissions > 0 ?
                                project.permissions > 1 ?
                                  <tr key={project.id} style={{ borderTopStyle: 'hidden' }}>
                                    <td>{project.name}</td>
                                    <td>{project.permissions ? 'Active - Write' : 'Inactive - Write'}</td>
                                    <td className="text-left">
                                      <Row>
                                        <div style={{ minWidth: '100px', maxWidth: '100px' }}>
                                          <Select id={`spin_on_${project.rule_id}`} options={apiOps} defaultValue={apiOps[project.permissions].label} aria-label="Default select example" onChange={(value) => {
                                            console.log("value select: " + value)
                                            switch (value) {
                                              case "0":
                                                toggleReadWritePrivilege(developer_id, selectedProjectId, project.rule_id)
                                                togglePrivilege(developer_id, selectedProjectId, project.rule_id, true);
                                                break;
                                              case "1":
                                                toggleReadWritePrivilege(developer_id, selectedProjectId, project.rule_id)
                                                break;
                                              case "2":
                                                break;
                                            }
                                          }}>
                                          </Select>
                                        </div>
                                      </Row>
                                    </td>
                                  </tr>
                                  :
                                  <tr key={project.id} style={{ borderTopStyle: 'hidden' }}>
                                    <td>{project.name}</td>
                                    <td>{project.permissions ? 'Active - Read' : 'Inactive - Read'}</td>
                                    <td className="text-left">
                                      <Row>
                                        <div style={{ minWidth: '100px', maxWidth: '100px' }}>
                                          <Select id={`spin_on_${project.rule_id}`} options={apiOps} defaultValue={apiOps[project.permissions].label} aria-label="Default select example" onChange={(value) => {
                                            console.log("value select: " + value)
                                            switch (value) {
                                              case "0":
                                                togglePrivilege(developer_id, selectedProjectId, project.rule_id, true);
                                                break;
                                              case "1":
                                                break;
                                              case "2":
                                                toggleReadWritePrivilege(developer_id, selectedProjectId, project.rule_id)
                                                break;
                                            }
                                          }}>
                                          </Select>
                                        </div>
                                      </Row>
                                    </td>
                                  </tr>
                                :
                                <tr key={project.id} style={{ borderTopStyle: 'hidden', color: 'grey', fontStyle: 'oblique' }}>
                                  <td>{project.name}</td>
                                  <td>{project.permissions ? 'Allowed' : 'Disallowed'}</td>
                                  <td className="text-left">
                                    <Row>
                                      <div style={{ minWidth: '100px', maxWidth: '100px' }}>
                                        <Select id={`spin_off_${project.rule_id}`} options={apiOps} defaultValue={apiOps[project.permissions].label} aria-label="Default select example" onChange={(value) => {
                                          console.log("value select: " + value)
                                          switch (value) {
                                            case "0":
                                              console.log(" CASE 0")
                                              togglePrivilege(developer_id, selectedProjectId, project.rule_id, false);
                                              break;
                                            case "1":
                                              console.log(" CASE 1")
                                              togglePrivilege(developer_id, selectedProjectId, project.rule_id, false);

                                              break;
                                            case "2":
                                              console.log(" CASE 2")
                                              togglePrivilege(developer_id, selectedProjectId, project.rule_id, false);
                                              toggleReadWritePrivilege(developer_id, selectedProjectId, project.rule_id);


                                              break;
                                          }
                                        }}>
                                        </Select>
                                      </div>
                                    </Row>
                                  </td>
                                </tr>

                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>

  )
}

export default Privileges;


