import React, { FunctionComponent, useState, useEffect } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Table, Container, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../../services/AmountService';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../mobileComponents/Disclaimer';

type BalanceProps = {

}


const BalanceContent: FunctionComponent<BalanceProps> = (props) => {

  const [payments, setPayments] = useState([]);
  const [stillLoaded, setStillLoaded] = useState(true);

  const getPayments = async () => {

    try {
      const result = await ObexRequestHandler.get('/payments');
      const { success, data } = result;
      if (success) {
        setStillLoaded(false);
        setPayments(data);

      } else {
        console.log('Cannot get the account settings information');
        setStillLoaded(false);
      }
    } catch (error) {
      console.error('ERROR FETCHING ACCOUNT SETTINGS ', error);
      setStillLoaded(false);
    }
  }

  useEffect(() => {
    getPayments()
  }, [])

  return (
    <>
      <BrowserView>
        <DashLayout active="balance" sider={DashRouters}>

          {stillLoaded ?

            <Container className="vh-100 d-flex flex-column vw-100">
              <Row className="h-100" >


                <Col md="1" className="mx-auto d-flex align-items-center">
                  <Spinner animation="border" />
                </Col>

              </Row>
            </Container>
            :

            <Row>
              <Col>
                <Table responsive>
                  <thead style={{ fontFamily: 'Regular Intro Bold' }}>
                    <tr>
                      <th className='textBold'>Type</th>
                      <th className='textBold'>NET</th>
                      <th className='textBold'>Amount</th>
                      <th className='textBold'>FEE</th>
                      <th className='textBold'>Description</th>
                      <th className='textBold'>Available on</th>
                      <th className='textBold'></th>
                    </tr>
                  </thead>
                  <tbody>

                    {payments.map((item, index) => {
                      if (item.description != "Add balance to the account.") return null
                      return (
                        <tr>
                          <td>Add Balance</td>
                          <td>({parseToCoin(item.amount)})</td>
                          <td>{parseToCoin(item.amount)}</td>
                          <td> - </td>
                          <td>{item.description}</td>
                          <td>{item.date}</td>
                        </tr>);
                    }
                    )}

                  </tbody>
                </Table>
              </Col>
            </Row>
          }
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>

  )
}

export default BalanceContent;