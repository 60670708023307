import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Spinner, Alert } from 'react-bootstrap';
import OpenBanking from '../../assets/img/openbankingsdk.png';
import LogoPie from '../../assets/img/logo-pie.png';
import FlechaArriba from '../../assets/img/flechaarriba.png';
import FlechaAbajo from '../../assets/img/flachaabajo.png';
import WarningInfo from '../../assets/img/warninginfo.png'
import Cerrar from '../../assets/img/closeicon.png'
import VerificationNotice from './VerificationNotice';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';

import './style.css';

import crypto from 'crypto';
import axios from 'axios';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';


type LoginFormProps = {
  Token?: string
}

const LoginOpenBankingForm: FunctionComponent<LoginFormProps> = (props: LoginFormProps) => {

  const {
    Token
  } = props;

  const [id_origen, setIdOrigen] = useState(0);
  const [redirect_link_base, setRedirectLinkBase] = useState('');
  const [redirect_link_base_error, setRedirectLinkBaseError] = useState('');



  const [redirect_link, setRedirectLink] = useState('');
  const [finalized, setFinalized] = useState(false);
  const [integration, setIntegration] = useState('belvo');
  const [institutionId, setInstitutionId] = useState('');

  const [accesing, setAccessing] = useState(true);

  const [placeUsername, setplaceUsername] = useState('Email address');
  const [placePassword, setplacePAssword] = useState('Password');

  const [placeUsernameRegex, setplaceUsernameRegex] = useState('^[0-9]{1,15}$');
  const [placePasswordRegex, setplacePAsswordRegex] = useState('^.{1,}$');

  const [mail, setMail] = useState('');
  const [developer, setDeveloper] = useState(false);
  const [password, setPassword] = useState('');
  const [signining, setSignining] = useState(false);
  const [error, setError] = useState('');

  const [enLogin, setEnLogin] = useState(false)

  const [permisos, setPermisos] = useState([]);

  const [bank_name, setBankName] = useState('Belvo');
  const [showInfo, setShowInfo] = useState(false);

  const [success, setSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showRecovery, setShowRecovery] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(true);
  const handleCloseRecovery = () => setShowRecovery(false);
  const handleShowRecovery = (e) => {
    e.preventDefault();
    setShowRecovery(true);
  };

  const history = useHistory();

  function routeDashboard() {
    history.push({
      pathname: '/cloud_dashboard'
    })
  }

  function routeDevDashboard() {
    history.push({
      pathname: '/devdashboard'
    })
  }

  function routeHome() {
    const path = '/login';
    window.location.href = path;
  }

  function routePlans() {
    const path = 'https://www.101obex.com/pricing';
    window.location.href = path;
  }

  const redirectPage = async (signin) => {


    //SessionService.ORGANIZATION = 8;
    if (signin.developer) {
      routeDevDashboard();
    } else {
      const projects = await ObexRequestHandler.get('/projects');
      if (!projects.success && projects.status == 403) {
        SetStillLoaded(false);
        handleShow();

      } else {
        routeDashboard();
      }
    }
  }

  const handleSubmitSignip = async (event) => {
    event.preventDefault();
    try {
      setError('');
      setSignining(true);
      const signin = await SessionService.login(mail, password, developer);
      setSuccess(true);
      // const sessionExpiration = signin.secondsTokenExpiration
      redirectPage(signin);
    } catch (error) {

      setError(error.message || 'An error was ocurred while sigining.');
      SetStillLoaded(false);
      setTimeout(function () {
        SetStillLoaded(false);
      }.bind(this), 2000)
      setTimeout(function () {
        setError('');
      }.bind(this), 4000)

    }
    setSubmitDisabled(false);
    setSignining(false);
    SetStillLoaded(true);
  };


  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    routePlans();
  };


  const changeMail = (e) => {


    if (e.target.value.match(placeUsernameRegex) == null) {
      e.target.value = mail;
    } else {
      setMail(e.target.value);
    }

  }

  const changeAcces = (e) => {

    setDeveloper(!developer);

  }

  const changePass = (e) => {
    if (e.target.value.match(placePasswordRegex) == null) {
      e.target.value = password;
    } else {
      setPassword(e.target.value);
    }
  }



  const accessAccount = async () => {

    if (integration == 'belvo') {

      const path = 'https://sandbox.belvo.com/api/links';
      const options = {
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization':'Basic 388b35c3-6462-4e5e-af39-d0acb816be41:K_UDH1syAzzaKQXM_*nk_GRA8qTaz-XJtCjNQltzH5-yGJvBaUGCmtVELTaVstX-'
        }

        ,
        auth: {
          username: '388b35c3-6462-4e5e-af39-d0acb816be41',
          password: 'K_UDH1syAzzaKQXM_*nk_GRA8qTaz-XJtCjNQltzH5-yGJvBaUGCmtVELTaVstX-'
        }

      }
      const data = {
        institution: institutionId,
        username: mail,
        password: password,
      }
      console.log(data);

      const result = (await axios.post(path, data, options));

      if ((result.data as Object).hasOwnProperty('id')) {

        console.log(result);

        setFinalized(true);
        setAccessing(true);

        setRedirectLink(redirect_link_base + '?id_origen=' + id_origen + '&error=Cant%20Connet' + '&auth_token=' + result.data.id)

      } else {

        setError('LAS CREDENCIALES SON ERROREAS');
        setAccessing(true);
        setRedirectLink(redirect_link_base + '?id_origen=' + id_origen + '&error=Cant%20Connet' + '&details=Cant%20Connect')
        setFinalized(true);

      }

      console.log(result.data.id);

    }

  }


  const getTokenInfo = async (token) => {
    console.log(`TOKEN A CONSULTAR ${token}`);
    const projects = await ObexRequestHandler.get(`/open_banking_tokens/${token}`);
    console.log(`RESULTADO ${projects}`);
    console.log(projects);

    setBankName(projects['data']['display_name']);
    setPermisos(projects['data']['resources']);
    setIntegration(projects['data']['entry']['integration'])
    setInstitutionId(projects['data']['entry']['institution_id'])
    setRedirectLinkBase(projects['data']['entry']['redirect']);

    setRedirectLinkBaseError(projects['data']['entry']['redirect'] + '?id_origen=' + projects['data']['entry']['id_origen'] + '&error=Cant%20Connet' + '&details=Cant%20Connect')

    setIdOrigen(projects['data']['entry']['id_origen']);

    projects['data']['form_fields'].forEach(element => {

      if (element['name'] == 'username') {
        setplaceUsername(element['label']);
        setplaceUsernameRegex(element['validation']);
      }
      if (element['name'] == 'password') {
        setplacePAssword(element['label']);
        setplacePAsswordRegex(element['validation']);
      }

    });


    SetStillLoaded(false);
  }

  useEffect(() => {
    console.log(Token)


    const secret = 'abcdefg';

    const hash = crypto.createHmac('sha256', secret)
      .update('I love cupcakes')
      .digest('hex');
    //console.log(hash);

    getTokenInfo(Token);


  }, [])



  return (


    <>
      <BrowserView>
        <Container fluid className="text-center d-flex flex-column min-vh-100" style={{ backgroundColor: '#eaeaea' }}>
          {stillLoaded ?
            <Container className="vh-100 d-flex flex-column vw-100" >
              <Row className="h-80" style={{ minHeight: '80vh', justifyContent: 'center' }}>
                <Row className="h-20" style={{ minHeight: '20vh', marginTop: '10%', maxWidth: '500px' }}>
                  <Col style={{ backgroundColor: '#ffffff', borderRadius: '15px' }}>
                    <Row style={{ justifyContent: 'center', marginTop: '10%' }}>
                      <div><h3>Redirecting to 101OBeX</h3></div>
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                      <div><h3>for autorization</h3></div>




                    </Row>
                    <Row style={{ justifyContent: 'center', marginTop: '10%' }}>
                      <Col style={{ maxWidth: '100%' }}>
                        <Image src={OpenBanking} style={{
                          maxWidth: '50%'
                        }} />
                      </Col>
                    </Row>



                    <Row className="h-10" style={{ justifyContent: 'center', marginTop: '10%' }} >
                      <Col >
                        <Spinner animation="border" />
                      </Col>
                    </Row>

                    <Row className="h-10" style={{ justifyContent: 'center', marginTop: '10%', marginBottom: '10%' }} >
                      <Col >
                        <Image src={LogoPie} />
                      </Col>
                    </Row>


                  </Col>
                </Row>

              </Row>



            </Container>
            :

            <>



              {enLogin ?
                <div>

                  <Container className="vh-100 d-flex flex-column vw-100" >
                    {accesing ?
                      <Row className="h-80" style={{ minHeight: '80vh', justifyContent: 'center' }}>
                        <Row className="h-20" style={{ minHeight: '20vh', marginTop: '10%', maxWidth: '500px' }}>
                          <Col style={{ backgroundColor: '#ffffff', borderRadius: '15px' }}>


                            <Row style={{ justifyContent: 'end', marginLeft: '90%', marginTop: '5%' }}>
                              <Col style={{}}>
                                <Image style={{ maxWidth: '15px' }} src={Cerrar} onClick={
                                  async () => {
                                    //setAccessing(true);
                                    //setFinalized(true);
                                    history.push(redirect_link_base_error);

                                  }} />
                              </Col>
                            </Row>



                            <Row style={{ justifyContent: 'center', marginTop: '0%', marginLeft: '5%', marginRight: '5%' }}>
                              <div><h3>
                                <span style={{
                                  color: '#7abcb0'
                                }}>101OBeX
                                </span>
                                <span style={{
                                  color: '#000000'
                                }}>
                                  request access for your
                                </span>
                                <span style={{ color: '#7abcb0' }}>
                                  {bank_name}
                                </span>
                                <span style={{
                                  color: '#000000'
                                }}> information.</span></h3></div>
                            </Row>
                            <Row style={{ justifyContent: 'center' }}>
                              <Col style={{ justifyContent: 'center' }}>
                              </Col>
                            </Row>
                            {!finalized ?
                              <><Form className="obex-form" style={{ marginTop: '20%', marginBottom: '30%' }}>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Control required type="email" placeholder={placeUsername} onChange={changeMail} pattern="placeUsernameRegex" />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                  <Form.Control required type="password" placeholder={placePassword} onChange={changePass} />
                                </Form.Group>
                                {error.length > 0 && (
                                  <Alert variant='danger'>
                                    {error}
                                  </Alert>)}
                              </Form><Row style={{ justifyContent: 'center', marginTop: '10%' }}>
                                  <Col style={{ maxWidth: '100%' }}>
                                    <Row style={{
                                      marginLeft: '0px',
                                      marginRight: '0px',
                                      justifyContent: 'center',
                                      minHeight: '45px',
                                      paddingTop: '11px',
                                      color: '#ffffff',
                                      backgroundColor: '#7abcb0',
                                      borderRadius: '5px',
                                      fontSize: '17px'
                                    }}

                                      onClick={() => {


                                        setAccessing(false);

                                        accessAccount();

                                      }}

                                    >
                                      Access
                                    </Row>
                                  </Col>
                                </Row><Row className="h-10" style={{ justifyContent: 'center', marginTop: '5%', marginBottom: '5%' }}>
                                  <Col style={{ maxWidth: '5%', marginRight: '-30px' }}>
                                    <Image style={{ maxWidth: '15px' }} src={WarningInfo} />
                                  </Col>
                                  <Col style={{ textAlign: 'start', marginLeft: '30px' }}>
                                    Using this service and clicking "Accept" button, you accept the 101OBeX Terms and conditions, and the 101OBeX privacy policy.
                                  </Col>
                                </Row></>

                              :
                              <><Row style={{ justifyContent: 'center' }}>
                                <Col style={{ justifyContent: 'center', marginTop: '30%', marginBottom: '10%' }}>
                                  <h1>Access Granted</h1>
                                </Col>
                              </Row><Row style={{ justifyContent: 'center', marginTop: '10%' }}>
                                  <Col style={{ maxWidth: '100%' }}>
                                    <Row style={{
                                      marginLeft: '0px',
                                      marginRight: '0px',
                                      justifyContent: 'center',
                                      minHeight: '45px',
                                      paddingTop: '11px',
                                      color: '#ffffff',
                                      backgroundColor: '#7abcb0',
                                      borderRadius: '5px',
                                      fontSize: '17px'
                                    }}

                                      onClick={() => {

                                        history.push(redirect_link);

                                      }}

                                    >
                                      Ok
                                    </Row>
                                  </Col>
                                </Row></>


                            }


                          </Col>
                        </Row>

                      </Row>

                      :
                      <Row className="h-80" style={{ minHeight: '80vh', justifyContent: 'center' }}>
                        <Row className="h-20" style={{ minHeight: '20vh', marginTop: '10%', maxWidth: '500px' }}>
                          <Col style={{ backgroundColor: '#ffffff', borderRadius: '15px' }}>
                            <Row style={{ justifyContent: 'center', marginTop: '10%', marginLeft: '5%', marginRight: '5%' }}>
                              <div><h3>
                                <span style={{ color: '#7abcb0' }}>101OBeX </span>
                                <span style={{ color: '#000000' }}>request access for your </span>
                                <span style={{ color: '#7abcb0' }}>{bank_name}</span>
                                <span style={{ color: '#000000' }}> information.</span></h3></div>
                            </Row>
                            <Row style={{ justifyContent: 'center' }}>
                              <Col style={{ justifyContent: 'center' }}>



                                <Row className="h-10" style={{ justifyContent: 'center', marginTop: '30%' }} >
                                  <Col >
                                    <Spinner animation="border" />
                                  </Col>
                                </Row>



                              </Col>


                            </Row>

                          </Col>
                        </Row>

                      </Row>

                    }

                  </Container>


                </div>
                :
                <Container className="vh-100 d-flex flex-column vw-100" >
                  <Row className="h-80" style={{ minHeight: '80vh', justifyContent: 'center' }}>
                    <Row className="h-20" style={{ minHeight: '20vh', marginTop: '10%', maxWidth: '500px' }}>

                      <Col style={{ backgroundColor: '#ffffff', borderRadius: '15px' }}>

                        <Row style={{ justifyContent: 'end', marginLeft: '90%', marginTop: '5%' }}>
                          <Col style={{}}>
                            <Image style={{ maxWidth: '15px' }} src={Cerrar} onClick={
                              async () => {
                                setAccessing(true);
                                setFinalized(true);
                                history.push(redirect_link_base_error);

                              }} />
                          </Col>
                        </Row>



                        <Row style={{ justifyContent: 'center', marginTop: '0%', marginLeft: '5%', marginRight: '5%' }}>
                          <div><h3>
                            <span style={{ color: '#7abcb0' }}>101OBeX </span>
                            <span style={{ color: '#000000' }}>request access for your </span>
                            <span style={{ color: '#7abcb0' }}>{bank_name}</span>
                            <span style={{ color: '#000000' }}> information.</span></h3></div>
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                          <Col style={{ justifyContent: 'center' }}>

                            {showInfo ?

                              <Row style={{ minWidth: '100%', minHeight: '40vh', maxHeight: '40vh' }}>
                                <Col style={{
                                  marginLeft: '15px',
                                  marginRight: '15px',
                                  borderStyle: 'solid',
                                  borderWidth: '1px',
                                  borderRadius: '7px',
                                  borderColor: 'lightgray', marginTop: '10%'
                                }}>

                                  <Row style={{ marginLeft: '00px', marginTop: '20px' }} onClick={() => { setShowInfo(!showInfo) }}>
                                    <Col style={{ minWidth: '80%', textAlign: 'start' }}>
                                      What info 101OBeX get
                                    </Col>
                                    <Col style={{ minWidth: '20%' }}>
                                      <Image style={{ maxWidth: '20px' }} src={FlechaArriba} />
                                    </Col>

                                  </Row>


                                  <hr></hr>
                                  {permisos.map(project => (
                                    <Row key={'loginopenbanking' + project.id} style={{ justifyContent: 'center' }}>
                                      <div>{project}</div>
                                    </Row>
                                  ))}
                                  <Row style={{ minHeight: '5%', marginBottom: '3%' }}><div></div></Row>
                                </Col>

                              </Row>
                              :



                              <Row style={{ minWidth: '100%', minHeight: '40vh', maxHeight: '40vh' }}>
                                <Col style={{ marginTop: '10%', marginLeft: '15px', marginRight: '15px' }}>

                                  <Row>
                                    <Col style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '7px', borderColor: 'lightgray' }}>
                                      <Row style={{ marginLeft: '00px', marginTop: '20px', marginBottom: '20px' }}
                                        onClick={() => { setShowInfo(!showInfo) }}>
                                        <Col style={{ minWidth: '80%', textAlign: 'start' }}>
                                          What info 101OBeX get
                                        </Col>
                                        <Col style={{ minWidth: '20%' }}>
                                          <Image style={{ maxWidth: '20px' }} src={FlechaAbajo} />
                                        </Col>

                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>




                              </Row>

                            }
                          </Col>


                        </Row>

                        <Row style={{ justifyContent: 'center', marginTop: '10%' }}>
                          <Col style={{ maxWidth: '100%' }}>
                            <Row style={{
                              marginLeft: '0px',
                              marginRight: '0px',
                              justifyContent: 'center',
                              minHeight: '45px',
                              paddingTop: '11px',
                              color: '#ffffff',
                              backgroundColor: '#7abcb0',
                              borderRadius: '5px',
                              fontSize: '17px'
                            }}

                              onClick={() => {


                                setEnLogin(true);

                              }}


                            >
                              Accept
                            </Row>
                          </Col>
                        </Row>



                        <Row className="h-10" style={{ justifyContent: 'center', marginTop: '5%', marginBottom: '5%' }} >
                          <Col style={{ maxWidth: '5%', marginRight: '-30px' }}>
                            <Image style={{ maxWidth: '15px' }} src={WarningInfo} />
                          </Col>
                          <Col style={{ textAlign: 'start', marginLeft: '30px' }}>
                            Using this service and clicking "Accept" button, you accept the 101OBeX Terms and conditions, and the 101OBeX privacy policy.
                          </Col>
                        </Row>




                      </Col>
                    </Row>

                  </Row>



                </Container>


              }


              <VerificationNotice
                mail={mail}
                show={show}
                handleClose={handleClose} />
              <Row className="mt-auto">
                <Col className="px-0">
                  {/*   <Footer /> */}
                </Col>
              </Row></>


          }
        </Container>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
}

export default LoginOpenBankingForm;