import RequestHandler from './RequestHandler';
import { url, port } from '../configs/appConfig.json';
import Cookies from 'universal-cookie';

type ObexHeaders = {
  Authorization:string,
  'disable-cache':boolean
}

class ObexRequestHandler {
  private static url = `${url}:${port}`;
  private static url_p2p = 

  url === 'https://avs-primary-pre.101obex.mooo.com' ? 
          'https://acl.p2p-pre.avap.mooo.com:3001' : 
          'https://acl.avapcloud.p2p.mooo.com:3001';

  public static cookies = new Cookies();

  private static getAuthToken() {
    const user = ObexRequestHandler.cookies.get('101Obex');
    // console.log('GET AUTH TOKEN');
    // console.log(user);
    if (!user) {return '';}
    const { token } = user;
    return `Bearer ${token}`;
  }
  private static getHeaders(disableCache = false): ObexHeaders { 
    const headers = { Authorization: '', 'disable-cache': disableCache };
    const token = this.getAuthToken();
    headers.Authorization = token;
    return headers;
  }

  public static async get(path: string, params?: object, disableCache = false): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders(disableCache);
      if (headers.Authorization==''){
        if ((!path.includes('/subscription')) &&
            (path!='/subscriptions') && 
            (path!='/extraprojects/plans') &&
            (path!='/extrarequests/plans') &&
            (path!='/countries') &&
            !(path.includes('validation')) &&
            !(path.includes('/open_banking_tokens')) &&
            !(path.includes('/request_reset_password')) &&
            !(path.includes('/reset_password')) &&
            !(path.includes('/resend_activation')) &&
            !(path.includes('/set_first_password')) &&
            !(path.includes('/accept_invitation')) &&
            !(path.includes('/accept_production')) &&
            !(path.includes('/confirm_client')) &&
            !(path.includes('/confirm_client')) &&
            !(path.includes('/check_credentials')) &&
            !(path.includes('/accept_change_production')) &&
            !(path.includes('/cancel_change_production')) &&
            !(path.includes('/set_first_password_client')) &&
            !(path.includes('/is_in_production')) &&
            !(path.includes('/developer_')) &&
            !(path.includes('/devs_account')) &&
            !(path.includes('/api_requests')) &&
            !(path.includes('/database_usage')) &&
            !(path.includes('/consumption')) &&
            !(path.includes('/current_subscription')) &&
            !(path.includes('/movements_stat')) &&
            !(path.includes('/movements_between')) &&
            !(path.includes('/active_support_plan')) &&
            !(path.includes('/projects')) &&
            !(path.includes('/plans')) &&
            !(path.includes('/pricing_plan')) &&
            !(path.includes('/check_renovated_suscription')) &&
            !(path.includes('/salesforce/register_neswletter')) &&
            !(path.includes('/get_authorization')) 
            ) {
              console.log(`PATH: ${path}`);
              window.location.replace('/cloud_login');}
        else {}

      }
       
      const result = await RequestHandler.get(url, params, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async post(path: string, payload?: object, p2p?: boolean): Promise<any> {
    try {
      const url = `${ p2p ? this.url_p2p : this.url }${path}`;
      const headers = this.getHeaders();


      if (headers.Authorization==''){
        if ((path!='/signin')  && 
            (path!='/unique_signin')  && 
            (path!='/signup') &&
            (path!='/resend_validation') &&
            (path!='/checkpayment') &&
            (path!='/checkauthsetup') &&
            (path!='/resend_validation') &&
            (path!='/request_reset_password') &&
            (path!='/reset_password') &&
            (path!='/resend_activation') &&
            !(path.includes('/developer_')) &&
            (path!='/finish_signup') &&
            (path!='/signup_avapid') &&
            (path!='/resend_validation_AvapID') &&
            !(path.includes('/salesforce/register_neswletter')) &&
            (path!='/signup_avapcloud')
            
            ) {
              console.log(`PATH: ${path}`);
              window.location.replace('/cloud_login');}
        else {}
      }


      const result = await RequestHandler.post(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async put(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.put(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async patch(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.patch(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async delete(path: string, params?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.delete(url, params, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(delete)', error)
    }
  }

}

export default ObexRequestHandler;