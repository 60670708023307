import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Button, Form, Table, Spinner, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';

import Cookies from 'universal-cookie';
import UpgradePlanModal from '../Modals/UpgradePlanModal';
import editicon from '../../assets/img/icon_edit.png';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

const cookies = new Cookies();

type IncomeProps = {
};


const DeveloperContent: FunctionComponent<IncomeProps> = ({ }) => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [developer, setDeveloper] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadDevs, setloadDevs] = useState(false);
  const [UsedMembers, setUsedMembers] = useState(0);
  const [MaxMembers, setMaxMembers] = useState(0);
  const [ActualPlan, setActualPlan] = useState(0);
  const [showUpgrade, setshowUpgrade] = useState(false)
  const [currentSuscripcion, setcurrentSuscripcion] = useState(0);


  const getProjects = async () => {
    try {

      setloadDevs(true);

      setProjects([]);

      const suscripcion = await ObexRequestHandler.get('/current_subscription', {}, false);

      const { data } = suscripcion;

      const max_members_allow = (data.max_members_slots);
      const active_slots_members = (data.active_slots_members);

      setMaxMembers(max_members_allow);
      setUsedMembers(active_slots_members);


      const projects = (
        await ObexRequestHandler.get(`/organization_devs?organization=${SessionService.ORGANIZATION}`, {}, true)).data || [];


      setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setloadDevs(false);

    }
    setloadDevs(false);
  }

  const whenClosing = () => {
    setshowUpgrade(false);

  }

  const sendInvitation = async (e) => {

    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      console.log(ActualPlan)
      const user = cookies.get('101Obex');
      console.log(user);
      if (ActualPlan == 1 && developer != user.email) {
        setshowUpgrade(true);
        {/*history.push('/plan_subcription/upgrade_plan');*/ }
        setLoading(false);
        return;
      }

      if (developer.length > 5 && developer.includes('@') && developer.includes('.')) {
        if (UsedMembers <= (MaxMembers)) {
          const payload = { email: developer, organization: SessionService.ORGANIZATION };
          const result = await ObexRequestHandler.post('/invite_developer', payload);
          const { success, message } = result;
          setDeveloper('');
          if (!success) throw { message };
          getProjects();
        } else {
          history.push('/plan_subcription/upgrade_plan');
        }
      } else {

      }
    } catch (error) {
      console.error('ERROR WHILE CHANGING PASSWORD ', error);
      setLoading(false);
    }
    setLoading(false);

  }

  const changeDeveloper = (e) => {
    const newDeveloper = e.target.value;
    setDeveloper(newDeveloper);
  }

  const getSuscription = async () => {

    const result = await ObexRequestHandler.get('/purchased_services');
    const { success, data } = result;
    if (success) {
      setActualPlan(data.subscription.subscription_id);

    }

  }


  useEffect(() => {
    window.scrollTo(0, 0);
    getProjects();
    getSuscription();



  }, []);


  const routerLink = (url) => {
    const pushData = projects
      ? { state: { projects } }
      : {};
    history.push(url, pushData);
  }

  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'developers'} sublinks={[]}>
          <Row>
            <Col>
              <Row style={{ marginBottom: '2rem' }}>
                <h2 className='textBold'>Add new Developer to <b>organization {SessionService.ORGANIZATION_NAME}</b></h2>

              </Row>
              <Form.Row >
                <Col md="9" style={{ marginLeft: '-15px' }}>
                  <Form.Control required type="text" value={developer} placeholder="Developer email"
                    onChange={changeDeveloper} />
                </Col>

                <Col md={3}>
                  <Button disabled={loading} className="custom-button-blue w-100 textBold px-4"
                    onClick={() => { sendInvitation(event) }}>Send Invitation
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  </Button>
                </Col>
              </Form.Row>
              <Row style={{ marginTop: '40px' }}>
                <h1>Organization <b>{SessionService.ORGANIZATION_NAME}</b> Developers</h1>

              </Row>


              <Row>
                <Col style={{ marginLeft: '-20px' }}>

                  {loadDevs &&

                    <Row style={{ textAlign: 'center' }}>
                      <Col>
                        <Spinner animation="border" style={{ marginLeft: '8px' }} />
                      </Col>
                    </Row>
                  }

                  {!loadDevs &&
                    <div style={{ borderStyle: 'solid', borderColor: '#ededee', borderWidth: '1px', borderRadius: '7px' }}>
                      <Table responsive>

                        <thead style={{ fontFamily: 'Regular Intro Bold', textAlign: 'start', backgroundColor: 'transparent', color: '#595959', borderBottomColor: '#ededed', borderBottomStyle: 'solid', borderBottomWidth: '2px' }}>
                          <div style={{ paddingTop: '10px', fontSize: '1.2vw', height: '50px', paddingLeft: '15px' }}>Developer list</div>
                        </thead>

                        <tbody>
                          <tr style={{ color: '#595959' }}>
                            <th className='textBold'>Developer Email</th>
                            <th style={{ textAlign: 'center' }} className='textBold'># Of teams</th>
                            <th style={{ textAlign: 'center' }} className='textBold'>Status</th>
                            <th style={{ textAlign: 'end' }} className='textBold'>Action</th>
                          </tr>

                          {projects.map(project => (

                            project.acepted ?

                              <tr key={project.id} style={{}}>
                                <td>{project.email}</td>
                                <td style={{ textAlign: 'center' }}>{project.dev_teams}</td>
                                <td style={{ textAlign: 'center' }}>{project.acepted ? 'Active' : 'Invited'}</td>
                                <td style={{ textAlign: 'end' }} className="text-right">
                                  Edit
                                  <a className="edit-link" onClick={() => routerLink(`/privileges/${project.dev_id}`)}>
                                    <Image src={editicon} style={{ marginBottom: '', marginTop: '-5px', paddingLeft: '10px' }} width="30px" alt="Logo" /></a>
                                </td>
                              </tr> :
                              <tr key={project.id} style={{ borderTopStyle: 'hidden', color: 'grey', fontStyle: 'oblique' }}>
                                <td style={{ textAlign: 'center' }}>{project.email}</td>
                                <td style={{ textAlign: 'center' }}>{project.dev_teams}</td>
                                <td style={{ textAlign: 'center' }}>{project.acepted ? 'Active' : 'Invited'}</td>
                                <td style={{ textAlign: 'end' }} className="text-right">
                                  Edit
                                  <a className="edit-link" onClick={() => routerLink(`/privileges/${project.dev_id}`)}>
                                    <Image src={editicon} style={{ marginBottom: '', marginTop: '-5px', paddingLeft: '10px' }} width="30px" alt="Logo" /></a>
                                </td>
                              </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  }
                </Col>
              </Row>
            </Col>
          </Row>

          <UpgradePlanModal
            showModal={showUpgrade}
            text={'In order to add a new project you need to upgrade your plan. \n You have consumed the maximun slots available.'}
            onCloseModal={whenClosing}
            onCancelModal={whenClosing}
            currentSuscription={currentSuscripcion}
          />


        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>

  )
}

export default DeveloperContent;


