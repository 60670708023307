import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import VerifyError from './VerifiedError';
import VerifySuccess from './VerifiedSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../../../assets/img/logo.png';
import { useParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';


const AccountVerified: FunctionComponent<{}> = () => {

  const [verified, setVerified] = useState(false);
  const [username, setUsername] = useState('');
  const [finished, setFinish] = useState(false);
  const { key } = useParams();


  const getVerified = async () => {

    const result = await ObexRequestHandler.get(`/validation/${key}`);
    const { success, data } = result;
    if (success) {
      const { username } = data;
      setUsername(username);
      setVerified(true);
      setFinish(true);
    }
  }

  useEffect(() => {
    getVerified();
  }, []);


  return (
    <>
      <BrowserView>
        <Container fluid>
          <VerifySuccess username={'username'} />
          {/* {!finished
        ? <Fragment>Loading...</Fragment>
        :  <Fragment>
            {verified
            ? <VerifySuccess username={username}/> 
            : <VerifyError/>}
          </Fragment>
      } */}


        </Container>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}


export default AccountVerified;