import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Row, Tooltip } from 'react-bootstrap';
import ConsumptionCard from './ConsumptionCard';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import AddProjectModalSel from '../../Modals/addProjectModalSelect';
import AddProjectModal from '../../Modals/addProjectModal';
import AddTxSelModal from '../../Modals/addTxModalSelect';
import AddStorageModal from '../../Modals/addStorageModal';
import AddBalanceModalSelection from '../../Modals/addBalanceModalSelection';
import AddDevsModalSel from '../../Modals/addDevelopersModalSelect';
import AddIncomeModalSelection from '../../Modals/addIncomeModalSelection';
import { amountToEur, parseToNumberFormat } from '../../../services/AmountService';
import UpgradePlanModal from '../../Modals/UpgradePlanModal';
import AddDeveloperModalSel from '../../Modals/addDevelopersModalSelect';
import AddDeveloperModal from '../../Modals/addDeveloperModal';
import SessionStorageHandler from '../../../handlers/SessionStorageHandler';
import SessionService from '../../../services/SessionsService';


const Consumptions:FunctionComponent<{}> = () => {

    const [slotsInfo, setSlots] = useState({
        allowProjectsBySubscription: 0,
        maxAllowProjecs: 0,
        maxExtraProjects: 0,
        extraPurchasedProjects: 0,
        activeProjects: 0,
        totalSlots: 0,
        availableSlots: 0,
        activeUsers : 0,
        maxAllowUsers: 0
    });
    const [txInfo, setTx] = useState({  
      limit: 0,
      current: 0,
      rest: 0,
      projects: {}
    });




    document.addEventListener('updateDashboard', (e) => {

      e.preventDefault();
      e.stopPropagation();

      getData();
      getVerified();

      if(slotsInfo.activeProjects < slotsInfo.maxAllowProjecs){
        setAvailableAdd(true);
      }else{
        setAvailableAdd(false);
      }
  
    }, false);

    const [possibleProjectsToBuy, setPossibleProjectsToBuy] = useState(0);
    const [possibleDevelopersToBuy, setPossibleDevelopersToBuy] = useState(0);
    const [possibleSlotsToBuy, setSlotsToBuy] = useState(0);
    const [addingProjects, setAddingProjects] = useState(false);

    const [addingDevelopers, setAddingDevelopers] = useState(false);

    const [addingTx, setAddingTx] = useState(false);
    const [addingBalance, setAddingBalance] = useState(false);
    const [removingIncome, setRemovingIncome] = useState(false);
    const [balance, setBalance] = useState('€0,00');
    const [income, setIncome] = useState('€0,00');
    const [loadingProject, setLoadingProject] = useState(true);
    const [loadingTx, setLoadingTx] = useState(true);
    const [loadingBalance, setLoadingBalance] = useState(true);
    const [loadingIncome, setLoadingIncome] = useState(true);
    const [availableAdd, setAvailableAdd] = useState(false);

    const [availableAddDev, setAvailableAddDev] = useState(false);

    const [loadingStorage, setLoadingStorage] = useState(true);
    const [addingStorage, setAddingStorage] = useState(false);
    const [storageUsed, setStorageUsed] = useState('-');
    const [storageLimit, setStorageLimit] = useState('-');
    const [storageHelper, setStorageHelper] = useState(false);
    const [currentSuscription, SetCurrentsuscripcion] = useState(0);
    const [maxTX, setMaxTX] = useState(3100);
    const [actualTX, setActualTX] = useState(0)
    const [MaxDBSize, setMaxDBSize] = useState(0);
    const [ActualDBSize, setActualDBSize] = useState(0);
    const [UsedMembers, setUsedMembers] = useState(0);
    const [ActualMembers, setActualMembers] = useState(0);
    const [MaxMembers, setMaxMembers] = useState(0);
    const [DeveloperMultiplier, setDeveloperMultiplier] = useState(0);

    const [BlockedAdd, setBlockedAdd] = useState(false);
    const [susinfo, setSusInfo] = useState()


    const handleAddProjects = () => {
      const restOfPossibleProjectToBuy = slotsInfo.maxExtraProjects - slotsInfo.extraPurchasedProjects;
      setPossibleProjectsToBuy(restOfPossibleProjectToBuy);
      setAddingProjects(!addingProjects);

    }

    const handleAddDevelopers = () => {
      const restOfPossibleDevelopersToBuy = MaxMembers - UsedMembers;
      setPossibleDevelopersToBuy(restOfPossibleDevelopersToBuy);
      setAddingDevelopers(!addingDevelopers);
    }


    const handleAddTx = () => setAddingTx(!addingTx);
    const handleAddStorage = () => setAddingStorage(!addingStorage);
    const handleAddBalance = () => setAddingBalance(!addingBalance);
    const handleRemoveIcome = () => setRemovingIncome(!removingIncome);
    

    const projectsCard = <ConsumptionCard NoPayment={BlockedAdd} title='API Projects' plus={true} value={`${slotsInfo.activeProjects}/${slotsInfo.maxAllowProjecs}${susinfo == 1 ? '/5':'/10'}`} action={handleAddProjects} loading={loadingProject} available={availableAdd} info={false} currentSuscription={currentSuscription}/>
    const usersCard = <ConsumptionCard NoPayment={BlockedAdd} title='Developers' plus={true} value={`${ActualMembers}/${UsedMembers}/${MaxMembers}`} action={handleAddDevelopers} loading={loadingProject} available={availableAddDev} info={false} currentSuscription={currentSuscription}/>
    const txCard =  <ConsumptionCard NoPayment={BlockedAdd} title='Requests' plus={true} value={`${txInfo.current}/${txInfo.limit}${susinfo == 1 ? '/50,000' : '/100,000'}`} action={handleAddTx} loading={loadingTx} available={true} info={false} currentSuscription={currentSuscription}/>
    const balanceCard =  <ConsumptionCard NoPayment={BlockedAdd} title='Balance' plus={true} value={balance} action={handleAddBalance} loading={loadingBalance} available={true} info={false} currentSuscription={currentSuscription}/>
    const storageCard =  <ConsumptionCard rightLine={false} NoPayment={BlockedAdd} title='Storage' plus={true} value={`${storageUsed}/${storageLimit}${susinfo == 1 ? '/500 MB': '/768 MB'}`} action={handleAddStorage} loading={loadingStorage} available={true} info={storageHelper} currentSuscription={currentSuscription}>
        i
    </ConsumptionCard>

    const incomeCard =  <ConsumptionCard NoPayment={BlockedAdd}  title='Income' plus={false} value={income} action={handleRemoveIcome} loading={loadingIncome} available={true} info={false} currentSuscription={currentSuscription}/>

    const getConsumption = async (disableCache = false) => {
        const result = await ObexRequestHandler.get('/consumption', {}, !disableCache);
        const { success, data } = result;
        if (success) {
          setMaxTX(data.subscriptionInfo.max_month_rate_limit)
          setLoadingTx(false);
          setLoadingProject(false);
          const { slots, tx, subscriptionInfo } = data;
          setActualTX(tx.limit);
          tx.limit = new Intl.NumberFormat('en-Us').format(tx.limit);
          tx.current = new Intl.NumberFormat('en-US').format(tx.current);
          setSlots(slots);
          setSlotsToBuy(slots.maxExtraProjects - slots.extraPurchasedProjects);
          setSusInfo(subscriptionInfo.subscription_id);

          //console.log(`RESULTADO DE CONSUMO ${slots.maxExtraProjects - slots.extraPurchasedProjects}`)

          setTx(tx);
        }
    }

    const getBalance = async (disableCache = false) => {
      setLoadingBalance(true);
      try {
        //const result = await ObexRequestHandler.get('/balance', {}, disableCache);
        //let { success, data, message } = result;
        //if (!success)  throw { message };
        let balance = '0';
        const data = 0;
        balance = amountToEur(data);
        setBalance(balance);
      } catch (error) {
        console.error('ERROR FETCHING BALANCE ', error)
      }
      setLoadingBalance(false);
    }

    const getIncome = async (disableCache = false) => {
      setLoadingIncome(true);
      try {
        //const result = await ObexRequestHandler.get('/income', {}, disableCache);
        //let { success, data, message } = result;
        //if (!success) throw { message };
        const data = '0'
        const income = amountToEur(data);
        setIncome(income);
      } catch (error) {
        console.error('ERROR FETCHING INCOME ', error)
      }
      setLoadingIncome(false);
    }

    const getStorage = async (disableCache = false) => {
      setLoadingStorage(true);
      setStorageHelper(false);
      try {
        const result = await ObexRequestHandler.get('/database_usage', {}, !disableCache);
        const { success, data, message } = result;

        setActualDBSize(data.allowed.total.db_size_project);
        setMaxDBSize(data.allowed.total.db_size_total);
        if (!success) throw { message };
        //const storageUsed = (data.used.total / 1e+6).toFixed(2).toString();
        //const storageLimit = (data.allowed.total.db_size_total  / 1e+6).toFixed(2).toString();

        const storageUsed = new Intl.NumberFormat('en-US').format(Math.trunc(data.used.total / 1e+6));
        const storageLimit = new Intl.NumberFormat('en-US').format(Math.trunc((data.allowed.subscription.db_size_project+ data.allowed.bought.extra_total_size) / 1e+6));

        setStorageUsed(storageUsed);
        setStorageHelper(false);
        setStorageLimit(storageLimit);
      } catch (error) {
        console.error('ERROR FETCHING STORAGE ', error)
        setStorageHelper(true);
      }
      setLoadingStorage(false);
    }

    const getDevsData = async (disableCache = false) => {
      const result = await ObexRequestHandler.get('/devs_account', {}, !disableCache);
      //console.log(result);
      setMaxMembers(parseInt(result.data.Maxslots));
      setUsedMembers(result.data.activeSlots);
      setActualMembers(result.data.activeDevelopers)

      SessionService.MAXDEVSLOTS = parseInt(result.data.Maxslots);
      SessionService.CURRENTDEVS = result.data.activeSlots;
      SessionService.ACTUALDEVS = result.data.activeDevelopers;

      setAvailableAddDev(result.data.activeSlots<parseInt(result.data.Maxslots));

    };

    const getSusbscription = async (disableCache = false) => {
      // setLoadingStorage(true);
      
      // try {
      //   const result = await ObexRequestHandler.get('/current_subscription', {}, !disableCache);
      //   //console.log(result);
      //   setMaxDBSize(result.data.db_size_total);
      //   const { success, data, message } = result;
      //   if (!success) throw { message };
      //   const currentsuscripcion = (data.subscription_id);
      //   let desarrolladores_activos = 0;
      //   if (data.active_slots_membersdata.active_slots_members == null){
      //     desarrolladores_activos = 0;
      //   } else {
      //     desarrolladores_activos = data.active_slots_members;
      //   }
      //   const max_members_allow = (data.max_members_slots);
      //   const active_slots_members = (desarrolladores_activos);

      //   const annualPayment = (data.annual_payment);
      //   const annualprice = (data.annual_price)
      //   const monthlyprice = (data.monthly_price)

      //   SetCurrentsuscripcion(currentsuscripcion);
      //   setMaxMembers(max_members_allow);
      //   setUsedMembers(active_slots_members);

      //   setDeveloperMultiplier(10);

      //   //console.log(data);

      // } catch (error) {
      //   console.error('ERROR FETCHING STORAGE ', error)

      // }
      // setLoadingStorage(false);
    }

    const getData = (disableCache = false) => {
      getConsumption(disableCache);
      getIncome(disableCache);
      getBalance(disableCache);
      getStorage(disableCache);
      getSusbscription(disableCache);
      getDevsData(disableCache);
    }


    const getVerified = async () => {
      SessionService.RENOVATEDSUSCRIPTION =true;

      // const result2 = await ObexRequestHandler.get('/api_requests');


      const result = await ObexRequestHandler.get('/check_renovated_suscription');
      
      const { success, data } = result;
      if (success) {
        const { renovated } = data;
        
        const wast = document.getElementById('nopayment') as HTMLDivElement;
        if (!renovated) {
          wast.style.display = 'block';
          SessionService.RENOVATEDSUSCRIPTION = renovated;
          setBlockedAdd(true);

        }
      }
  }

    
    useEffect(() => {
      window.scrollTo(0, 0);
        getData();
        getVerified();
       // const consumptionInterval = setInterval(getData, 1000000); // Cada 30seg
       // return () => { 
       //   clearInterval(consumptionInterval) // El return en el use effect ejectuta la función dada cuando el componente se desmonta
       // }
    }, []);

    useEffect(() => {
      if(slotsInfo.activeProjects < slotsInfo.maxAllowProjecs){
        setAvailableAdd(true);
      }else{
        setAvailableAdd(false);
      }
    }, [slotsInfo])

    // Método para cuando se cierra un modal(no obtenemos los datos si no se opera)
    const whenClosing = () => {
      setAddingTx(false);
      setAddingBalance(false);
      setAddingProjects(false);

      setAddingDevelopers(false);

      setRemovingIncome(false);
      setAddingStorage(false);
      //getData();
    }
    
    // Método para cuando una operación de un modal ha ido bien y obtiene los datos de nuevo sin cachear
    const onSuccess = () => {
      setAddingTx(false);
      setAddingBalance(false);
      setAddingProjects(false);

      setAddingDevelopers(false);

      setRemovingIncome(false);
      setAddingStorage(false);
      getData(true);
    }

    return (
      <Fragment>
        <h1 className='mb-4 mt-2 pb-0 d-flex'>
          <div>Dashboard</div>
        </h1>
        <Row className="consumption mb-4 mx-0">
          {usersCard}
          {projectsCard}
          {txCard}
          {storageCard}
    {/*      {balanceCard}
          {incomeCard} */}
        </Row>
        {/* Modal para las opciones proyectos */}
        
        
 <UpgradePlanModal showModal={possibleSlotsToBuy==0 && addingProjects } text={'In order to add a more slots you need to upgrade your plan.'} onCloseModal={whenClosing} onCancelModal={whenClosing}  currentSuscription={currentSuscription}/>
 
 
 <AddProjectModalSel possibleProjectsToBuy={possibleSlotsToBuy} shown={addingProjects && possibleSlotsToBuy>0} onCloseAction={whenClosing}/> 
        



{(addingDevelopers && (SessionService.MAXDEVSLOTS - SessionService.CURRENTDEVS) > 0) ??

<AddDevsModalSel DeveloperMultiplier={DeveloperMultiplier} possibleProjectsToBuy={possibleDevelopersToBuy} shown={addingDevelopers && possibleDevelopersToBuy>0} onCloseAction={whenClosing}/> 
        
      }

{/*

      {(addingDevelopers && MaxMembers-UsedMembers > 0 ) 
        ? <AddDeveloperModal 
          shown={addingDevelopers && MaxMembers-UsedMembers > 0} 
          onSuccessCreation = {whenClosing} 
          onCloseAction={whenClosing}
          setAvailable={setAvailableAdd}/>
        : <AddDeveloperModalSel possibleProjectsToBuy={possibleSlotsToBuy} shown={addingDevelopers && possibleDevelopersToBuy>0} onCloseAction={whenClosing}/> 
        
      }

    */}
<AddDeveloperModalSel DeveloperMultiplier={DeveloperMultiplier} possibleProjectsToBuy={possibleDevelopersToBuy} shown={addingDevelopers && possibleDevelopersToBuy>0} onCloseAction={whenClosing}/> 

        {/* Modal para las opciones de Storage */}
        {(addingStorage && !BlockedAdd) && <AddStorageModal shown={addingStorage && !BlockedAdd} onCloseAction={whenClosing} onSuccess={onSuccess} MaxDBSize={MaxDBSize} ActualDBSize={ActualDBSize}/>}
        {/* Modal para las opciones de Tx */}
        {(addingTx && !BlockedAdd)  && <AddTxSelModal shown={addingTx && !BlockedAdd} onCloseAction={whenClosing} onSuccess={onSuccess} maxMonthLimit={maxTX} actualMonthLimit={actualTX}/>}
        {/* Modal para las opciones de Balance */}
        {addingBalance && <AddBalanceModalSelection shown={addingBalance} onCloseAction={whenClosing} onSuccess={onSuccess}/>}
        {/* Modal para las opciones de Income */}
        {removingIncome && <AddIncomeModalSelection shown={removingIncome} onCloseAction={whenClosing} onSuccess={onSuccess}/>}
      </Fragment>
    );
};

export default Consumptions;