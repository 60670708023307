import React, { FunctionComponent } from 'react';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import Logo from '../../assets/img/newAvapCloud/AVAP Cloud_Business_Logo.png';
import Logo2 from '../../assets/img/newAvapCloud/AVAP_Cloud_Professional-logo.png';
import ImgSerpentina from '../../assets/img/ilustracion-serpentina.png';
import { useParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

function routeLogin() {
  const path = '/cloud_login';
  window.location.href = path;
}

const handleLogIn = async () => {
  routeLogin();

}




const VerifyCloudSignUp: FunctionComponent<{}> = () => {

  const { token } = useParams();
  return (
    <>
      <BrowserView>
        <Container fluid>
          <Row style={{ marginTop: '3rem' }} className="pt-3 verify-signup">
            <Col md="6" className="border rounded px-5 pt-3 pb-4 login-form mx-auto">
              <Row className="justify-content-center my-4">
                <Col xs="6">
                  <Image src={token == 'professional' ? Logo2 : Logo} fluid />
                </Col>
              </Row>
              <Row className="">
                <Col className="my-2 text-center" >
                  <h1 style={{ marginTop: '24px' }} className="mb-2">Suscription Created</h1>
                  <span className="verify-text-content">Congratulations! Your suscription was applied successfully. An email wast sent to your registered email address to verify your sign up, please check your email and follow the link to finish the activation process.</span>
                </Col>
              </Row>
              <Row className="my-5">
                <Col md="4" className="mx-auto">
                  <Image src={ImgSerpentina} fluid />
                </Col>
              </Row>
              <Row className="" style={{ textAlign: 'center' }}>
                <Col md="12" className="mx-auto">
                  <div className='landingFont3 textBold'>Remember check your inbox and verify your email</div>
                  {/*} <Button type="submit" onClick={handleLogIn} className="btn-block btn-green">Login to Cloud Dashboard</Button>  */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}


export default VerifyCloudSignUp;