import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import "./NewLanding.css"
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from "../../assets/img/newDashboard/icon_name.png";
import icono_Infinito from "../../assets/img/newDashboard/icono-Infinito.png";
import logo_communities from "../../assets/img/newDashboard/logo_communities.png";
import logo_alexandria from "../../assets/img/newDashboard/logo-alexandria.png";
import logo_Connect from "../../assets/img/newDashboard/logo-Connect.png";
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from "../../assets/img/avap_logo.png"
import ReactGA from 'react-ga';
import config from '../../configs/appConfig.json';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/Disclaimer';

type DashProps = {
    sider: any
};



const NewLanding = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();
    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }

    useEffect(() => {
        //ReactGA.initialize('G-98LF8608ZM');

        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-98LF8608ZM');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap ID' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };



    return (
        <>
            <BrowserView>
                <div className='d-flex flex-column'>

                    <Navbar className='navbar' style={{ position: 'fixed', width: '100%' }}>
                        <Container className="">
                            <Navbar.Brand href="/framework" style={{}}>
                                <Image src={logo_framework} style={{ marginBottom: '5px' }} height="33px" width="317px" alt="Logo" />
                            </Navbar.Brand>
                            <Nav style={{ width: '80%' }}>
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu1(!menu1) }} style={{ marginRight: '10px', marginLeft: '10px' }}>Products <div hidden={!menu1} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu2(!menu2) }} style={{ marginRight: '10px', marginLeft: '10px' }}>Services <div hidden={!menu2} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                                <Nav.Link onClick={() => { window.open('https://speed-computing-1656.my.site.com/connect/s/', '_blank'); cleanMenuAll() }} style={{ marginRight: '10px', marginLeft: '10px' }}>Partners <div hidden={!menu3} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu4(!menu4) }} style={{ marginRight: '10px', marginLeft: '10px' }}>Resources<div hidden={!menu4} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu5(!menu5) }} style={{ marginRight: '10px', marginLeft: '10px' }}>Company  <div hidden={!menu5} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                            </Nav>
                            <button
                                style={{ padding: '0px', width: '350px', height: '30px' }}
                                className='custom-button-green textBold'
                                onClick={() => { history.push('/login') }}
                            >
                                Sign in with your AVAP ID
                            </button>
                        </Container>

                        {menu1 &&
                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div>
                                <div className='fade-in-image' style={{
                                    marginTop: '374px',
                                    marginLeft: '10%',
                                    marginRight: 'auto',
                                    position: 'absolute',
                                    width: '80%',
                                    backgroundColor: 'white',
                                    borderBottomLeftRadius: '15px',
                                    borderBottomRightRadius: '15px',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                                }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Build Virtual APIs
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}

                                                onClick={() => { history.push('/avap') }}>
                                                AVAP (Advanced Virtual API Programming)
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}>
                                                AVAP API Extensions for Microsoft Visual Code
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }} onClick={() => { history.push('/devstudio') }}>
                                                AVAP Dev Studio
                                            </div>



                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Test & Deliver APIs
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}>
                                                AVAP API Version Control
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}>
                                                AVAP API Publisher
                                            </div>

                                        </div>


                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Run APIs
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>
                                            {/*}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Virtual Server
                                </div> */}
                                            {/*}    <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Community Edition
                                </div>*/}
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }} onClick={() => { history.push('/avs') }}>
                                                AVAP Virtual Server
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }} onClick={() => { history.push('/avap') }}>
                                                AVAP Interpreter & Language Server
                                            </div>
                                        </div>



                                    </div>

                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Power Up APIs
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>


                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}>
                                                AVAP Plug Ins
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer',
                                                marginBottom: '34px',
                                            }}

                                            >
                                                AVAP Connectors
                                            </div>
                                            {/*}    <div style={{
                paddingLeft:'20px',
                paddingTop:'10px',
                color:'#969696',
                marginBottom:'34px',
                cursor:'pointer'
            }}>
            AVAP Virtual Machine
        </div>*/}



                                        </div>
                                        {/*}
                    <div style={{
                        width:'33%'
                    }}>
                        <div className='landingFont3'
                            style={{
                                paddingLeft:'20px',
                                paddingTop:'30px'
                            }}
                        >
                            Extend APIs
                        </div>
                        <div style={{
                            height:'1px',
                            backgroundColor:'#d28e22',
                            marginLeft:'20px',
                            marginRight:'20px',
                                cursor:'pointer'
                        }}>
                        </div>
    
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                cursor:'pointer'
                            }}>
                            Plug Ins
                        </div>
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                marginBottom:'34px',
                                cursor:'pointer'
                            }}>
                            Connectors
                        </div>
    
    
                    </div>
    
    
                */}



                                    </div>


                                </div></>
                        }

                        {menu2 &&
                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div><div className='fade-in-image' style={{
                                marginTop: '215px',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                position: 'absolute',
                                width: '80%',
                                backgroundColor: 'white',
                                borderBottomLeftRadius: '15px',
                                borderBottomRightRadius: '15px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                            }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Professional
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => { history.push('/collaborative') }}
                                            >
                                                AVAP Collaborative Network
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer',
                                                marginBottom: '34px'
                                            }}>
                                                AVAP Cloud Professional
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Monolith to Microservices
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Power OpenAI Applications
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer',
            marginBottom:'35px'
        }}>
        Mobile Application API Development
    </div>
*/}


                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Business
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}>
                                                AVAP Cloud Business
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Secure and Govern APIs
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Gateway for Istio
    </div>
*/}
                                        </div>


                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Corporate
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}>
                                                AVAP Cloud Enterprise
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Decentralized Load Balancing
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Zero Trust Security
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Observability
    </div>
    */}
                                        </div>

                                    </div>
                                </div></>
                        }

                        {menu3 &&

                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div><div className='fade-in-image' style={{
                                marginTop: '148px',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                position: 'absolute',
                                width: '80%',
                                backgroundColor: 'white',
                                borderBottomLeftRadius: '15px',
                                borderBottomRightRadius: '15px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                            }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {/*} Partner Ecosystem*/}
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                display: 'none',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>



                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {/*Find a Partner*/}
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                display: 'none',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>



                                        </div>


                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {/*Become a partner*/}
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                display: 'none',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>


                                        </div>
                                    </div>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {/*Partner Login*/}
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: 'transparent',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer',
                                                marginBottom: '35px'
                                            }}>
                                            </div>
                                        </div>
                                    </div>
                                </div></>
                        }

                        {menu4 &&
                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div><div className='fade-in-image' style={{
                                marginTop: '214px',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                position: 'absolute',
                                width: '80%',
                                backgroundColor: 'white',
                                borderBottomLeftRadius: '15px',
                                borderBottomRightRadius: '15px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                            }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Developer
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => { history.push('/cloud_login') }}
                                            >
                                                AVAP Developer Portal.
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer',
                                                marginBottom: '34px'
                                            }}
                                                onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank') }}
                                            >
                                                AVAP Communities
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Case Studies
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Blog
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Content Library
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Learning Center
    </div>

*/}

                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Knowledge Center
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US', '_blank') }}
                                            >
                                                AVAP Alexandria
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Mesh
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP AI Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Insomnia
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Plugin Hub
    </div>
*/}
                                        </div>

                                        {/*}
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Community
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                About Us
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Join the Community
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Livestreams & Events
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Champions
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Discuss
            </div>
        </div>
    
        </div>
    
        <div style={{
            width:'100%',
            display:'flex'
            }}>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Developer
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Contributor Program
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer',
                    marginBottom:'34px',
                }}>
                GitHub
            </div>
    
        </div>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px'
                }}
            >
                Academy
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Certification
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    marginBottom:'34px',
                    cursor:'pointer'
                }}>
                Education
            </div>
        </div> */}
                                    </div>

                                </div></>
                        }
                        {menu5 &&
                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div><div className='fade-in-image' style={{
                                marginTop: '214px',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                position: 'absolute',
                                width: '80%',
                                backgroundColor: 'white',
                                borderBottomLeftRadius: '15px',
                                borderBottomRightRadius: '15px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                            }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%',
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Company
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer',
                                            }}>
                                            </div>

                                            <div onClick={() => { history.push('/about') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                About Us
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer',
                                                marginBottom: '34px'
                                            }} onClick={() => { window.open('mailto: info@101obex.com', '_self') }}>
                                                Contact Us
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Press Room
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Investors
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Contact Us
    </div>
*/}

                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Legal
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}>
                                                Terms & Conditions
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}>
                                                Privacy Notice
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Documentation
    </div>
    

</div>


<div style={{
    width:'33%'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px',
            cursor:'pointer'
        }}
    >
        Events
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Summit by AVAP
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Upcomming Events
    </div>
    
</div>

</div>

<div style={{
    width:'100%',
    display:'flex'
    }}>

<div style={{
    width:'33%'
}}>
    

</div>

<div style={{
    width:'33%'
}}>

</div>

<div style={{
    width:'33%',
    marginTop:'-100px',
    marginBottom:'35px'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px'
        }}
    >
        Security
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Trust and Compilance
    </div>
    
    */}

                                        </div>

                                    </div>
                                </div></>
                        }

                    </Navbar>


                    <div onClick={() => { cleanMenuAll() }}>

                        <div className="d-flex flex-column align-items-center">


                            <div className='d-flex flex-column  min-vh-100 align-items-center w-principal justify-content-center'>

                                <div>
                                    <div className='landingSizeLogo' style={{ marginTop: "16rem", marginBottom: '2rem' }}>
                                        <img style={{ marginLeft: '10%', height: '80%', width: '80%' }} src={Logo} alt="" width={"100%"} height={"100%"} />
                                    </div>
                                </div>
                                <div className='landingFont1 textBold landingFontColor mt-5 pt-3 text-center'>Your account for AVAP Framework</div>
                                <div className='landingFont2  landingFontColor mt-5 pt-3 text-center'>Your AVAP ID will give you access to AVAP products and services.</div>
                                <div className='landingFont2  landingFontColor text-center'>Sign in to manage your account.</div>
                                <div className='mt-5 pt-3' style={{ marginBottom: "8rem" }}>
                                    <button className="btn custom-button w-100 textBold " onClick={handleSignInClick}>Sign in</button>
                                </div>

                                <div className='textos w-text d-flex mt-5 pt-3'>
                                    <div>
                                        <div className='iconsSize mr-2'>
                                            <img src={icon_name} alt="" width={"100%"} height={"100%"} />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='landingFont3 textBold mb-5'>
                                            What is AVAP ID?
                                        </div>
                                        <div className='landingFont4'>
                                            AVAP ID is your personal account to access AVAP products and services like Interpreter & Language, AVAP Cloud, AVAP Dev Extensions, AVAP Gateway Advanced, AVAP Collaborative Network, and more.
                                            It includes your contact and sign in information, payment methods, and security details that you'll use for AVAP Framework.
                                        </div>
                                    </div>

                                </div>

                                <div className='textos w-text d-flex mt-5 pt-3'>
                                    <div>
                                        <div className='iconsSize mr-2' style={{ height: '18px', width: '36px', marginLeft: '-10px'/*marginTop:"10px"*/ }}>
                                            <img src={icono_Infinito} alt="" width={"100%"} height={"150%"} />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='landingFont3 textBold mb-5'>
                                            When do I use my AVAP ID?
                                        </div>
                                        <div className='landingFont4'>
                                            Any time you set up a new device, make a purchase, or use any AVAP service, you will be asked to  sign in with your AVAP ID and password.
                                            Use the same AVAP ID everywhere you sign in to ensure that all your AVAP products, services and devices work together seamlessly, and access your personal content from all your devices.
                                        </div>
                                    </div>

                                </div>

                                <div className='textos w-text d-flex mt-5 pt-5 justify-content-between' style={{ marginBottom: "112px" }}>
                                    <div>
                                        <div className='logosSize mr-2' >
                                            <a href="https://speed-computing-1656.my.site.com/communities">
                                                <img src={logo_communities} alt="" width={"80%"} height={"100%"} />
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='logosSize mr-2'>
                                            <a href="https://speed-computing-1656.my.site.com/alexandria">
                                                <img src={logo_alexandria} alt="" width={"80%"} height={"100%"} />
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='logosSize mr-2'>
                                            <a href="https://speed-computing-1656.my.site.com/connect">
                                                <img src={logo_Connect} alt="" width={"80%"} height={"100%"} />
                                            </a>
                                        </div>
                                    </div>
                                    {/*}                    <div>
                        <div className='logosSize mr-2'>
                            <img src={logo_framework} alt="" width={"100%"} height={"100%"}/>
                        </div>
                    </div>*/}


                                </div>

                            </div>
                        </div>
                    </div>
                    <div>
                        <FooterMenu green={true} />
                    </div>

                </div>
            </BrowserView>
            <MobileView>
                <Disclaimer />
            </MobileView>
        </>

    )
}

export default NewLanding;