import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ProjectChart from './GraphComponents/ProjectsChart';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddProjectModalSel from '../Modals/addProjectModalSelect';
import AddProjectModal from '../Modals/addProjectModal';
import { faPencilAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { amountToEur } from '../../services/AmountService';
import ConsumptionProjectChart from './GraphComponents/ConsumptionProjectChart';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type ProjectsProps = {
};


const StagingProjects: FunctionComponent<ProjectsProps> = props => {

  const [loadingTable, setLoadingTable] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectSelected, setProjectSelected] = useState("")
  const [labels, setLabels] = useState([]);
  const [dataValues, setDataValues] = useState([]);

  const getProjects = async () => {
    try {
      setProjects([]);
      setLoadingTable(true);
      const projects = (await ObexRequestHandler.get('/staging_projects')).data || [];
      setProjects(projects);
      const labels = [];
      const dataValues = [];
      projects.map(project => {
        labels.push(project.description)
        dataValues.push(project.tx.current);
      })
      setLabels(labels);
      setDataValues(dataValues);
      setProjectSelected(projects[0].name);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    }
    setLoadingTable(false);
  }

  const history = useHistory();
  const routerLink = (url) => {
    const pushData = projects
      ? { state: { projects } }
      : {};
    history.push(url, pushData);
  }

  const loadingSpinner = <Row><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText = <Row>
    <Col className="mx-auto text-center">
      <span className="d-block">
        You have not yet registered any staging project in your account.
      </span>
      <span className="d-block">
        Press add to register and configure your projects.
      </span>
    </Col>
  </Row>;


  useEffect(() => {
    getProjects();
  }, []);


  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'developers'}>
          <Row>
            <Col md={10}>
              <h1>Staging Projects</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive className="obex-projects-table" style={{ paddingLeft: '10px' }}>
                <thead style={{
                  fontFamily: 'Regular Intro Bold',
                  textAlign: 'start',
                  backgroundColor: 'transparent',
                  color: 'black',
                  borderWidth: '1px',
                  borderRadius: '15px',
                  borderStyle: 'hidden'
                }}>
                  <tr>
                    <th className='textBold'>Project Name</th>
                    <th className='textBold'>Project ID</th>
                    <th className='textBold'>Requests</th>
                    <th className='textBold'>Balance</th>
                    <th className='textBold'>Benefits</th>
                    <th className='textBold'>Date</th>
                    <th className='textBold'></th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map(project => (
                    <tr key={project.id}>
                      <td>{project.description}</td>
                      <td>{project.name}</td>
                      <td>{project.tx.current}</td>
                      <td>{amountToEur(project.balance)}</td>
                      <td>{amountToEur(project.benefits)}</td>
                      <td>{project.creation_date}</td>
                      <td className="text-right">
                        <a className="edit-link" onClick={() => routerLink(`/projects/${project.name}`)}><FontAwesomeIcon icon={faPencilAlt} size="1x" className="mr-1" /></a> |
                        <FontAwesomeIcon icon={faFileAlt} size="1x" className="ml-1" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          {loadingTable && loadingSpinner}
          {(!loadingTable && projects.length <= 0) && noProjectsText}
          <Row className="stats-grafic">
            <Col>
              {loadingTable ? loadingSpinner : <ConsumptionProjectChart title={projectSelected} projects={projects} />}
            </Col>

          </Row>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}
// TODO possibleProjectsToBuy rescartar la info
export default StagingProjects;