import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert, Navbar, Nav } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
//import Logo from '../../assets/img/logotipo_ID_1.png';
import Logo from '../../assets/img/AVAP_ID_horizontal.png';
import Logo2 from '../../assets/img/ilustracion-serpentina.png'
import VerificationNotice from './VerificationNotice';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import './style.css';
import NewPassword from '../Modals/setNewPassword';
import { color } from 'd3';
import FooterMenu from '../functionalComponents/DashboardLayout/FooterMenu';
import logo_framework from "../../assets/img/avap_logo.png"
import RequestHandler from '../../handlers/RequestHandler';
import Cookies from 'universal-cookie';
//import { config } from 'process';
import config from '../../configs/appConfig.json';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type AuthProps = {
  Token?: number
}

const GetAuthorizationForm: FunctionComponent<AuthProps> = (props: AuthProps) => {

  const [codeResult, setCodeResult] = useState([]);
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [signining, setSignining] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showRecovery, setShowRecovery] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [p2pMode, setP2pMode] = useState(false);
  const [tokenUsed, setToken] = useState('');

  const handleCloseRecovery = () => setShowRecovery(false);
  const handleCloseNewPassword = () => setShowNewPassword(false);
  const handleShowRecovery = (e) => {
    e.preventDefault();
    setShowRecovery(true);
  };

  const handleShowNewPassword = (e) => {
    e.preventDefault();
    setShowNewPassword(true);
  };

  const history = useHistory();

  function routeDashboard() {
    history.push({
      pathname: '/admin'
    })
  }

  function routeHome() {
    const path = '/login';
    window.location.href = path;
  }

  function routePlans() {
    const path = '/Plans';
    window.location.href = path;
  }

  function routePrivate() {
    history.push({
      pathname: '/private'
    })

  }

  const redirectPage = (signindata) => {

    if (signindata['id_perfil'] == 6) routeDashboard();
    else if (signindata['id_perfil'] == 1) routePrivate();
    else routePlans();

  };

  const handleSubmitSignip = async (event) => {
    console.log(event);
    setSignining(true);
    let result;
    if (p2pMode === false) {
      result = await ObexRequestHandler.get(`/get_authorization?email=${mail}&password=${password}&token=${tokenUsed}`);
    }
    else {
      const headers = { Authorization: '', 'disable-cache': true };
      const user = ObexRequestHandler.cookies.get('101Obex');
      const token = `Bearer ${user}`
      headers.Authorization = token;
      let url = 'https://acl.avapcloud.p2p.mooo.com:3001';
      if (config.url === 'https://avs-primary-pre.101obex.mooo.com') url = 'https://acl.p2p-pre.avap.mooo.com:3001';

      result = await RequestHandler.get(`${url}/get_authorization?email=${mail}&password=${password}&token=${tokenUsed}`,
        {}, headers);
    }
    console.log(result);
    if (result.success) {
      if (result.data.code) {
        const gg = document.getElementById('registro_entrada');
        const hh = document.getElementById('registro_entrada_success');
        gg.hidden = true;
        hh.hidden = false;
        let code: string = result.data.code.toString();
        let codeArray = code.split('');
        setCodeResult(codeArray);
      } else {
        setError(result.data.message);
      }
    } else {
      setError(result.message);
    }
    setSignining(false);
  };


  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    routePlans();
  };


  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  }

  const changePass = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }


  useEffect(() => {

    var lastFour = props.Token.toString().substr(props.Token.toString().length - 4);
    var Tokk = props.Token.toString();
    if (lastFour === '-p2p') {
      Tokk = (props.Token.toString().substring(0, props.Token.toString().length - 4)).toString();
      setP2pMode(true);
    }

    console.log(Tokk);
    setToken(Tokk);

  });


  return (
    <>
      <BrowserView>

        <div className="text-center d-flex flex-column containerLogin ">

          {stillLoaded ?
            <Row className="h-74" >
              <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '25em' }}>
                <Spinner animation="border" />
              </Col>
            </Row> :
            <><Navbar className='navbar' style={{ position: 'fixed', width: '100%' }}>
              <Container className="">
                <Navbar.Brand href="#home" style={{}}>
                  <Image src={logo_framework} style={{ marginBottom: "5px" }} width="227px" alt="Logo" />
                </Navbar.Brand>
                <Nav style={{ width: "80%" }}>
                  <Nav.Link href="#section1" style={{ marginRight: "10px", marginLeft: "10px" }}>Products</Nav.Link>
                  <Nav.Link href="#section2" style={{ marginRight: "10px", marginLeft: "10px" }}>Partners</Nav.Link>
                  <Nav.Link href="#section3" style={{ marginRight: "10px", marginLeft: "10px" }}>Resource</Nav.Link>
                  <Nav.Link href="#section4" style={{ marginRight: "10px", marginLeft: "10px" }}>Company</Nav.Link>
                  <Nav.Link href="#section4" style={{ marginRight: "10px", marginLeft: "10px" }}>Contact Sales</Nav.Link>
                </Nav>
              </Container>
            </Navbar><><Row className="justify-content-center pt-5 mt-5 " style={{ minHeight: "72vh" }}>
              <div id='registro_entrada' className=" rounded px-5 pt-7 pb-4 login-form" style={{ paddingTop: '4rem' }}>
                <Row className="justify-content-center my-4">
                  <Col>
                    <Image onClick={routeHome} src={Logo} fluid
                      width="300"
                      style={{ marginBottom: '60px' }} />
                    <h6 className='text-boldLogin'>Sign in with your AVAP ID and manage your account.</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form className="obex-form theForm">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control required type="email" placeholder="Email" onChange={changeMail} className="borderRadiusLogin" />
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">

                        <div className='d-flex'>

                          <Form.Control required type="password" placeholder="Password" onChange={changePass} className="borderRadiusLeftLogin" />
                          <button className="borderRadiusRightLogin btnNew  btn-block" onClick={handleSubmitSignip} disabled={signining}>
                            {!signining && <>
                              <svg xmlns="http://www.w3.org/2000/svg" fill='gray' width={"15px"} height={"15px"} viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                            </>}
                            {signining && <Spinner animation="border" size="sm" style={{ color: "gray" }} />}
                          </button>
                        </div>
                      </Form.Group>

                      {error.length > 0 && (
                        <Alert variant='danger'>
                          {error}
                        </Alert>)}
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-2">
                    <a className="link_forgetpass" onClick={handleShowRecovery}>Are you having problems with signing in?</a>
                  </Col>
                </Row>
                {/*
<Row>
<Col className="my-3">
<div className="border-top divider-login"></div>
</Col>
</Row>
<Row>
<Col className="my-2">
<Form>
  <Button type="submit" variant="light" className="btn-block singin" onClick={handleSubmitSignup}>
    Create account
  </Button>
</Form>
</Col>
</Row>
*/}
              </div>


              <div id='registro_entrada_success' className="rounded px-5 pt-3 pb-4 login-form" hidden={true}>
                <Row className="justify-content-center my-4">
                  <Col>
                    {/* <Image onClick={routeHome} src={Logo2} fluid
      width="180"
      style={{ marginBottom: '60px' }}
    /> */}
                    <h5><b className='text-bold mb-4'>Signature Code, copy it and finish your AVAP transaction.</b></h5>
                    {/* <Row style={{ justifyContent: 'center' }}>
      Use this code to get your developer Token
    </Row> */}
                    <Row style={{ justifyContent: 'center' }} className="mb-5">
                      {codeResult && codeResult.map((caracter, indice) => (
                        <div className='rounded m-2 fontNumber d-flex align-items-center' key={indice}>
                          {caracter}
                        </div>
                      ))}
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                      This code is for single use, sign with it to finalize yout transaction.
                    </Row>
                  </Col>
                </Row>

              </div>
              <PasswordRecovery show={showRecovery} handleClose={handleCloseRecovery} />
            </Row><VerificationNotice
                  mail={mail}
                  show={show}
                  handleClose={handleClose} /><Row className="mt-auto">
                  <Col className="px-0">
                    <FooterMenu green={true} />
                  </Col>
                </Row></></>
          }


        </div>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
}
export default GetAuthorizationForm;